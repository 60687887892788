var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm.isLoadingSubmit
      ? _c(
          "section",
          { staticClass: "el-centered" },
          [_c("emd-pagarme-loader")],
          1
        )
      : _c(
          "section",
          [_c("CostumerReview"), _c("AddressReview"), _c("PaymentReview")],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "challengeIframeElement",
        attrs: { id: "challengeIframeElement" },
      },
      [
        _c("div", { staticClass: "modal-content" }, [
          _c("iframe", {
            attrs: {
              title: "tds",
              name: "challengeIframe",
              id: "challengeIframe",
            },
          }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }