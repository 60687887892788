<template>
  <div class="emd-form-box" :class="[enumBorder[border], hasError ? '_state_error' : '', isActive ? '_active' : '']">
    <div class="emd-form-box__header" @click="changeValue">
      <label class="emd-radio-button" :for="id">
        <input class="emd-radio-button__input" type="radio" :name="name" :id="id" :value="id" v-model="radioButtonValue"/>
        <div class="emd-radio-button__area"></div>
        <span v-if="$slots.icon" class="emd-form-box__image">
          <slot name="icon"></slot>
        </span>
        <span class="emd-radio-button__text">{{ label }}</span>
      </label>
      <div class="emd-form-box__helper" v-if="$slots.helper">
        <div class="emd-text _size_tiny">
          <slot name="helper"></slot>
        </div>
      </div>
    </div>
    <div v-if="$slots.aside" class="emd-form-box__aside">
      <slot name="aside"></slot>
    </div>
    <div v-if="$slots.content" class="emd-form-box__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmdFormBox',
  props: {
    id: {
      default: 0
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false,
      required: false
    },
    border: {
      type: String,
      default: 'none',
      required: false,
      validator: (value) => value === 'light' || value === 'base' || value === 'strong' || value === 'none'
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      enumBorder: {
        light: '_border_light',
        base: '_border_base',
        strong: '_border_strong',
        none: '',
        '': ''
      }
    }
  },
  computed: {
    radioButtonValue: {
      get () {
        return this.value
      },
      set () {
        this.$emit('change', this.id)
      }
    }
  },
  methods: {
    changeValue () {
      this.radioButtonValue = true
    }
  }
}
</script>

<style>
.emd-form-box__image svg,
.emd-form-box__image img {
  height: 24px;
  width: 98px;
}
.emd-form-box__header {
  cursor: pointer;
}
</style>
