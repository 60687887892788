import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Motherboard from '@/components/project/templates/Motherboard.vue'
import Checkout from '@/views/Checkout.vue'
import { getPaymentLinkData } from './utils/paymentLinkData'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:token',
      alias: ['/v1/:token'],
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        const token = to.params.token
        getPaymentLinkData(token, next)
      },
      children: [
        {
          path: '',
          name: 'checkout',
          component: Checkout
        }
      ]
    },
    {
      path: '',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        store.dispatch('TOGGLE_GLOBAL_LOADING', { isLoading: false })
        store.dispatch('TOGGLE_GLOBAL_ERROR', { status: true, code: 404 })
        window.document.title = 'Página não encontrada! | Checkout Pagar.me'
        next()
      },
      children: [
        {
          path: '',
          name: 'default',
          component: Checkout
        }
      ]
    },
    {
      path: '*',
      redirect: { name: 'default' }
    }
  ]
})
