var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "el-chunk" }, [
      _c(
        "p",
        { staticClass: "emd-text _align_center" },
        [
          _c("emd-footer-icon", { attrs: { theme: _vm.theme, url: _vm.url } }, [
            _c(
              "span",
              {
                staticClass: "emd-text _color_default",
                staticStyle: { "vertical-align": "2px" },
              },
              [_vm._v(" " + _vm._s(_vm.$t(_vm.theme)) + " ")]
            ),
          ]),
          _c("span", { staticClass: "emd-text _color_nonessential eu-mx_2" }, [
            _vm._v(" | "),
          ]),
          _c("span", [
            _vm._m(0),
            _c("span", { staticStyle: { "vertical-align": "-1px" } }, [
              _vm._v(" " + _vm._s(_vm.$t("safeZone")) + " "),
            ]),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "el-chunk emd-text _align_center" },
      [_c("emd-language-select", { attrs: { color: "subtle" } })],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "emd-text _color_secondary" }, [
      _c("i", { staticClass: "uil uil-shield-check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }