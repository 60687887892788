var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "el-bounds _narrow" }, [
    _c(
      "div",
      { staticClass: "el-column__main" },
      [
        _vm.tokenData && !_vm.globalError
          ? [
              _c("div", { staticClass: "el-column__main_body" }, [
                _c(
                  "div",
                  {
                    staticClass: "emd-container",
                    class: {
                      _footer_end: _vm.checkoutState === "form",
                      _footer_centered: _vm.checkoutState === "success",
                    },
                  },
                  [
                    _vm.checkoutState === "form"
                      ? [
                          _c(
                            "form",
                            {
                              attrs: { novalidate: "" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submit.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "emd-container__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "el-chunk" },
                                    [
                                      _c("emd-progress-indicator", {
                                        staticClass: "_merchant_primary",
                                        attrs: {
                                          items: _vm.sections,
                                          interactive: true,
                                          variant: "primary",
                                        },
                                        on: { goTo: _vm.goToState },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "el-chunk" },
                                    [
                                      _vm.errors.length > 0
                                        ? _c(
                                            "emd-alert",
                                            {
                                              attrs: {
                                                variant: "danger",
                                                title: _vm.$t("error.title"),
                                                autoclose: "",
                                              },
                                              on: { cancel: _vm.handleCancel },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "content" },
                                                _vm._l(
                                                  _vm.errors,
                                                  function (error, index) {
                                                    return _c("i18n", {
                                                      key: index,
                                                      attrs: {
                                                        path: `error.${error}`,
                                                        tag: "p",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "form",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "emd-text _type_caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          `section.${_vm.formError}`
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.currentSection === "customer"
                                    ? _c("section-customer")
                                    : _vm._e(),
                                  _vm.currentSection === "address"
                                    ? _c("section-address")
                                    : _vm._e(),
                                  _vm.currentSection === "payment"
                                    ? _c("section-payment")
                                    : _vm._e(),
                                  _vm.currentSection === "review"
                                    ? _c("section-review")
                                    : _vm._e(),
                                  _c(
                                    "form",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: false,
                                          expression: "false",
                                        },
                                      ],
                                      attrs: {
                                        "data-pagarmecheckout-form": "",
                                      },
                                    },
                                    [
                                      _vm.currentSection === "review"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.tdsToken,
                                                expression: "tdsToken",
                                              },
                                            ],
                                            staticClass: "emd-input",
                                            attrs: { type: "text" },
                                            domProps: { value: _vm.tdsToken },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.tdsToken =
                                                  $event.target.value
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.currentSection === "review"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.creditCardNumber,
                                                expression: "creditCardNumber",
                                              },
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: true,
                                                expression: "true",
                                              },
                                            ],
                                            staticClass: "emd-input",
                                            attrs: {
                                              type: "text",
                                              "data-pagarmecheckout-element":
                                                "number",
                                            },
                                            domProps: {
                                              value: _vm.creditCardNumber,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.creditCardNumber =
                                                  $event.target.value
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.currentSection === "review" &&
                                  !_vm.isLoadingSubmit
                                    ? [
                                        _vm.showCaptcha
                                          ? _c("vue-recaptcha", {
                                              staticClass:
                                                "captcha-container g-recaptcha",
                                              attrs: {
                                                sitekey: _vm.sitekey,
                                                loadRecaptchaScript: true,
                                                language: _vm.$i18n?.locale,
                                                theme: "light",
                                              },
                                              on: {
                                                verify: _vm.validateCaptcha,
                                                expired: _vm.resetCaptcha,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.showRecaptchaFeedback
                                          ? _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "emd-input-feedback eu-align_center _state_error",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("recaptchaError")
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              !_vm.isLoadingSubmit
                                ? _c(
                                    "div",
                                    { staticClass: "emd-container__footer" },
                                    [
                                      _vm.currentSection !== "customer"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "emd-btn _color_primary _type_text _block_mobile _merchant_primary",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goToState("BACK")
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "uil uil-angle-left",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("btn.back")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.currentSection !== "review"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "emd-btn _color_primary _block_mobile _merchant_primary",
                                              attrs: { type: "submit" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("btn.next")) +
                                                  " "
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "uil uil-angle-right",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.payments.selectedPaymentMethod !==
                                        "apple_pay" &&
                                      _vm.payments.selectedPaymentMethod !==
                                        "google_pay" &&
                                      _vm.currentSection === "review"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "emd-btn _color_primary _block_mobile",
                                              class: {
                                                _disabled: _vm.loadingPayment,
                                                _merchant_secondary:
                                                  !_vm.loadingPayment,
                                              },
                                              attrs: {
                                                disabled: _vm.loadingPayment,
                                                type: "submit",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.payments
                                                      .selectedPaymentMethod ===
                                                      "boleto" ||
                                                      _vm.payments
                                                        .selectedPaymentMethod ===
                                                        "pix"
                                                      ? _vm.$t(
                                                          `btn.${_vm.payments.selectedPaymentMethod}`
                                                        )
                                                      : _vm.$t("btn.pay")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.payments.selectedPaymentMethod ===
                                        "apple_pay" &&
                                      _vm.currentSection === "review"
                                        ? [
                                            _c("apple-pay", {
                                              on: {
                                                onPaymentAuthorized:
                                                  _vm.setApplePayPayment,
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm.payments.selectedPaymentMethod ===
                                        "google_pay" &&
                                      _vm.currentSection === "review"
                                        ? [
                                            _c("google-pay", {
                                              attrs: {
                                                setPaymentData:
                                                  _vm.setPaymentData,
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.checkoutState === "success"
                      ? [_c("success")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "div",
                { staticClass: "el-column__main_aside" },
                [_c("order-detail")],
                1
              ),
            ]
          : [_c("error", { attrs: { code: _vm.globalErrorCode } })],
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "el-chunk" },
      [
        _c("emd-modal", {
          attrs: {
            id: "hst_src-modal",
            scrollable: "",
            "has-footer": "",
            open: _vm.showClickToPayModal,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", {
                    staticClass: "click-to-pay",
                    attrs: { id: "hst_src" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c("script", {
      attrs: {
        type: "application/javascript",
        src: _vm.clickToPayParam?.hst_sdk_url,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }