var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["emd-language-select", _vm.modifiers] }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$i18n.locale,
            expression: "$i18n.locale",
          },
        ],
        on: {
          click: _vm.selectLanguage,
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.$set(
              _vm.$i18n,
              "locale",
              $event.target.multiple ? $$selectedVal : $$selectedVal[0]
            )
          },
        },
      },
      _vm._l(_vm.langs, function (lang) {
        return _c(
          "option",
          { key: lang.code, domProps: { value: lang.code } },
          [_vm._v(_vm._s(lang.flag) + " " + _vm._s(lang.name))]
        )
      }),
      0
    ),
    _c("div", { staticClass: "emd-language-select__arrow" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }