import { hasError, isEmpty } from '@/utils'
const clonedeep = require('lodash.clonedeep')

const address = {
  state: {
    type: 'global',
    requiredForm: true,
    is_editable: true,
    hasError: false,
    billingEqualShipping: false,
    fields: {},
    shipping: {
      recipient_name: null,
      recipient_phone: null
    },
    global: {
      zip_code: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      line_1: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      line_2: {
        value: null,
        validation: {
          required: false
        },
        error: false
      },
      city: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      state: {
        value: null,
        validation: {
          required: true,
          length: 2
        },
        error: false
      },
      country: {
        value: 'BR',
        validation: {
          required: true,
          length: 2
        },
        error: false
      }
    },
    brazilian: {
      zip_code: {
        value: null,
        validation: {
          required: true,
          length: 8
        },
        error: false
      },
      street: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      number: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      complement: {
        value: null,
        validation: {
          required: false
        },
        error: false
      },
      neighborhood: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      city: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      state: {
        value: null,
        validation: {
          required: true,
          length: 2
        },
        error: false
      },
      country: {
        value: 'BR',
        validation: {
          required: true,
          length: 2
        },
        error: false
      }
    }
  },
  mutations: {
    setAddressData (state, tokenData) {
      const billingAddressData = tokenData.customer_settings && tokenData.customer_settings.customer && tokenData.customer_settings.customer.address
      state.type = tokenData.checkout_settings && tokenData.checkout_settings.address_type
      state.fields = clonedeep(state[state.type])
      if (billingAddressData) {
        Object.keys(billingAddressData).forEach((key) => {
          if (state.fields[key]) {
            state.fields[key].value = billingAddressData[key]
          }
        })
        state.is_editable = tokenData.customer_settings.editable
      }
      const requiredFields = tokenData.checkout_settings && tokenData.checkout_settings.required_fields
      if (requiredFields) {
        if (!(requiredFields.includes('billing.address') || requiredFields.includes('customer.adddres'))) {
          Object.keys(state.fields).forEach((key) => {
            state.requiredForm = false
            state.fields[key].validation.required = false
          })
        }
      }
    },
    setBillingEqualShipping (state, params) {
      if (state.billingEqualShipping) {
        Object.keys(state.fields).forEach((key) => {
          state.fields[key].value = state.shipping[key].value
        })
      } else {
        Object.keys(state.fields).forEach((key) => {
          if (state.type === 'global' || key !== 'country') {
            state.fields[key].value = null
          }
        })
      }
    },
    updateRequiredFields (state, params) {
      if (!state.requiredForm) {
        if (!isEmpty(state.fields.zip_code.value)) {
          Object.keys(state.fields).forEach((key) => {
            if (!(key === 'line_2' || key === 'complement')) {
              state.fields[key].validation.required = true
            }
          })
        } else {
          Object.keys(state.fields).forEach((key) => {
            state.fields[key].validation.required = false
          })
        }
      }
    }
  },
  actions: {
    'UPDATE_REQUIRED_FIELDS'  (context, params = {}) {
      context.commit('updateRequiredFields')
    },
    'SET_BILLING_EQUAL_SHIPPING'  (context, params = {}) {
      context.commit('setBillingEqualShipping')
    },
    'VERIFY_ADDRESS_FORM' (context, params = {}) {
      context.state.hasError = false
      const fields = context.state.fields
      if (!context.state.billingEqualShipping && context.state.is_editable) {
        Object.keys(fields).forEach((key) => {
          context.state.fields[key].error = hasError(fields[key])
          context.state.hasError = (context.state.hasError || !!context.state.fields[key].error)
        })
      }
    }
  }
}

export default address
