var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emd-form-box",
      class: [
        _vm.enumBorder[_vm.border],
        _vm.hasError ? "_state_error" : "",
        _vm.isActive ? "_active" : "",
      ],
    },
    [
      _c(
        "div",
        { staticClass: "emd-form-box__header", on: { click: _vm.changeValue } },
        [
          _c(
            "label",
            { staticClass: "emd-radio-button", attrs: { for: _vm.id } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.radioButtonValue,
                    expression: "radioButtonValue",
                  },
                ],
                staticClass: "emd-radio-button__input",
                attrs: { type: "radio", name: _vm.name, id: _vm.id },
                domProps: {
                  value: _vm.id,
                  checked: _vm._q(_vm.radioButtonValue, _vm.id),
                },
                on: {
                  change: function ($event) {
                    _vm.radioButtonValue = _vm.id
                  },
                },
              }),
              _c("div", { staticClass: "emd-radio-button__area" }),
              _vm.$slots.icon
                ? _c(
                    "span",
                    { staticClass: "emd-form-box__image" },
                    [_vm._t("icon")],
                    2
                  )
                : _vm._e(),
              _c("span", { staticClass: "emd-radio-button__text" }, [
                _vm._v(_vm._s(_vm.label)),
              ]),
            ]
          ),
          _vm.$slots.helper
            ? _c("div", { staticClass: "emd-form-box__helper" }, [
                _c(
                  "div",
                  { staticClass: "emd-text _size_tiny" },
                  [_vm._t("helper")],
                  2
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.$slots.aside
        ? _c(
            "div",
            { staticClass: "emd-form-box__aside" },
            [_vm._t("aside")],
            2
          )
        : _vm._e(),
      _vm.$slots.content
        ? _c(
            "div",
            { staticClass: "emd-form-box__content" },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }