<template>
  <div>
    <section class="el-centered" v-if="isLoadingSubmit">
      <emd-pagarme-loader />
    </section>
    <section v-else>
      <div class="el-chunk">
        <emd-modal scrollable has-footer :open="openModal">
          <template v-slot:content>
            <div class="header">
              <span class="emd-text _size_subpage _color_primary eu-flex-item">
                <i class="uil uil-info-circle" />
                {{ $t('modalTitle') }}
              </span>
            </div>
            <h2 class="emd-text _size_section _color_subtle eu-flex-item body">
              {{ $t('modalMessage') }}
            </h2>
          </template>
        </emd-modal>
      </div>
      <div class="emd-container _type_card" v-if="isEditable">
        <div class="emd-container__content">
          <div class="el-chunk">
            <h2 class="eu-capitalize">
              {{ customer.fields.name.value || '-' }}
            </h2>
          </div>
          <p v-if="customer.fields.email" class="emd-text _size_small">
            {{ customer.fields.email.value }}
          </p>
          <p v-if="customer.fields.document" class="emd-text _size_small">
            {{ customer.fields.document.value | document }}
          </p>
          <p v-if="customer.fields.home_phone" class="emd-text _size_small">
            {{ customer.fields.home_phone.value | phone }}
          </p>
          <p v-if="customer.fields.mobile_phone" class="emd-text _size_small">
            {{ customer.fields.mobile_phone.value | phone }}
          </p>
        </div>
      </div>
      <customer-editable v-else/>
    </section>
  </div>
</template>

<script>
import CustomerEditable from './CustomerEditable.vue'
import { EmdModal, EmdPagarmeLoader } from 'emerald-vue/src/components'
import { onVisitPage } from '@/utils/analitica/events'

export default {
  name: 'Customer',
  components: {
    CustomerEditable,
    EmdModal,
    EmdPagarmeLoader
  },
  data () {
    return {
      openModal: false
    }
  },
  computed: {
    isLoadingSubmit () {
      return this.$store.getters.loadingSubmit
    },
    customer: {
      get () {
        return this.$store.getters.customer
      }
    },
    isEditable () {
      return !this.customer.is_editable
    }
  },
  created () {
    onVisitPage.events.view.onVisitPage('customer')
    setTimeout(() => {
      this.openModal = true
    }, 300000)
  }
}
</script>

<style>
.header {
  padding-top: 13px
}
.body {
  padding-top: 6px
}
.el-centered {
  height: 50vh !important
}
</style>

<i18n>
{
  "pt-BR": {
    "modalTitle": "Sua sessão expirou.",
    "modalMessage": "Tente atualizar a página para realizar o pagamento."
  },
  "en-US": {
    "modalTitle": "Your session has expired.",
    "modalMessage": "Try reload the page to finalize the payment."
  }
}
</i18n>
