<template>
  <div class="el-chunk">
    <div class="emd-form-group" :class="{ '_state_error': payment.bank.error }">
      <label class="emd-label">{{ $t('chooseYourBank') }}</label>
      <div class="emd-select">
        <select v-model="payment.bank.value">
          <option :value="null" disabled>
            {{ $t('select') }}
          </option>
          <option v-for="bank in banks" :value="bank.key" v-bind:key="bank.key">{{ bank.value }}</option>
        </select>
        <div class="emd-select__arrow"></div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "select": "Selecione",
    "chooseYourBank": "Escolha seu banco"
  },
  "en-US": {
    "select": "Select",
    "chooseYourBank": "Choose your bank"
  }
}
</i18n>

<script>
export default {
  name: 'BankTransfer',
  computed: {
    banks () {
      return this.$store.getters.payment.banks
    },
    payment () {
      return this.$store.getters.payment.payment_1
    }
  }
}
</script>
