import store from '@/store'

export const getPaymentLinkData = (token, next) => {
  if (token && token.includes('pl_')) {
    store.dispatch('GET_PAYMENT_LINK', { token: token })
      .then((res) => {
        window.document.title = 'Checkout Pagar.me'
        store.dispatch('CREATE_SESSION', { token: token })
        next()
      })
      .catch((erro) => {
        if (erro.status === 404) {
          window.document.title = 'Página não encontrada! | Checkout Pagar.me'
        } else {
          window.document.title = 'Instabilidade no sistema | Checkout Pagar.me'
        }
        next()
      })
  } else {
    store.dispatch('TOGGLE_GLOBAL_LOADING', { isLoading: false })
    store.dispatch('TOGGLE_GLOBAL_ERROR', { status: true, code: 404 })
    window.document.title = 'Página não encontrada! | Checkout Pagar.me'
    next()
  }
}
