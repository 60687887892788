<template>
  <div class="emd-logo-wrapper">
    <template v-if="account">
      <template v-if="account.website">
        <a v-if="account.logo" class="emd-logo-link" :href="account.website" target="_blank" rel="noopener noreferrer">
          <img :src="account.logo" class="emd-logo-link__image" :title="account.name" :alt="`Logo ${account.name}`" />
        </a>
        <a v-else class="emd-text _size_subpage emd-link" :href="account.website" target="_blank" rel="noopener noreferrer">
          <default-logo/>
        </a>
      </template>
      <template v-else>
        <img v-if="account.logo" :src="account.logo" class="emd-logo-link__image" :title="account.name ? account.name : ''" :alt="account.name ? `Logo ${account.name}` : ''">
        <h2 v-else class="emd-text _size_subpage">
          <default-logo/>
        </h2>
      </template>
    </template>
    <h2 v-else class="emd-text _size_subpage">
      <default-logo/>
    </h2>
  </div>
</template>

<script>

import DefaultLogo from '@/components/project/globals/Logo.vue'
export default {
  components: {
    DefaultLogo
  },
  name: 'LogoWrapper',
  props: {
    account: {
      type: Object
    }
  }
}
</script>

<style>
.emd-logo-wrapper,
.emd-logo-link__image {
  max-height: 100px;
  height: auto;
}
</style>
