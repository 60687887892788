<template>
  <div class="eu-align_center">
    <div class="el-section">
      <div class="emeraldraws">
        <svg width="305" height="305" viewBox="0 0 305 305" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M232.79 112.395C232.79 163.077 191.665 204.202 140.983 204.202C90.3006 204.202 49.1757 163.077 49.1757 112.395C49.1757 53.6997 90.3006 20.5874 140.983 20.5874C191.665 20.5874 232.79 61.6158 232.79 112.395Z" fill="#6045AF"/>
          <path d="M60.8571 274.578H15.1948C15.1948 274.578 -15.0214 218.393 12.0091 214.339C39.0396 210.187 44.1561 248.32 44.1561 248.32C44.1561 248.32 58.3471 239.824 60.8571 274.578Z" fill="white"/>
          <path d="M61.6289 275.253H14.7117L14.5186 274.867C13.6498 273.323 -6.04387 236.252 1.8722 220.42C3.80295 216.559 7.18176 214.338 11.8156 213.566C18.4767 212.601 24.3654 213.952 29.3854 217.717C40.3907 226.019 43.866 242.913 44.6383 247.161C45.9898 246.678 48.5964 246.003 51.4925 247.547C56.9951 250.443 60.3739 259.518 61.5324 274.481L61.6289 275.253ZM15.5805 273.805H60.0843C58.7328 256.429 54.2921 250.636 50.9133 248.802C47.631 247.064 44.6383 248.802 44.5418 248.802L43.5764 249.381L43.3833 248.319C43.3833 248.126 40.4872 227.757 28.5166 218.876C23.7862 215.304 18.2836 214.049 12.0086 215.014C7.76099 215.69 4.76832 217.621 3.12719 221.096C-4.01659 235.48 13.5532 269.847 15.5805 273.805Z" fill="#2B2B2B"/>
          <path d="M272.371 274.578H249.492C252.291 261.835 272.178 237.7 272.371 274.578Z" fill="white"/>
          <path d="M273.142 275.253H248.622L248.815 274.385C250.649 266.179 259.048 253.629 265.709 254.305C270.536 254.787 272.949 261.642 273.046 274.578L273.142 275.253ZM250.456 273.805H271.694C271.501 262.51 269.377 256.139 265.612 255.753C260.206 255.174 252.483 266.275 250.456 273.805Z" fill="#2B2B2B"/>
          <path d="M301.429 274.578H278.549C273.915 221.675 301.236 237.701 301.429 274.578Z" fill="white"/>
          <path d="M302.201 275.254H277.97L277.873 274.578C276.232 256.236 278.26 244.265 283.473 240.79C285.307 239.535 287.431 239.535 289.651 240.693C295.733 243.976 302.008 256.622 302.104 274.481L302.201 275.254ZM279.225 273.806H300.656C300.367 257.105 294.478 244.941 288.975 242.045C287.238 241.176 285.693 241.176 284.245 242.045C279.708 245.134 277.873 256.912 279.225 273.806Z" fill="#2B2B2B"/>
          <path d="M290.713 275.253H288.782C288.396 275.253 288.01 274.964 288.01 274.481C288.01 273.998 288.299 273.709 288.782 273.709H289.265C289.168 265.406 288.492 254.498 286.175 247.644C286.079 247.257 286.272 246.871 286.658 246.678C287.044 246.582 287.43 246.775 287.623 247.161C290.133 254.401 290.713 265.986 290.809 274.384L290.713 275.253Z" fill="#2B2B2B"/>
          <path d="M262.524 275.254C262.428 275.254 262.428 275.254 262.331 275.254C261.945 275.157 261.752 274.771 261.849 274.385L264.552 264.345C264.648 263.959 265.034 263.766 265.421 263.862C265.807 263.959 266 264.345 265.903 264.731L263.2 274.771C263.104 275.061 262.814 275.254 262.524 275.254Z" fill="#2B2B2B"/>
          <path d="M37.1081 275.254H35.2739C34.8877 275.254 34.5016 274.964 34.5016 274.481C34.5016 274.095 34.7912 273.806 35.0808 273.806C30.447 259.904 23.6894 241.272 17.0283 229.398C16.8352 229.012 16.9318 228.626 17.3179 228.433C17.7041 228.24 18.0902 228.336 18.2833 228.723C25.0409 240.886 32.0882 260.194 36.722 274.288L37.1081 275.254Z" fill="#2B2B2B"/>
          <path d="M42.9015 267.531C42.8049 267.531 42.7084 267.531 42.6119 267.434C42.2257 267.241 42.1292 266.855 42.3222 266.469L48.1145 254.595C48.3076 254.208 48.6937 254.112 49.0799 254.305C49.466 254.498 49.5626 254.884 49.3695 255.27L43.5772 267.145C43.3842 267.434 43.0945 267.531 42.9015 267.531Z" fill="#2B2B2B"/>
          <path d="M232.404 267.917C232.404 267.917 230.57 261.932 225.357 260.29C222.364 259.325 218.406 260.097 214.738 253.05C212.132 247.934 208.753 230.267 204.119 214.146C189.831 163.753 172.744 136.722 172.744 136.722L161.546 147.052H134.226C130.075 174.276 86.0535 264.055 86.0535 264.055L123.607 283.846C123.607 283.846 126.31 278.246 123.607 273.516C120.904 268.786 112.891 266.179 136.253 233.356C146.389 219.069 153.919 200.823 159.229 184.702C174.192 221.193 191.376 278.826 191.376 278.826L232.404 267.917Z" fill="white"/>
          <path d="M123.993 284.811L85.1849 264.345L85.4746 263.669C85.9572 262.8 129.496 173.696 133.55 146.859L133.647 146.279H161.353L172.938 135.564L173.42 136.336C173.613 136.626 190.7 164.042 204.891 213.952C206.726 220.517 208.463 227.371 209.911 233.453C212.035 242.238 213.869 249.768 215.414 252.76C218.214 258.166 221.013 258.746 223.716 259.228C224.392 259.325 224.971 259.518 225.647 259.711C231.15 261.449 233.08 267.53 233.177 267.82L233.37 268.496L190.99 279.887L190.797 279.212C190.604 278.632 173.999 223.123 159.422 186.922C152.954 206.229 145.424 221.965 137.026 233.839C116.656 262.511 120.518 267.724 123.317 271.585C123.703 272.164 124.09 272.647 124.379 273.226C127.275 278.246 124.476 284.038 124.379 284.231L123.993 284.811ZM87.0192 263.669L123.221 282.783C123.8 281.239 124.958 277.184 122.931 273.805C122.641 273.323 122.255 272.84 121.869 272.357C118.587 267.917 114.532 262.414 135.578 232.874C144.169 220.806 151.892 204.492 158.457 184.508L159.036 182.674L159.808 184.508C173.71 218.489 189.832 271.489 191.762 278.053L231.343 267.434C230.667 265.889 228.833 262.221 225.068 261.062C224.489 260.869 223.909 260.773 223.33 260.676C220.627 260.194 217.152 259.518 214.063 253.436C212.421 250.347 210.684 243.01 208.463 233.839C207.015 227.757 205.278 220.903 203.443 214.435C190.604 169.255 175.448 142.611 172.648 137.881L161.932 147.824H134.902C130.364 174.275 90.9772 255.656 87.0192 263.669Z" fill="#2B2B2B"/>
          <path d="M214.738 253.05C205.278 256.139 195.914 259.615 186.356 262.511C189.446 272.454 191.376 278.826 191.376 278.826L232.405 267.82C232.405 267.82 230.571 261.835 225.358 260.194C222.365 259.325 218.503 260.097 214.738 253.05Z" fill="#2B2B2B"/>
          <path d="M216.861 255.077C215.22 256.815 213.579 258.456 211.938 260.194C211.552 260.58 212.131 261.256 212.517 260.869C214.158 259.132 215.8 257.491 217.441 255.753C217.923 255.367 217.248 254.691 216.861 255.077Z" fill="white"/>
          <path d="M219.371 256.043C217.73 257.78 216.089 259.422 214.448 261.159C214.061 261.545 214.641 262.221 215.027 261.835C216.668 260.097 218.309 258.456 219.95 256.718C220.336 256.236 219.757 255.657 219.371 256.043Z" fill="white"/>
          <path d="M120.807 260.966C111.733 256.718 102.465 252.953 93.4869 248.609C89.0462 257.973 86.0535 264.055 86.0535 264.055L123.607 283.845C123.607 283.845 126.31 278.246 123.607 273.516C122.062 270.716 118.683 268.689 120.807 260.966Z" fill="#2B2B2B"/>
          <path d="M120.903 263.862C118.586 263.959 116.173 264.152 113.856 264.248C113.277 264.248 113.277 265.117 113.856 265.117C116.173 265.02 118.586 264.827 120.903 264.731C121.482 264.731 121.482 263.862 120.903 263.862Z" fill="white"/>
          <path d="M122.061 266.179C119.744 266.275 117.331 266.468 115.014 266.565C114.434 266.565 114.434 267.434 115.014 267.434C117.331 267.337 119.744 267.144 122.061 267.048C122.64 267.048 122.64 266.179 122.061 266.179Z" fill="white"/>
          <path d="M159.035 183.543C158.938 183.543 158.842 183.543 158.745 183.446C158.359 183.253 158.166 182.867 158.359 182.481C161.931 173.406 163.089 167.035 163.089 166.938L163.186 166.456L169.847 164.428C170.233 164.332 170.619 164.525 170.716 164.911C170.812 165.297 170.619 165.683 170.233 165.78L164.441 167.518C164.151 169.159 162.8 175.048 159.71 182.867C159.614 183.35 159.324 183.543 159.035 183.543Z" fill="#2B2B2B"/>
          <path d="M239.258 145.893C237.23 143.19 234.527 141.259 225.453 141.066C216.571 140.97 209.331 135.95 204.601 130.351C201.125 134.019 197.843 137.881 194.464 141.742C193.016 143.287 191.761 144.928 190.41 146.569C201.029 157.767 214.93 159.312 219.95 158.636C229.314 157.478 226.708 162.304 233.755 159.601C238.003 157.96 242.54 155.064 242.54 150.72C242.54 149.079 240.416 147.341 239.258 145.893Z" fill="white"/>
          <path d="M230.28 161.146C229.025 161.146 228.253 160.76 227.384 160.277C226.032 159.601 224.488 158.733 220.047 159.312C215.51 159.891 201.126 158.829 189.831 147.052L189.348 146.569L190.217 145.507C191.375 144.155 192.534 142.707 193.789 141.259C194.851 140.004 195.913 138.846 197.071 137.591C199.291 135.081 201.608 132.378 204.022 129.868L204.601 129.289L205.084 129.868C210.586 136.432 218.02 140.197 225.453 140.39C234.335 140.487 237.424 142.321 239.837 145.507C240.127 145.893 240.513 146.279 240.899 146.762C242.058 148.017 243.216 149.272 243.216 150.623C243.216 154.485 240.03 157.864 233.948 160.181C232.404 160.856 231.149 161.146 230.28 161.146ZM222.75 157.671C225.55 157.671 226.901 158.346 228.06 158.926C229.411 159.601 230.28 160.084 233.562 158.829C237.327 157.284 241.864 154.678 241.864 150.623C241.864 149.851 240.803 148.693 239.934 147.727C239.451 147.245 239.065 146.762 238.775 146.376C237.038 144.059 234.721 142.032 225.55 141.838C217.923 141.742 210.393 137.977 204.601 131.509C202.477 133.826 200.353 136.239 198.229 138.556C197.168 139.811 196.106 140.97 194.947 142.225C193.692 143.576 192.534 145.024 191.375 146.376L191.279 146.472C201.994 157.381 215.799 158.346 219.757 157.864C221.012 157.767 221.978 157.671 222.75 157.671Z" fill="#2B2B2B"/>
          <path d="M206.435 132.281C201.608 137.494 194.851 145.41 189.831 150.527C186.162 146.955 182.88 142.321 180.37 136.239C179.405 133.922 178.632 130.93 177.957 127.647C175.929 117.511 175.447 104.092 175.253 95.5969C175.157 90.6735 175.253 87.2947 175.253 87.2947C178.343 87.7774 181.046 89.0324 183.459 90.8666C193.403 98.2034 197.264 114.229 199.195 120.697C200.16 124.269 202.767 128.613 206.435 132.281Z" fill="#2B2B2B"/>
          <path d="M189.831 151.588L189.349 151.106C185.294 147.148 182.012 142.224 179.695 136.625C178.73 134.405 177.957 131.412 177.282 127.937C175.351 117.993 174.772 104.961 174.579 95.7897C174.482 90.8663 174.579 87.5841 174.579 87.4875V86.6187L175.447 86.7152C178.44 87.1979 181.336 88.4529 183.943 90.3836C193.307 97.3343 197.265 111.332 199.485 118.862C199.678 119.441 199.871 120.021 199.968 120.6C201.03 124.172 203.636 128.419 207.015 131.798L207.498 132.281L207.015 132.764C205.181 134.791 203.057 137.108 200.837 139.618C197.265 143.672 193.5 147.823 190.411 151.009L189.831 151.588ZM175.93 88.3564C175.93 89.6114 175.93 92.3144 175.93 95.6932C176.123 104.768 176.702 117.704 178.633 127.551C179.309 131.026 180.081 133.826 180.95 135.949C183.17 141.162 186.067 145.7 189.735 149.465C192.728 146.472 196.203 142.514 199.582 138.652C201.609 136.335 203.54 134.115 205.374 132.281C202.092 128.806 199.485 124.654 198.423 120.986C198.23 120.503 198.134 119.924 197.941 119.248C195.817 111.815 191.859 98.1067 182.977 91.5421C180.853 89.901 178.44 88.8391 175.93 88.3564Z" fill="#2B2B2B"/>
          <path d="M238.197 153.326C236.653 151.782 234.239 149.175 231.826 149.561C230.088 149.851 229.123 151.492 229.509 153.133C229.123 153.133 228.737 153.133 228.254 153.23C226.323 153.809 226.903 156.222 227.964 157.284C228.351 157.67 229.413 156.898 228.93 156.512C228.737 156.319 228.447 156.029 228.254 155.74C228.061 155.354 227.964 154.581 228.447 154.292C229.026 153.905 230.185 154.485 230.764 154.774C231.729 155.257 232.791 156.029 233.757 156.608C234.239 156.898 234.915 156.319 234.239 155.74C233.178 154.774 231.826 153.712 230.378 153.423C230.378 153.423 230.378 153.423 230.378 153.326C229.992 152.361 230.474 151.395 231.44 150.816C233.66 149.465 236.17 153.037 237.232 154.002C237.908 154.485 238.584 153.712 238.197 153.326Z" fill="#2B2B2B"/>
          <path d="M233.37 147.341C232.502 147.824 232.019 148.789 232.308 149.754C232.405 150.43 233.37 150.141 233.274 149.465C233.177 148.886 233.467 148.403 233.95 148.21C234.432 147.92 233.95 147.051 233.37 147.341Z" fill="#2B2B2B"/>
          <path d="M241.189 149.561C240.61 148.789 239.838 148.306 238.872 148.017C238.293 147.824 238.1 148.693 238.679 148.886C239.452 149.079 240.127 149.561 240.61 150.141C240.9 150.623 241.575 150.044 241.189 149.561Z" fill="#2B2B2B"/>
          <path d="M237.424 143.672C237.135 143.093 236.266 143.576 236.556 144.155C237.135 145.12 237.618 145.989 238.004 147.341C238.197 148.692 237.811 150.913 235.976 149.561C235.108 148.692 234.625 147.823 234.335 147.051C234.239 146.762 233.949 146.665 233.659 146.762C230.474 148.21 227.191 148.982 223.62 148.982C223.04 148.982 223.04 149.947 223.62 149.947C227.095 149.947 230.474 149.272 233.659 147.823C234.335 149.561 236.459 152.361 238.39 150.913C240.417 149.465 238.293 145.12 237.424 143.672Z" fill="#2B2B2B"/>
          <path d="M283.472 140.777H283.376L271.791 138.653C271.405 138.556 271.115 138.17 271.212 137.784C271.308 137.398 271.695 137.108 272.081 137.205L283.665 139.329C284.051 139.425 284.341 139.811 284.244 140.197C284.148 140.584 283.858 140.777 283.472 140.777Z" fill="#2B2B2B"/>
          <path d="M276.618 145.603H276.522C276.136 145.507 275.846 145.121 275.943 144.734L278.066 133.15C278.163 132.764 278.549 132.474 278.935 132.571C279.321 132.667 279.611 133.053 279.514 133.44L277.391 145.024C277.198 145.314 276.908 145.603 276.618 145.603Z" fill="#2B2B2B"/>
          <path d="M287.236 135.95C286.078 135.95 285.016 135.37 284.34 134.405C283.278 132.764 283.665 130.64 285.209 129.482C285.981 128.902 286.947 128.709 287.816 128.902C288.781 129.096 289.553 129.578 290.036 130.351C290.615 131.123 290.808 132.088 290.615 132.957C290.422 133.826 289.939 134.695 289.167 135.177C288.588 135.757 287.912 135.95 287.236 135.95ZM287.236 130.351C286.85 130.351 286.368 130.447 286.078 130.737C285.113 131.412 284.92 132.667 285.595 133.633C286.271 134.598 287.526 134.791 288.491 134.115C288.974 133.826 289.264 133.343 289.36 132.764C289.457 132.185 289.36 131.702 289.071 131.219C288.781 130.737 288.298 130.447 287.719 130.351C287.43 130.351 287.333 130.351 287.236 130.351Z" fill="#2B2B2B"/>
          <path d="M175.252 87.4875C153.724 89.901 144.36 108.436 136.83 129.674C129.3 150.913 130.362 149.561 130.362 149.561L181.817 151.009C181.817 151.009 173.901 134.115 178.534 126.489C183.265 118.766 198.228 96.8517 175.252 87.4875Z" fill="#2B2B2B"/>
          <path d="M182.976 151.685L130.652 150.14L130.459 150.333L129.88 150.044C129.204 149.658 128.915 149.465 136.058 129.288C142.913 109.981 152.084 89.2252 175.06 86.6187H175.253L175.446 86.7152C181.914 89.3217 185.968 93.1832 187.61 98.1066C190.795 107.857 183.748 119.248 179.983 125.427C179.693 125.909 179.404 126.296 179.211 126.682C174.77 133.922 182.493 150.333 182.493 150.527L182.976 151.685ZM131.232 148.789L180.755 150.237C179.114 146.375 173.805 132.957 177.956 126.103C178.149 125.716 178.438 125.234 178.728 124.848C182.397 118.959 189.251 107.857 186.258 98.7824C184.81 94.3417 181.045 90.7698 175.156 88.2598C153.242 90.8663 144.264 111.139 137.603 129.964C132.873 142.997 131.521 147.341 131.232 148.789Z" fill="#2B2B2B"/>
          <path d="M179.113 124.655C178.92 124.655 178.727 124.558 178.631 124.461C178.341 124.172 178.341 123.689 178.631 123.4C183.265 118.669 187.223 108.34 185.968 97.8172C185.968 97.431 186.161 97.0449 186.643 97.0449C187.03 96.9483 187.416 97.2379 187.416 97.7206C188.767 108.726 184.52 119.538 179.693 124.461C179.5 124.558 179.307 124.655 179.113 124.655Z" fill="white"/>
          <path d="M229.315 172.923L244.857 184.314C244.857 184.314 249.395 176.591 242.734 171.378C235.976 166.165 229.315 172.923 229.315 172.923Z" fill="#65A300"/>
          <path d="M248.818 155.773L239.773 156.51L240.511 165.555L249.555 164.818L248.818 155.773Z" fill="#2B2B2B"/>
          <path d="M225.163 188.949C225.163 188.949 225.067 188.949 225.163 188.949C224.681 188.949 224.391 188.563 224.488 188.176L227.287 156.512C227.287 156.126 227.673 155.836 228.06 155.836C228.446 155.836 228.735 156.223 228.735 156.609L225.936 188.273C225.839 188.659 225.55 188.949 225.163 188.949Z" fill="#2B2B2B"/>
          <path d="M273.914 155.45H273.817L240.319 149.561C239.933 149.465 239.643 149.079 239.74 148.692C239.836 148.306 240.222 148.017 240.609 148.113L274.107 154.002C274.493 154.099 274.783 154.485 274.686 154.871C274.59 155.257 274.3 155.45 273.914 155.45Z" fill="#2B2B2B"/>
          <path d="M275.561 149.997L221.243 187.549L249.572 228.526L303.889 190.973L275.561 149.997Z" fill="white"/>
          <path d="M275.363 151.009L302.876 190.783L249.78 227.564L222.267 187.79L275.363 151.009ZM275.749 148.982L274.59 149.851L221.495 186.632L220.336 187.501L221.205 188.659L248.718 228.433L249.587 229.591L250.746 228.722L303.841 191.941L305 191.073L304.131 189.914L276.618 150.141L275.749 148.982Z" fill="#2B2B2B"/>
          <path d="M237.81 195.706C237.617 195.706 237.327 195.61 237.231 195.417C237.038 195.127 237.038 194.644 237.424 194.451L256.924 180.936C257.214 180.743 257.697 180.743 257.89 181.129C258.083 181.419 258.083 181.902 257.697 182.095L238.196 195.61C238.1 195.61 238.003 195.706 237.81 195.706Z" fill="#2B2B2B"/>
          <path d="M242.541 202.56C242.348 202.56 242.058 202.464 241.961 202.271C241.768 201.981 241.768 201.498 242.155 201.305L261.655 187.79C261.945 187.597 262.427 187.597 262.621 187.983C262.814 188.369 262.814 188.755 262.427 188.948L242.927 202.464C242.83 202.464 242.734 202.56 242.541 202.56Z" fill="#2B2B2B"/>
          <path d="M263.969 179.965C265.153 179.146 265.45 177.523 264.631 176.339C263.812 175.155 262.189 174.858 261.005 175.677C259.821 176.496 259.525 178.119 260.343 179.303C261.162 180.487 262.785 180.783 263.969 179.965Z" fill="#65A300"/>
          <path d="M290.808 173.116L302.972 190.686L278.259 207.773C276.714 206.615 275.266 205.167 274.107 203.429C268.026 194.644 270.246 182.674 279.031 176.592C282.699 174.082 286.85 173.02 290.808 173.116Z" fill="#65A300"/>
          <path d="M125.92 147.728C129.01 149.658 132.388 153.809 131.713 159.409C131.23 162.884 127.562 168.676 125.438 166.842C121.094 162.787 125.92 147.728 125.92 147.728Z" fill="white"/>
          <path d="M126.307 167.807C125.824 167.807 125.342 167.614 124.859 167.228C120.225 162.98 124.956 148.017 125.149 147.438L125.438 146.569L126.211 147.052C129.686 149.272 133.065 153.616 132.292 159.408C131.906 162.304 129.396 166.842 127.079 167.711C126.886 167.807 126.597 167.807 126.307 167.807ZM126.307 148.886C124.956 153.52 122.832 163.463 125.921 166.262C126.114 166.359 126.307 166.552 126.693 166.359C128.334 165.78 130.651 161.918 130.941 159.312C131.617 154.581 129.107 150.913 126.307 148.886Z" fill="#2B2B2B"/>
          <path d="M140.209 193.1C139.92 193.1 139.63 192.907 139.533 192.617L126.115 160.47L110.476 162.401L92.4231 191.265C92.23 191.652 91.7473 191.748 91.4577 191.458C91.0716 191.265 90.975 190.783 91.2646 190.493L109.703 161.146L127.08 158.925L140.885 192.134C141.078 192.52 140.885 192.907 140.499 193.1C140.402 193.1 140.306 193.1 140.209 193.1Z" fill="#2B2B2B"/>
          <path d="M128.14 150.527C128.623 146.279 129.782 140.777 131.905 135.274C125.341 134.405 118.68 133.922 112.115 132.474C105.744 151.492 107.192 167.807 114.142 166.456C114.142 166.456 115.784 168.483 119.742 167.324C119.742 167.324 121.672 168.579 123.7 167.904C125.534 167.324 126.017 165.587 126.499 162.787" fill="white"/>
          <path d="M122.446 168.869C121.191 168.869 120.129 168.386 119.646 168.097C116.364 168.966 114.53 167.807 113.854 167.228C112.406 167.421 111.054 166.842 109.992 165.683C105.841 160.953 106.42 146.859 111.344 132.281L111.537 131.702L112.213 131.799C116.75 132.764 121.384 133.343 125.921 133.826C127.948 134.019 129.879 134.309 131.906 134.598L132.775 134.695L132.486 135.564C130.265 141.452 129.3 146.955 128.817 150.624C128.721 151.01 128.431 151.299 128.045 151.299C127.659 151.203 127.369 150.913 127.369 150.527C127.852 146.955 128.721 141.646 130.844 135.95C129.203 135.757 127.466 135.564 125.728 135.371C121.384 134.888 116.943 134.309 112.502 133.44C107.579 148.5 107.869 161.146 111.054 164.815C111.827 165.683 112.792 166.07 113.854 165.876L114.24 165.78L114.53 166.166C114.626 166.263 115.978 167.807 119.356 166.842L119.646 166.745L119.936 166.938C119.936 166.938 121.577 168 123.314 167.421C124.763 166.938 125.149 165.683 125.631 162.884C125.728 162.498 126.114 162.208 126.5 162.305C126.886 162.401 127.176 162.787 127.079 163.173C126.597 166.07 126.114 168.097 123.797 168.869C123.411 168.773 122.928 168.869 122.446 168.869Z" fill="#2B2B2B"/>
          <path d="M175.252 87.4878C175.252 87.4878 174.19 90.8666 171.101 95.4039C167.432 100.907 160.964 108.243 150.152 113.553C146.097 115.58 142.719 118.283 139.919 121.469C135.478 126.489 132.679 132.474 130.844 138.267C124.57 136.336 113.082 134.791 106.614 133.44C107.386 131.026 108.255 128.613 109.317 126.199C119.936 101.196 143.201 87.874 163.764 86.9086C167.722 86.4259 171.583 86.7155 175.252 87.4878Z" fill="#2B2B2B"/>
          <path d="M131.327 139.039L130.651 138.846C126.79 137.687 120.805 136.625 115.592 135.66C112.213 135.081 109.027 134.501 106.517 133.922L105.745 133.729L106.034 132.957C106.903 130.35 107.772 127.937 108.737 125.62C120.611 97.6242 145.808 86.7154 163.764 85.8466C167.722 85.6535 171.68 85.9431 175.348 86.6189L176.121 86.8119L175.831 87.5842C175.831 87.6808 174.672 91.0596 171.583 95.5969C168.011 101.003 161.447 108.533 150.345 113.939C146.58 115.773 143.201 118.38 140.402 121.662C136.54 126.006 133.547 131.605 131.424 138.17L131.327 139.039ZM107.579 132.667C109.896 133.15 112.792 133.633 115.785 134.212C121.094 135.177 126.5 136.046 130.362 137.205C132.582 130.64 135.575 125.137 139.436 120.793C142.429 117.318 146.001 114.615 149.862 112.781C160.675 107.471 167.046 100.134 170.521 94.9211C172.645 91.7354 173.804 89.2254 174.286 87.9704C170.908 87.3912 167.432 87.1981 163.86 87.3912C146.29 88.26 121.673 98.9757 110.089 126.296C109.22 128.323 108.351 130.447 107.579 132.667Z" fill="#2B2B2B"/>
          <path d="M140.982 119.924C140.885 119.924 140.692 119.924 140.596 119.828C140.306 119.635 140.209 119.152 140.403 118.863C140.596 118.476 146.098 110.271 151.697 106.506C151.987 106.313 152.47 106.409 152.663 106.699C152.856 106.988 152.759 107.471 152.47 107.664C147.064 111.236 141.658 119.538 141.561 119.635C141.464 119.828 141.175 119.924 140.982 119.924Z" fill="white"/>
          <path d="M119.743 158.25C118.874 161.049 118.681 163.849 119.067 166.648C119.163 167.228 120.129 167.035 120.032 166.359C119.646 163.656 119.839 161.049 120.708 158.443C120.901 157.863 119.936 157.574 119.743 158.25Z" fill="#2B2B2B"/>
          <path d="M114.337 158.057C114.433 157.478 113.468 157.188 113.371 157.767C112.985 160.084 113.082 162.304 113.275 164.621C113.275 165.201 114.24 165.201 114.24 164.621C114.047 162.498 113.95 160.277 114.337 158.057Z" fill="#2B2B2B"/>
          <path d="M129.205 157.864C127.95 157.671 127.178 158.636 126.791 159.698C126.019 161.532 125.633 163.463 125.44 165.394C125.343 165.973 126.309 165.973 126.405 165.394C126.598 163.946 127.177 158.539 129.301 158.829C129.784 158.926 129.784 157.96 129.205 157.864Z" fill="#2B2B2B"/>
          <path d="M184.909 60.3608C183.171 57.5612 180.468 55.4373 177.283 54.8581C170.428 53.6996 163.864 58.9127 162.609 66.5391C162.609 66.5391 162.609 66.5392 162.609 66.6357C162.512 67.408 162.416 68.1803 162.416 68.9526C159.327 70.111 158.747 74.1656 160.292 76.6756C161.74 78.8959 164.154 80.1509 167.05 79.3786C168.691 80.8267 170.525 81.8886 172.649 82.3713C182.206 84.7847 187.516 78.7028 187.323 70.6902C187.226 67.2149 186.84 63.3534 184.909 60.3608Z" fill="white"/>
          <path d="M176.606 83.7228C175.255 83.7228 173.903 83.5297 172.359 83.1436C170.331 82.6609 168.401 81.599 166.76 80.2475C163.863 80.8267 161.257 79.6683 159.616 77.0617C158.65 75.4206 158.361 73.2968 158.94 71.4626C159.423 70.111 160.292 69.0491 161.547 68.4699C161.547 67.7941 161.643 67.1184 161.74 66.4426V66.346C162.415 62.2915 164.636 58.6231 167.918 56.3062C170.718 54.2789 174.096 53.5066 177.282 54.0858C180.564 54.665 183.461 56.6923 185.488 59.8781C187.612 63.1603 187.901 67.408 187.998 70.6902C188.094 75.2275 186.453 79.089 183.557 81.4059C181.626 82.9505 179.213 83.7228 176.606 83.7228ZM167.146 78.7029L167.435 78.9925C168.98 80.344 170.814 81.3094 172.745 81.8886C176.703 82.9505 180.178 82.4678 182.592 80.4405C185.102 78.4133 186.55 74.9379 186.55 70.8833C186.55 67.601 186.164 63.8361 184.329 60.8434C182.495 58.0439 179.985 56.2096 177.186 55.7269C174.29 55.2442 171.393 55.92 168.883 57.7542C165.987 59.7815 163.96 63.1603 163.284 66.8287V66.9253C163.188 67.6011 163.091 68.2768 163.091 69.1457V69.6283L162.608 69.8214C161.257 70.3041 160.678 71.366 160.388 72.1383C159.905 73.4898 160.099 75.2275 160.871 76.4825C162.222 78.6063 164.346 79.4751 166.856 78.8959L167.146 78.7029Z" fill="#2B2B2B"/>
          <path d="M178.261 69.6041C178.546 69.1534 178.412 68.5571 177.961 68.2722C177.51 67.9873 176.914 68.1216 176.629 68.5723C176.344 69.0229 176.479 69.6192 176.929 69.9041C177.38 70.189 177.976 70.0547 178.261 69.6041Z" fill="#2B2B2B"/>
          <path d="M183.653 74.3585C183.749 72.2346 183.46 70.0142 182.977 67.9869C182.784 67.4077 181.915 67.6008 182.012 68.18C182.494 70.2073 182.784 72.2346 182.687 74.3585C182.687 74.9377 183.653 74.9377 183.653 74.3585Z" fill="#2B2B2B"/>
          <path d="M167.918 74.0689C166.76 72.1381 164.346 71.1728 162.222 71.8485C161.643 72.0416 161.836 73.007 162.416 72.8139C164.153 72.2347 166.084 72.9105 167.049 74.4551C167.339 75.0343 168.208 74.6481 167.918 74.0689Z" fill="#2B2B2B"/>
          <path d="M167.435 69.1456C169.076 71.0763 169.945 67.3114 174.772 63.7395C176.895 62.1949 188.576 61.0364 188.576 56.7888C188.576 50.7069 180.467 55.3407 177.185 54.7614C170.331 53.603 160.87 59.0091 161.836 69.5317C161.836 69.7248 166.373 68.7594 167.435 69.1456Z" fill="#2B2B2B"/>
          <path d="M164.442 63.1603C166.662 61.5192 166.469 61.133 169.076 60.3607C168.979 52.7343 159.326 42.4047 149.189 48.8728C144.555 51.8654 143.783 58.7196 143.59 59.2023C138.86 57.3681 132.681 57.7542 129.013 61.133C123.607 66.0565 125.634 74.5517 131.33 78.3167C137.508 82.4678 146.196 81.6955 152.664 78.2202C162.994 72.621 160.387 59.878 161.353 59.685C162.608 59.3954 163.863 61.9053 164.442 63.1603Z" fill="#2B2B2B"/>
          <path d="M183.461 90.963C183.461 91.0595 183.364 91.0595 183.364 91.156C183.171 91.4457 182.978 91.7353 182.785 92.0249C181.047 94.3418 178.151 95.5002 175.352 95.6933C175.255 95.6933 175.062 95.6933 174.965 95.6933C173.614 95.7899 172.359 95.5968 171.2 95.3071C168.401 94.5348 166.084 92.6041 164.829 90.1907C164.25 89.1288 163.96 87.8738 163.864 86.6188C167.822 86.4257 171.587 86.7154 175.255 87.3911C178.344 87.9703 181.047 89.2253 183.461 90.963Z" fill="white"/>
          <path d="M174.192 96.4656C173.034 96.4656 171.972 96.2725 170.91 95.9829C167.918 95.114 165.408 93.1833 164.153 90.4802C163.573 89.3218 163.187 87.9702 163.091 86.6187V85.943H163.766C167.724 85.7499 171.682 86.0395 175.351 86.7153C178.344 87.198 181.24 88.4529 183.846 90.3837L184.329 90.6733L184.136 91.156C184.136 91.2525 184.039 91.3491 184.039 91.3491C183.846 91.7352 183.557 92.1214 183.364 92.3145C181.626 94.5348 178.73 95.9829 175.351 96.2725C175.254 96.2725 175.158 96.2725 174.965 96.2725C174.675 96.4655 174.482 96.4656 174.192 96.4656ZM164.635 87.3911C164.732 88.2599 165.021 89.1287 165.408 89.901C166.566 92.1214 168.69 93.8591 171.296 94.6314C172.455 94.921 173.613 95.114 174.868 95.0175C174.965 95.0175 175.061 95.0175 175.158 95.0175C178.15 94.7279 180.66 93.4729 182.109 91.6387C182.205 91.5421 182.302 91.3491 182.398 91.2526C180.178 89.708 177.668 88.646 175.061 88.2599C171.779 87.4876 168.207 87.198 164.635 87.3911Z" fill="#2B2B2B"/>
          <path d="M116.042 173.914L100.309 181.515L107.91 197.248L123.643 189.647L116.042 173.914Z" fill="#2B2B2B"/>
          <path d="M148.021 189.884L87.0348 182.798L79.9495 243.785L140.936 250.87L148.021 189.884Z" fill="#65A300"/>
          <path d="M134.708 243.878C134.515 243.878 134.322 243.782 134.128 243.589L92.4243 190.976C92.1346 190.686 92.2312 190.203 92.5208 189.914C92.8104 189.624 93.2931 189.721 93.5827 190.01L135.287 242.623C135.577 242.913 135.48 243.396 135.19 243.685C135.094 243.878 134.901 243.878 134.708 243.878Z" fill="white"/>
          <path d="M87.5978 238.473C87.4047 238.473 87.2116 238.376 87.0186 238.183C86.7289 237.893 86.8255 237.411 87.1151 237.121L139.728 195.417C140.018 195.127 140.5 195.224 140.79 195.513C141.08 195.803 140.983 196.286 140.693 196.575L88.0805 238.279C87.8874 238.376 87.7909 238.473 87.5978 238.473Z" fill="white"/>
          <path d="M130.172 176.109L123.607 179.585L131.813 182.867L130.172 176.109Z" fill="#65A300"/>
          </svg>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Success'
}
</script>
