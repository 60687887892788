import { hasError } from '@/utils'

const customer = {
  state: {
    is_editable: true,
    customer_id: null,
    hasError: false,
    fields: {
      name: {
        value: null,
        validation: {
          required: true
        },
        error: false
      },
      email: {
        value: null,
        validation: {
          required: true,
          format: [
            {
              type: 'email'
            }
          ]
        },
        error: false
      },
      document: {
        value: null,
        validation: {
          required: true,
          format: [
            {
              type: 'document'
            }
          ]
        },
        error: false
      },
      type: {
        value: null,
        error: false
      },
      home_phone: {
        value: null,
        validation: {
          required: false,
          format: [
            {
              type: 'phone'
            }
          ]
        },
        error: false
      },
      mobile_phone: {
        value: null,
        validation: {
          required: false,
          format: [
            {
              type: 'phone'
            }
          ]
        },
        error: false
      }
    }
  },
  mutations: {
    setCustomerData (state, tokenData) {
      const customerData = tokenData.customer_settings && tokenData.customer_settings.customer
      state.customer_id = tokenData.customer_settings && tokenData.customer_settings.customer_id
      if (customerData) {
        state.fields.name.value = formatName(customerData.name) || null
        state.fields.email.value = customerData.email || null
        state.fields.document.value = customerData.document || null
        state.fields.type.value = customerData.type || null
        if (customerData.phones) {
          state.fields.home_phone.value = formatPhone(customerData.phones.home_phone)
          state.fields.mobile_phone.value = formatPhone(customerData.phones.mobile_phone)
        }
        state.is_editable = tokenData.customer_settings.editable
      }

      const requiredFields = tokenData.checkout_settings && tokenData.checkout_settings.required_fields
      if (requiredFields) {
        Object.keys(state.fields).forEach((key) => {
          if (state.fields[key].validation) {
            state.fields[key].validation.required = false
          }
        })

        requiredFields.forEach((propName) => {
          const propPath = propName.split('.')
          if (propPath[0] === 'customer') {
            const field = propPath[1]
            if (state.fields[field]) {
              state.fields[field].validation.required = true
            }
          }
        })
      }
    }
  },
  actions: {
    'VERIFY_CUSTOMER_FORM' (context, params = {}) {
      if (context.state.is_editable) {
        context.state.hasError = false
        const fields = context.state.fields
        Object.keys(fields).forEach((key) => {
          context.state.fields[key].error = hasError(fields[key])
          context.state.hasError = (context.state.hasError || !!context.state.fields[key].error)
        })
      }
    }
  }
}

function formatName (name) {
  if (name && name === '.') {
    return null
  }
  return name
}

function formatPhone (phone) {
  var area, number
  const isObject = (typeof phone === 'object' && phone !== null)
  if (phone) {
    if (isObject) {
      area = phone.area_code
      number = phone.number
    } else {
      area = phone.substr(0, 2)
      number = phone.substr(2)
    }
    return area + number
  } else {
    return null
  }
}

export default customer
