<template>
  <div>
    <div v-if="multipayment" class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_12_md">
        <div class="emd-form-group" :class="{ '_state_error': invalidAmount }">
          <label class="emd-label">{{ $t('amount') }}</label>
          <currency-input class="emd-input" v-model="payment.amount" v-bind="options" />
        </div>
      </div>
    </div>
    <div v-if="payment.installment" class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_12_md">
        <div class="emd-form-group" :class="{ '_state_error': payment.installment.error }">
          <label class="emd-label">{{ $t('installments') }}</label>
          <div class="emd-select" :class="{ '_disabled': installmentDisabled }">
            <select v-model="payment.installment.value" :disabled="installmentDisabled" @change="updateError('installment')">
              <option :value="null" disabled>
                {{ installmentDisabled ? $t('setAmount') : $t('selectInstallment') }}
              </option>
              <option v-for="(installment, index) in installments" :value="installment.number" v-bind:key="index">
                {{ installment.number }}x {{ installmentValue(installment.total) / installment.number | currency(currency) }} ({{ installmentValue(installment.total) | currency(currency) }})
              </option>
            </select>
            <div class="emd-select__arrow"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_9_md">
        <div class="emd-form-group" :class="{ '_state_error': (payment.cardNumber.error) }">
          <label class="emd-label">{{ $t('cardNumber') }}</label>
          <div class="emd-input-group">
            <the-mask name="cardNumber" :masked="false" class="emd-input fs-hide" placeholder="0000 0000 0000 0000" :mask="['#### #### #### ####', '#### #### #### #### ###', '#### #### #### ###']" type="tel" v-model="payment.cardNumber.value" @input="getBrandByZip"/>
            <i class="uil uil-credit-card" />
          </div>
        </div>
      </div>
      <div class="eu-flex-item eu-basis_3_md">
        <div class="emd-form-group" :class="{ '_state_warning': manualMode, '_state_error': payment.brand.error }">
          <label class="emd-label">{{ $t('cardBrand') }}</label>
          <div class="emd-select">
            <select v-model="payment.brand.value" @change="changedBrand">
              <option :value="null" disabled>
                Selecionar...
              </option>
              <option v-for="(brand, index) in brands" :value="brand" v-bind:key="index">{{ brand.toUpperCase() }}</option>
            </select>
            <div class="emd-select__arrow"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_12_md">
        <div class="emd-form-group" :class="{ '_state_error': payment.name.error }">
          <label class="emd-label">{{ $t('cardName') }}</label>
          <input class="emd-input eu-uppercase" placeholder="Nome como está no cartão" type="text" v-model="payment.name.value" @input="updateError('name')"/>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md" v-if="showHolderDocumentNumber">
      <div class="eu-flex-item eu-basis_12_md">
        <div class="emd-form-group" :class="{ '_state_error': payment.holderDocumentNumber.error }">
          <label class="emd-label">{{ $t('holderDocumentNumber') }}</label>
          <the-mask :masked="false" class="emd-input" :mask="['###.###.###-##']" type="tel" v-model="payment.holderDocumentNumber.value" @input="payment.holderDocumentNumber.error = false" />
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{ '_state_error': payment.expDate.error }">
          <label class="emd-label">{{ $t('cardExpiry') }}</label>
          <the-mask name="cardExpiry" class="emd-input fs-hide" :placeholder="$t('placeholderExpiry')" mask="##/##" type="tel" v-model="payment.expDate.value" @input="updateError('expDate')" />
        </div>
      </div>
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{ '_state_error': payment.cvv.error }">
          <label class="emd-label">{{ $t('cvv') }}</label>
          <the-mask name="cardCVV" class="emd-input fs-hide" placeholder="000" :mask="['###', '####']"  v-model="payment.cvv.value" type="tel" @input="updateError('cvv')" />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "selectInstallment": "Selecione",
    "setAmount": "Antes, preencha o valor",
    "amount": "Valor a pagar",
    "installments": "Quantidade de parcelas",
    "cardNumber": "Número do Cartão",
    "cardBrand": "Bandeira",
    "cardName": "Nome como está no cartão",
    "holderDocumentNumber": "CPF do Titular (dono do cartão)",
    "cardExpiry": "Validade",
    "placeholderExpiry": "MM/AA",
    "cvv": "CVV"
  },
  "en-US": {
    "selectInstallment": "Select",
    "setAmount": "Please, fill the amount",
    "amount": "Payment value",
    "installments": "Number of installments",
    "cardNumber": "Card Number",
    "cardBrand": "Card brand",
    "cardName": "Card name",
    "holderDocumentNumber": "Holder document",
    "cardExpiry": "Card Expiry",
    "placeholderExpiry": "MM/YY",
    "cvv": "CVV"
  }
}
</i18n>

<script>
import { TheMask } from 'vue-the-mask'
import { isEmpty } from '@/utils'

export default {
  name: 'Card',
  components: {
    TheMask
  },
  props: {
    multipayment: {
      type: Boolean,
      required: false,
      default: false
    },
    paymentKey: {
      type: String,
      required: false,
      default: 'payment_1'
    }
  },
  data () {
    return {
      oldAmount: 0,
      selectedInstallment: null,
      installmentDisabled: this.multipayment,
      invalidAmount: false,
      manualMode: false,
      binIsLoading: false,
      canSearchBin: true,
      timer: null,
      showHolderDocumentNumber: false
    }
  },
  computed: {
    payment: {
      get () {
        return this.$store.getters.payment[this.paymentKey]
      },
      set () {}
    },
    amount () {
      return this.payment.amount
    },
    options () {
      return {
        currency: this.currency,
        locale: this.$i18n.locale,
        'distraction-free': {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: false,
          hideGroupingSymbol: false
        },
        'value-as-integer': true,
        'auto-decimal-mode': true,
        precision: 2
      }
    },
    brands () {
      return this.$store.getters.payment.brands
    },
    installments () {
      return this.$store.getters.payment.installments
    },
    currency () {
      return this.$store.getters.currency
    },
    totalAmount () {
      return this.$store.getters.payment.amount
    },
    brandValue () {
      return this.payment.brand.value
    }
  },
  created () {
    if (!isEmpty(this.payment.cardNumber.value)) {
      // impede busca de bandeira se a cardNumber estiver pré-preenchido (ex.: o usuário vá e volte de revisão)
      this.canSearchBin = false
    }
    if (!isEmpty(this.payment.brand.value)) { // valida exibição do holderDocument ao entrar/sair da aba pagamento
      this.toggleShowHolderDocument(this.payment.brand.value)
    }
    // habilita campos se amount for válido
    if (this.amount > 0 && this.amount <= this.totalAmount) {
      this.installmentDisabled = false
      this.invalidAmount = false
    }
  },
  watch: {
    amount (newValue) {
      this.handleMultipaymentValue(newValue)
    },
    brandValue (newValue) {
      if (newValue) this.toggleShowHolderDocument(newValue)
    }
  },
  methods: {
    updateError (field, error) {
      if (!error) error = false
      this.$store.dispatch('UPDATE_ERRORS', { error: error, payment: this.paymentKey, field: field })
    },
    handleMultipaymentValue (value) {
      if (value !== this.oldAmount) {
        if (value <= 0 || value >= this.totalAmount) {
          this.installmentDisabled = true
          this.invalidAmount = value !== 0
          this.$store.dispatch('UPDATE_FIELD_VALUE', { payment: this.paymentKey, field: 'installment', value: null })
        } else {
          this.installmentDisabled = false
          this.invalidAmount = false
        }
        this.oldAmount = value
        this.$store.dispatch('UPDATE_MULTIPAYMENT_VALUE', { payment: this.paymentKey, amount: value })
      }
    },
    installmentValue (total) {
      if (this.payment.percentage !== 0) {
        var newAmmount = (this.payment.percentage / 100) * total
        return newAmmount / 100
      }
      return total / 100
    },
    changedBrand (e) {
      this.toggleShowHolderDocument(e.target.value)

      this.manualMode = true
      this.updateError('cardNumber')
      this.$store.dispatch('SET_BRAND_IMAGE', { payment: this.paymentKey, brandImage: null })
      if (e.target.value === 'cassol') {
        this.$store.dispatch('UPDATE_CARD_VALIDATION', { payment: this.paymentKey, cardNumber: null, cvv: null })
      } else {
        this.$store.dispatch('UPDATE_CARD_VALIDATION', { payment: this.paymentKey, cardNumber: 16, cvv: 3 })
      }
    },
    getBrandByZip (e) {
      if (e === '') {
        this.updateError('cardNumber')
      }
      this.fetchBrand(e)
    },
    fetchBrand (bin, delay = 500) {
      if ((bin && bin.length >= 13) && !this.binIsLoading && this.canSearchBin) {
        var params = {
          bin: bin.substring(0, 6)
        }
        this.binIsLoading = true
        this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: true })
        this.$store.dispatch('GET_BRAND', params)
          .then((response) => {
            this.$store.dispatch('UPDATE_CARD_VALIDATION', { payment: this.paymentKey, cardNumber: response.lenghts, cvv: response.cvv })
            this.$store.dispatch('UPDATE_FIELD_VALUE', { payment: this.paymentKey, field: 'brand', value: response.brand.toUpperCase() })
            this.$store.dispatch('SET_BRAND_IMAGE', { payment: this.paymentKey, brandImage: response.brandImage })
            this.updateError('cardNumber')
            this.updateError('brand')
            this.binIsLoading = false
            this.canSearchBin = true
            this.manualMode = false
            this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: false })
          })
          .catch(() => {
            this.$store.dispatch('UPDATE_CARD_VALIDATION', { payment: this.paymentKey, cardNumber: 16, cvv: 3 })
            this.$store.dispatch('UPDATE_FIELD_VALUE', { payment: this.paymentKey, field: 'brand', value: null })
            this.$store.dispatch('SET_BRAND_IMAGE', { payment: this.paymentKey, brandImage: null })
            this.updateError('cardNumber', true)
            this.updateError('brand', true)
            this.binIsLoading = false
            this.canSearchBin = true
            this.manualMode = false
            this.$store.dispatch('TOGGLE_LOCAL_LOADING', { isLoading: false })
          })
      } else {
        this.canSearchBin = true
      }
    },
    toggleShowHolderDocument (value) {
      if (this.payment.holderDocumentNumber) {
        if (value === 'sodexo' || value === 'vr') {
          this.showHolderDocumentNumber = true
          this.payment.holderDocumentNumber.validation.required = true
        } else {
          this.showHolderDocumentNumber = false
          this.payment.holderDocumentNumber.value = null
          this.payment.holderDocumentNumber.validation.required = false
        }
      }
    }
  }
}
</script>
