const whitelabel = {
  state: {
    whitelabel: {
      enabled: false,
      theme: 'mundipagg',
      url: process.env.VUE_APP_BASE_URL,
      domain: '.mundipagg.com'
    }
  },
  getters: {
    whitelabel (state) {
      return state.whitelabel
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state.whitelabel = value
    },
    updateWhitelabelTheme (state, value) {
      state.whitelabel.theme = value
    }
  },
  actions: {
    updateWhitelabel ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    loadEmeraldByTheme ({ commit }, theme) {
      const emerald = () => import('emerald-workbench/dist/emerald.' + theme + '.min.css')
      commit('updateWhitelabelTheme', theme)
      emerald()
    }
  }
}
export default whitelabel
