<template>
  <ul class="emd-progress-indicator" :class="[{ '_interactive': interactive }, enumVariant[variant]]">
    <li v-for="(item) in items" class="emd-progress-indicator__item" v-bind:key="item.key" :class="{'_active': item.selected }" @click="clicked(item.key)">
      <div class="emd-progress-indicator__label">
        {{ item.value }}
      </div>
      <span class="emd-progress-indicator__icon">
        <!-- <i v-if="index > selectedIndex" class="uil uil-circle" /> -->
        <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor" d="M8,0 C3.58064516,0 0,3.58064516 0,8 C0,12.4193548 3.58064516,16 8,16 C12.4193548,16 16,12.4193548 16,8 C16,3.58064516 12.4193548,0 8,0 Z">
          </path>
        </svg>
      </span>
    </li>
  </ul>
</template>

<script>
import { onClickStepper } from '@/utils/analitica/events'

export default {
  name: 'EmdProgressIndicator',
  props: {
    interactive: {
      type: Boolean,
      required: false,
      default: false
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'tertiary'].includes(value)
    },
    items: {
      type: Array,
      required: true,
      default: null,
      validator: (value) => {
        var valid = true
        value.forEach(obj => {
          const hasKey = Object.prototype.hasOwnProperty.call(obj, 'key')
          const hasValue = Object.prototype.hasOwnProperty.call(obj, 'value')
          const hasSelected = Object.prototype.hasOwnProperty.call(obj, 'selected')
          if (!hasKey || !hasValue || !hasSelected) {
            valid = false
          }
        })
        return valid
      }
    }
  },
  computed: {
    selectedIndex () {
      return this.items.findIndex(item => item.selected)
    }
  },
  data () {
    return {
      enumVariant: {
        primary: '_color_primary',
        secondary: '_color_secondary',
        tertiary: '_color_tertiary'
      }
    }
  },
  methods: {
    clicked (item) {
      onClickStepper.events.view.onClickStepper(this.items[this.selectedIndex].key, item)
      this.$emit('goTo', item)
    }
  }
}
</script>

<style>
.emd-progress-indicator__label, .emd-progress-indicator__icon {
  cursor: pointer;
}
</style>
