<template>
  <div class="el-chunk" v-if="shippable || showBilling">
      <div class="el-chunk">
        <p>
          <strong>{{ $t('section.address') }}</strong>
        </p>
      </div>
      <div class="eu-flex eu-column eu-row_sm">
        <div v-if="shippable" class="eu-flex-item" :class="{'eu-basis_6_sm': !(shippable && address.billingEqualShipping)}">
          <div class="el-chunk">
            <div class="emd-container _type_card">
              <div class="emd-container__content">
                <div class="emd-container__aside">
                  <review-icon icon="review/shipping" />
                </div>
                <div class="el-chunk">
                  <h2>
                    {{ $t('shipping') }}
                    <span v-if="address.billingEqualShipping">
                      {{ $t('andBilling') }}
                    </span>
                  </h2>
                </div>
                <p class="emd-text _size_small">{{ address.shipping.zip_code.value | cep }}</p>
                <p v-if="address.type === 'brazilian'" class="emd-text _size_small">{{ address.shipping.street.value }}, {{ address.shipping.number.value }}<span v-if="address.shipping.complement.value">, {{ address.shipping.complement.value }}</span> - {{ address.shipping.neighborhood.value }}</p>
                <template v-if="address.type === 'global'" >
                  <p class="emd-text _size_small">{{ address.shipping.line_1.value }}</p>
                  <p v-if="address.shipping.line_2.value" class="emd-text _size_small">{{ address.shipping.line_2.value }}</p>
                </template>
                <p class="emd-text _size_small">
                  {{ address.shipping.city.value }} - {{ address.shipping.state.value }} - {{ address.shipping.country.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="eu-flex-item" v-if="showBilling">
          <div class="el-chunk">
            <div class="emd-container _type_card">
              <div class="emd-container__content">
                <div class="emd-container__aside">
                  <review-icon icon="review/billing" />
                </div>
                <div class="el-chunk">
                  <h2>{{ $t('billing') }}</h2>
                </div>
                <p class="emd-text _size_small">{{ address.fields.zip_code.value | cep }}</p>
                <p v-if="address.type === 'brazilian'" class="emd-text _size_small">{{ address.fields.street.value }}, {{ address.fields.number.value }}<span v-if="address.fields.complement.value">, {{ address.fields.complement.value }}</span> - {{ address.fields.neighborhood.value }}</p>
                <template v-if="address.type === 'global'" >
                  <p class="emd-text _size_small">{{ address.fields.line_1.value }}</p>
                  <p v-if="address.fields.line_2.value" class="emd-text _size_small">{{ address.fields.line_2.value }}</p>
                </template>
                <p class="emd-text _size_small eu-capitalize">
                  {{ address.fields.city.value }} - {{ address.fields.state.value }} - {{ address.fields.country.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ReviewIcon from '@/components/project/atoms/Icon.vue'
import { isEmpty } from '@/utils'

export default {
  name: 'AddressReview',
  components: {
    ReviewIcon
  },
  computed: {
    address () {
      return this.$store.getters.address
    },
    shippable () {
      return this.$store.getters.tokenData.shippable
    },
    showBilling () {
      return !(this.shippable && this.address.billingEqualShipping) && !isEmpty(this.address.fields.zip_code.value)
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "section": {
      "customer": "Identificação",
      "address": "Endereço",
      "payment": "Pagamento"
    },
    "recipient": "Destinatário",
    "shipping": "Entrega",
    "billing": "Cobrança",
    "andBilling": "e cobrança"
  },
  "en-US": {
    "section": {
      "customer": "Identificação",
      "address": "Address",
      "payment": "Payment"
    },
    "recipient": "Recipient",
    "shipping": "Shipping",
    "billing": "Billing",
    "andBilling": "and billing"
  }
}
</i18n>
