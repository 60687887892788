import { Machine, interpret } from 'xstate'

const sectionMachine = Machine({
  id: 'section',
  initial: 'customer',
  states: {
    customer: {
      on: {
        NEXT: 'address'
      }
    },
    address: {
      on: {
        NEXT: 'payment',
        BACK: 'customer'
      }
    },
    payment: {
      on: {
        NEXT: 'review',
        BACK: 'address'
      }
    },
    review: {
      on: {
        BACK: 'payment'
      }
    }
  },
  on: {
    CUSTOMER: { target: 'customer' },
    ADDRESS: { target: 'address' },
    PAYMENT: { target: 'payment' },
    REVIEW: { target: 'review' }
  }
})

const checkoutMachine = Machine({
  id: 'checkout',
  initial: 'form',
  states: {
    form: {
      on: {
        SUCCESS: 'success'
      }
    },
    success: {}
  }
})

export const sectionService = interpret(sectionMachine)
export const checkoutService = interpret(checkoutMachine)
