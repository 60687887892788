var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "el-chunk" }, [
    _c("div", { staticClass: "el-chunk" }, [
      _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("section.customer")))])]),
    ]),
    _c("div", { staticClass: "eu-flex eu-column eu-row_sm" }, [
      _c(
        "div",
        {
          staticClass: "eu-flex-item",
          class: {
            "eu-basis_6_sm":
              _vm.address.shipping.recipient_name ||
              _vm.address.shipping.recipient_phone,
          },
        },
        [
          _c("div", { staticClass: "el-chunk" }, [
            _c("div", { staticClass: "emd-container _type_card" }, [
              _c("div", { staticClass: "emd-container__content" }, [
                _c(
                  "div",
                  { staticClass: "emd-container__aside" },
                  [_c("review-icon", { attrs: { icon: "review/user" } })],
                  1
                ),
                _c("div", { staticClass: "el-chunk" }, [
                  _c("h2", { staticClass: "eu-capitalize" }, [
                    _vm._v(
                      " " + _vm._s(_vm.customer.fields.name.value || "-") + " "
                    ),
                  ]),
                ]),
                _vm.customer.fields.email
                  ? _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        " " + _vm._s(_vm.customer.fields.email.value) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.customer.fields.document
                  ? _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("document")(
                              _vm.customer.fields.document.value
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.customer.fields.home_phone
                  ? _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("phone")(
                              _vm.customer.fields.home_phone.value
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.customer.fields.mobile_phone
                  ? _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("phone")(
                              _vm.customer.fields.mobile_phone.value
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.address.shipping.recipient_name ||
      _vm.address.shipping.recipient_phone
        ? _c("div", { staticClass: "eu-flex-item eu-basis_6_sm" }, [
            _c("div", { staticClass: "el-chunk" }, [
              _c("div", { staticClass: "emd-container _type_card" }, [
                _c("div", { staticClass: "emd-container__content" }, [
                  _c(
                    "div",
                    { staticClass: "emd-container__aside" },
                    [
                      _c("review-icon", {
                        attrs: { icon: "review/recipient" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "el-chunk" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("recipient")))]),
                  ]),
                  _vm.address.shipping.recipient_name
                    ? _c("p", { staticClass: "emd-text _size_small" }, [
                        _vm._v(
                          _vm._s(_vm.address.shipping.recipient_name.value)
                        ),
                      ])
                    : _vm._e(),
                  _vm.address.shipping.recipient_phone
                    ? _c("p", { staticClass: "emd-text _size_small" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("phone")(
                              _vm.address.shipping.recipient_phone
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }