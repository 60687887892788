<template>
  <component :is="dynamicIcon" :class="{ 'emeraldicons _color_theme': colorTheme === true }" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true
    },
    colorTheme: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    dynamicIcon () {
      return () => import(/* webpackChunkName: "icons/icon-[request]" */ '@/assets/' + this.icon + '.svg')
    }
  }
}
</script>

<style>
.emeraldicons._color_theme {
  color: #082558;
  color: var(--brand--le--primary-color--base);
  fill: #178176;
  fill: var(--brand--le--secondary-color--base);
}

._mode_dark .emeraldicons._color_theme {
  color: #00b7b4;
  color: var(--brand--de--primary-color--base);
  fill: #73b3ac;
  fill: var(--brand--de--secondary-color--base);
}

._mode_light .emeraldicons._color_theme {
  color: #082558;
  color: var(--brand--le--primary-color--base);
  fill: #178176;
  fill: var(--brand--le--secondary-color--base);
}
</style>
