var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "emd-container _type_card" }, [
    _c(
      "div",
      { staticClass: "emd-container__content" },
      [
        _c("div", { staticClass: "el-chunk" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("address")))]),
        ]),
        _c("p", { staticClass: "emd-text _size_small" }, [
          _vm._v(_vm._s(_vm._f("cep")(_vm.address.fields.zip_code.value))),
        ]),
        _vm.address.type === "brazilian"
          ? _c("p", { staticClass: "emd-text _size_small" }, [
              _vm._v(
                _vm._s(_vm.address.fields.street.value) +
                  ", " +
                  _vm._s(_vm.address.fields.number.value)
              ),
              _vm.address.fields.complement.value
                ? _c("span", [
                    _vm._v(", " + _vm._s(_vm.address.fields.complement.value)),
                  ])
                : _vm._e(),
              _vm._v(" - " + _vm._s(_vm.address.fields.neighborhood.value)),
            ])
          : _vm._e(),
        _vm.address.type === "global"
          ? [
              _c("p", { staticClass: "emd-text _size_small" }, [
                _vm._v(_vm._s(_vm.address.fields.line_1.value)),
              ]),
              _vm.address.fields.line_2.value
                ? _c("p", { staticClass: "emd-text _size_small" }, [
                    _vm._v(_vm._s(_vm.address.fields.line_2.value)),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _c("p", { staticClass: "emd-text _size_small" }, [
          _vm._v(
            " " +
              _vm._s(_vm.address.fields.city.value) +
              " - " +
              _vm._s(_vm.address.fields.state.value) +
              " - " +
              _vm._s(_vm.address.fields.country.value) +
              " "
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }