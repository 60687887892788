var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "el-chunk" }, [
    _c("div", { staticClass: "el-chunk" }, [
      _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("section.payment")))])]),
    ]),
    _c(
      "div",
      { staticClass: "eu-flex eu-column eu-row_sm" },
      _vm._l(_vm.paymentMethods, function (method, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "eu-flex-item",
            class: { "eu-basis_6_sm": _vm.isMultiPayment },
          },
          [
            _c("div", { staticClass: "el-chunk" }, [
              _c("div", { staticClass: "emd-container _type_card" }, [
                _c(
                  "div",
                  { staticClass: "emd-container__content" },
                  [
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", [
                        _vm._v(
                          " " + _vm._s(_vm.$t(`paymentMethods.${method}`)) + " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "emd-container__aside" },
                      [
                        method === "apple_pay"
                          ? [
                              _c("review-icon", {
                                attrs: {
                                  icon: `payment_methods/apple_pay`,
                                  colorTheme: false,
                                },
                              }),
                            ]
                          : _vm._e(),
                        method === "google_pay"
                          ? [
                              _c("review-icon", {
                                attrs: { icon: `payment_methods/google_pay` },
                              }),
                            ]
                          : _vm._e(),
                        method === "click_to_pay"
                          ? [
                              _c("review-icon", {
                                attrs: { icon: `payment_methods/click_to_pay` },
                              }),
                            ]
                          : _vm._e(),
                        method === "boleto"
                          ? [
                              _c("review-icon", {
                                attrs: { icon: "review/boleto" },
                              }),
                            ]
                          : _vm._e(),
                        method === "pix"
                          ? [
                              _c("review-icon", {
                                attrs: { icon: "review/pix" },
                              }),
                            ]
                          : _vm._e(),
                        method === "bank_transfer"
                          ? [
                              _c("img", {
                                attrs: {
                                  src: require(`@/assets/banks/${
                                    _vm.banksImage[
                                      _vm.payments[`payment_${index + 1}`].bank
                                        .value
                                    ]
                                  }.png`),
                                  alt: _vm.getBank(
                                    _vm.payments[`payment_${index + 1}`].bank
                                      .value
                                  ),
                                },
                              }),
                            ]
                          : _vm._e(),
                        method === "credit_card" ||
                        method === "debit_card" ||
                        method === "voucher"
                          ? [
                              _c("img", {
                                attrs: {
                                  src: _vm.payments[`payment_${index + 1}`]
                                    .brandImage,
                                  alt: _vm.payments[`payment_${index + 1}`]
                                    .brand,
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    method === "credit_card" ||
                    method === "debit_card" ||
                    method === "voucher"
                      ? [
                          _c(
                            "p",
                            {
                              staticClass: "emd-text _size_small eu-capitalize",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.payments[`payment_${index + 1}`].name
                                    .value
                                )
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(
                              "•••• •••• •••• " +
                                _vm._s(
                                  _vm.payments[
                                    `payment_${index + 1}`
                                  ].cardNumber.value.substr(-4)
                                )
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    method === "boleto"
                      ? [
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("due_date")) +
                                ": " +
                                _vm._s(_vm.payments.boleto.due_at) +
                                " "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    method === "bank_transfer"
                      ? [
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getBank(
                                  _vm.payments[`payment_${index + 1}`].bank
                                    .value
                                )
                              )
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "emd-text _size_small" },
                      [
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.total / 100,
                                  _vm.currency
                                )
                              ) +
                              " "
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
    _vm.payments.selectedPaymentMethod === "pix"
      ? _c("p", { staticClass: "emd-text _size_tiny" }, [
          _vm._v(" " + _vm._s(_vm.$t("pixMessage")) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }