<template>
      <div class="emd-container _type_card">
        <div class="emd-container__content">
          <div class="el-chunk">
            <h2>{{ $t('address') }}</h2>
          </div>
          <p class="emd-text _size_small">{{ address.fields.zip_code.value | cep }}</p>
          <p v-if="address.type === 'brazilian'" class="emd-text _size_small">{{ address.fields.street.value }}, {{ address.fields.number.value }}<span v-if="address.fields.complement.value">, {{ address.fields.complement.value }}</span> - {{ address.fields.neighborhood.value }}</p>
          <template v-if="address.type === 'global'" >
            <p class="emd-text _size_small">{{ address.fields.line_1.value }}</p>
            <p v-if="address.fields.line_2.value" class="emd-text _size_small">{{ address.fields.line_2.value }}</p>
          </template>
          <p class="emd-text _size_small">
            {{ address.fields.city.value }} - {{ address.fields.state.value }} - {{ address.fields.country.value }}
          </p>
        </div>
      </div>
</template>

<script>
export default {
  name: 'BillingAdressCard',
  computed: {
    address: {
      get () {
        return this.$store.getters.address
      }
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "address": "Endereço"
  },
  "en-US": {
    "address": "Address"
  }
}
</i18n>
