var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("apple-pay-button", {
    attrs: { buttonstyle: "black", type: "buy", locale: "pt-BR" },
    on: { click: _vm.createApplePaySession },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }