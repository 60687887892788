<template>
      <div class="el-chunk">
      <div class="el-chunk">
        <p>
          <strong>{{ $t('section.payment') }}</strong>
        </p>
      </div>
      <div class="eu-flex eu-column eu-row_sm">
        <div v-for="(method, index) in paymentMethods" :key="index" class="eu-flex-item" :class="{ 'eu-basis_6_sm': isMultiPayment }">
          <div class="el-chunk">
            <div class="emd-container _type_card">
              <div class="emd-container__content">
                <div class="el-chunk">
                  <h2>
                    {{ $t(`paymentMethods.${method}`) }}
                  </h2>
                </div>
                <div class="emd-container__aside">
                  <template v-if="method === 'apple_pay'">
                    <review-icon :icon="`payment_methods/apple_pay`" :colorTheme="false"/>
                  </template>
                  <template v-if="method === 'google_pay'">
                    <review-icon :icon="`payment_methods/google_pay`" />
                  </template>
                  <template v-if="method === 'click_to_pay'">
                    <review-icon :icon="`payment_methods/click_to_pay`" />
                  </template>
                  <template v-if="method === 'boleto'">
                    <review-icon icon="review/boleto" />
                  </template>
                  <template v-if="method === 'pix'">
                    <review-icon icon="review/pix" />
                  </template>
                  <template v-if="method === 'bank_transfer'">
                    <img v-bind:src="require(`@/assets/banks/${banksImage[payments[`payment_${index + 1}`].bank.value]}.png`)"  :alt="getBank(payments[`payment_${index + 1}`].bank.value)">
                  </template>
                  <template v-if="method === 'credit_card' || method === 'debit_card' || method === 'voucher'">
                    <img v-bind:src="payments[`payment_${index + 1}`].brandImage" :alt="payments[`payment_${index + 1}`].brand">
                  </template>
                </div>
                <template v-if="method === 'credit_card' || method === 'debit_card' || method === 'voucher'">
                  <p class="emd-text _size_small eu-capitalize">{{ payments[`payment_${index + 1}`].name.value }}</p>
                  <p class="emd-text _size_small">•••• •••• •••• {{ payments[`payment_${index + 1}`].cardNumber.value.substr(-4) }}</p>
                </template>
                <template v-if="method === 'boleto'">
                  <p class="emd-text _size_small">
                    {{ $t('due_date') }}:  {{ payments.boleto.due_at }}
                  </p>
                </template>
                <template v-if="method === 'bank_transfer'">
                  <p class="emd-text _size_small">{{ getBank(payments[`payment_${index + 1}`].bank.value) }}</p>
                </template>
                <p class="emd-text _size_small">
                  <template>
                    {{ total / 100 | currency(currency) }}
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p  v-if="payments.selectedPaymentMethod === 'pix'" class="emd-text _size_tiny">
        {{$t('pixMessage')}}
      </p>
    </div>
</template>

<script>
import ReviewIcon from '@/components/project/atoms/Icon.vue'
const get = require('lodash.get')

export default {
  name: 'PaymentReview',
  components: {
    ReviewIcon
  },
  computed: {
    currency () {
      return this.$store.getters.currency
    },
    payments () {
      return this.$store.getters.payment
    },
    banks () {
      return this.$store.getters.payment.banks
    },
    banksImage () {
      return {
        237: 'bradesco',
        '001': 'banco_do_brasil',
        341: 'itau'
      }
    },
    tokenData () {
      return this.$store.getters.tokenData
    },
    paymentMethods () {
      if (this.$store.getters.payment.selectedPaymentMethod === '2_credit_cards') return ['credit_card', 'credit_card']
      if (this.$store.getters.payment.selectedPaymentMethod === 'credit_card_and_boleto') return ['credit_card', 'boleto']
      return [this.$store.getters.payment.selectedPaymentMethod]
    },
    isMultiPayment () {
      return this.$store.getters.payment.selectedPaymentMethod === '2_credit_cards' || this.$store.getters.payment.selectedPaymentMethod === 'credit_card_and_boleto'
    },
    discount () {
      return this.discountPercentage(get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount_percentage`), this.subtotal) ||
        this.discountFlat || 0
    },
    subtotal () {
      let subtotal = 0
      if (this.tokenData.cart_settings.items) {
        this.tokenData.cart_settings.items.forEach((item) => {
          subtotal += item.amount * item.default_quantity
        })
      } else {
        this.tokenData.cart_settings.recurrences[0].plan.items.forEach((item) => {
          subtotal += item.quantity * item.princing_scheme.price
        })
      }
      return subtotal
    },
    total () {
      const total = this.subtotal + this.tokenData.cart_settings.shipping_total_cost - this.discount
      if (this.payments.selectedPaymentMethod === 'credit_card') {
        return this.installments[this.$store.getters.payment.payment_1.installment.value - 1]?.total || total
      }
      return total
    },
    installments () {
      return this.$store.getters.payment.installments
    },
    discountFlat () {
      return get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount`)
    }
  },
  methods: {
    discountPercentage (discountPercentage, amount) {
      if (discountPercentage && amount) {
        return discountPercentage * amount / 100
      }
    },
    getBank (bank) {
      return this.banks.find(item => item.key === bank).value
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "due_date": "Vencimento",
    "paymentMethods": {
      "credit_card": "Cartão de crédito",
      "debit_card": "Cartão de débito",
      "boleto": "Boleto",
      "bank_transfer": "Débito online",
      "voucher": "Voucher",
      "pix": "Pix",
      "apple_pay": "Apple Pay",
      "google_pay": "Google Pay",
      "click_to_pay": "Click to Pay"
    },
    "section": {
      "customer": "Identificação",
      "address": "Endereço",
      "payment": "Pagamento"
    },
    "pixMessage": "*O código Pix para o pagamento será gerado e exibido na tela após a conclusão do pedido."
  },
  "en-US": {
    "due_date": "Due date",
    "paymentMethods": {
      "credit_card": "Credit card",
      "debit_card": "Debit card",
      "boleto": "Boleto",
      "bank_transfer": "Bank transfer",
      "voucher": "Voucher",
      "pix": "Pix",
      "apple_pay": "Apple Pay",
      "google_pay": "Google Pay",
      "click_to_pay": "Click to Pay"
    },
     "section": {
      "customer": "Identificação",
      "address": "Address",
      "payment": "Payment"
    },
    "pixMessage": "*The Pix code for the payment will be generated and displayed on the screen after the order is completed."
  }
}
</i18n>
