<template>
  <google-pay-button
    :environment="environment"
    button-type="short"
    button-color="default"
    :existing-payment-method-required="existingPaymentMethodRequired"
    :paymentRequest.prop="{
      apiVersion: paymentRequest.apiVersion,
      apiVersionMinor: paymentRequest.apiVersionMinor,
      allowedPaymentMethods: paymentRequest.allowedPaymentMethods,
      merchantInfo: paymentRequest.merchantInfo,
      transactionInfo: paymentRequest.transactionInfo,
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
    }"
    :onPaymentAuthorized.prop="onPaymentDataAuthorized"
  ></google-pay-button>
</template>

<script>
import '@google-pay/button-element'
export default {
  name: 'GooglePay',
  props: {
    setPaymentData: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      environment: '',
      existingPaymentMethodRequired: null,
      paymentRequest: null,
      googlePayData: {}
    }
  },
  computed: {
    payments () {
      return this.$store.getters.payment
    }
  },
  showGooglePayOption (googlePayData) {
    // eslint-disable-next-line
    const client = new google.payments.api.PaymentsClient({ environment: googlePayData.environment })
    return client.isReadyToPay(this.methods.getGooglePayParam(googlePayData)).then((response) => {
      return response.result
    })
  },
  created () {
    this.paymentRequest = this.getGooglePayParam(this.payments.googlePayData)
  },
  methods: {
    getGooglePayParam (googlePayData) {
      this.googlePayData = googlePayData
      const paymentRequest = {
        apiVersion: googlePayData.api_version,
        apiVersionMinor: googlePayData.api_version_minor,
        merchantInfo: {
          merchantId: googlePayData.merchant_info.merchant_id
        },
        transactionInfo: {
          totalPriceStatus: googlePayData.transaction_info.total_price_status,
          totalPriceLabel: googlePayData.transaction_info.total_price_label,
          totalPrice: googlePayData.transaction_info.total_price.toString(),
          currencyCode: googlePayData.transaction_info.currency_code,
          countryCode: googlePayData.transaction_info.country_code
        }
      }

      paymentRequest.allowedPaymentMethods = googlePayData.allowed_payment_methods.map(item => {
        return {
          type: item.type,
          parameters: {
            allowedAuthMethods: item.parameters.allowed_auth_methods,
            allowedCardNetworks: item.parameters.allowed_card_networks
          },
          tokenizationSpecification: {
            type: item.tokenization_specification.type,
            parameters: {
              gateway: item.tokenization_specification.parameters.gateway,
              gatewayMerchantId: item.tokenization_specification.parameters.gateway_merchant_id
            }
          }
        }
      })

      this.environment = googlePayData.environment
      this.existingPaymentMethodRequired = googlePayData.existing_payment_method_required

      return paymentRequest
    },
    async onPaymentDataAuthorized (response) {
      const responseStatus = await this.setPaymentData(null, {
        googlePayDetails: {
          ...JSON.parse(response.paymentMethodData.tokenizationData.token),
          merchant_identifier: this.googlePayData.merchant_info.gateway_merchant_id
        }
      })

      return new Promise(function (resolve) {
        resolve({ transactionState: responseStatus })
      })
    }
  }
}
</script>
