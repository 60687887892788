<template>
  <div v-if="!isIE" id="app">
    <global-loader v-if="isLoading" />
    <template v-if="!isLoading">
      <router-view/>
    </template>
  </div>
</template>
<script>
import GlobalLoader from '@/components/project/globals/GlobalLoader.vue'

export default {
  name: 'App',
  components: {
    GlobalLoader
  },
  created () {
    var ua = window.navigator.userAgent
    var trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:')
      var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
      if (version === 11) {
        this.$store.dispatch('SET_IE_11', true)
      }
    }
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  computed: {
    appVersion () {
      return this.$store.state.appVersion
    },
    isLoading () {
      return this.$store.getters.globalLoading
    },
    isIE () {
      return window.isIE
    }
  }
}
</script>

<style>
.emd-form-group {
  padding: 0 0 16px !important;
}
.emd-form-group .emd-input-link {
  margin-top: 4px !important;
}
.emd-label {
  margin-bottom: 2px !important;
  text-transform: none !important;
}
</style>
