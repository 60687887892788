<template>
  <div>
    <div class="el-chunk">
      <h2 class="emd-text _size_subpage"><b>{{ $t('title') }}</b></h2>
    </div>
    <div class="el-chunk">
      <p class="emd-text _color_subtle" style="white-space: pre-line;">
        {{ $t('text') }}
      </p>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Não foi possível finalizar o pagamento.",
    "text": "Algo inesperado aconteceu. \nVocê pode voltar ou atualizar essa página e tentar outra vez."
  },
  "en-US": {
    "title": "Unable to complete payment.",
    "text": "Something unexpected happened.\nYou can go back or refresh this page and try again."
  }
}
</i18n>

<script>
export default {
  name: 'Error404'
}
</script>
