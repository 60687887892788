<template>
  <footer>
    <div class="el-chunk">
      <p class="emd-text _align_center">
        <emd-footer-icon :theme="theme" :url="url">
          <span class="emd-text _color_default" style="vertical-align: 2px">
            {{ $t(theme) }}
          </span>
        </emd-footer-icon>
        <span class="emd-text _color_nonessential eu-mx_2"> | </span>
        <span>
          <span class="emd-text _color_secondary">
            <i class="uil uil-shield-check" />
          </span>
          <span style="vertical-align: -1px">
          {{$t('safeZone')}}
          </span>
        </span>
      </p>
    </div>
    <div class="el-chunk emd-text _align_center">
      <emd-language-select color="subtle"/>
    </div>
  </footer>
</template>

<i18n>
{
  "pt-BR": {
    "safeZone": "Ambiente Seguro",
    "mundipagg": "Mundipagg",
    "pagarme": "Pagar.me"
  },
  "en-US": {
    "safeZone": "Secure environment",
    "mundipagg": "Mundipagg",
    "pagarme": "Pagar.me"
  }
}
</i18n>

<script>
import EmdLanguageSelect from '@/components/emerald/atoms/EmdLanguageSelect.vue'
import EmdFooterIcon from '@/components/emerald/atoms/EmdFooterIcon.vue'

export default {
  name: 'EmdFooterExternal',
  components: {
    EmdLanguageSelect,
    EmdFooterIcon
  },
  computed: {
    theme () {
      return this.$store.getters.whitelabel.theme
    },
    url () {
      return process.env.VUE_APP_URL_SITE
    }
  }
}
</script>
