<template>
  <a class="emd-mundi-icon" :href="url" target="_blank" rel="noopener noreferrer">
    <span v-if="theme === 'mundipagg'">
      <svg version="1.1" id="emd-mundi-icon" width="16px" height="16px" viewBox="0 0 24 27" xml:space="preserve">
        <path d="M22.2823551,0.296429942 L10.6690403,11.8872553 C9.77698656,12.7775816 9.77698656,14.2224184 10.6690403,15.1127447 L22.2823551,26.7035701 C22.4869356,26.9087908 22.739286,27 22.9874826,27 C23.5046453,27 24,26.5988868 24,25.9935893 L24,1.00744722 C24,0.401113244 23.5046453,0 22.9874826,0 C22.739286,0 22.4869356,0.0912092131 22.2823551,0.296429942"
          id="right" fill="#B9C3CC"></path>
        <path d="M10.2281241,16.5168669 C8.58391576,14.8543644 8.58391576,12.1460608 10.2281241,10.4835584 L11,9.70309429 L1.69648681,0.297103065 C1.07017053,-0.337218326 0,0.111574423 0,1.00708698 L0,25.9943747 C0,26.8888508 1.07017053,27.3366071 1.69648681,26.7033222 L11,17.2973309 L10.2281241,16.5168669 Z"
          id="left" fill="#B9C3CC"></path>
      </svg>
    </span>
    <span v-if="theme === 'pagarme'" class="emd-text _color_default">
      <span class="emd-pagarme-icon-outline _active">
        <span>
          <emd-pagarme-icon />
        </span>
      </span>
    </span>
    <slot></slot>
  </a>
</template>

<script>
import { EmdPagarmeIcon } from 'emerald-vue/src/components'

export default {
  name: 'EmdFooterIcon',
  components: {
    EmdPagarmeIcon
  },
  props: {
    theme: {
      type: String,
      required: false,
      default: 'mundipagg'
    },
    url: {
      type: String,
      required: false
    }
  }
}
</script>
