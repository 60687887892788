var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "emd-logo-wrapper" },
    [
      _vm.account
        ? [
            _vm.account.website
              ? [
                  _vm.account.logo
                    ? _c(
                        "a",
                        {
                          staticClass: "emd-logo-link",
                          attrs: {
                            href: _vm.account.website,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "emd-logo-link__image",
                            attrs: {
                              src: _vm.account.logo,
                              title: _vm.account.name,
                              alt: `Logo ${_vm.account.name}`,
                            },
                          }),
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "emd-text _size_subpage emd-link",
                          attrs: {
                            href: _vm.account.website,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                        [_c("default-logo")],
                        1
                      ),
                ]
              : [
                  _vm.account.logo
                    ? _c("img", {
                        staticClass: "emd-logo-link__image",
                        attrs: {
                          src: _vm.account.logo,
                          title: _vm.account.name ? _vm.account.name : "",
                          alt: _vm.account.name
                            ? `Logo ${_vm.account.name}`
                            : "",
                        },
                      })
                    : _c(
                        "h2",
                        { staticClass: "emd-text _size_subpage" },
                        [_c("default-logo")],
                        1
                      ),
                ],
          ]
        : _c(
            "h2",
            { staticClass: "emd-text _size_subpage" },
            [_c("default-logo")],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }