var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "el-chunk" }, [
      _c("h2", { staticClass: "emd-text _size_subpage" }, [
        _c("b", [_vm._v(_vm._s(_vm.$t("title")))]),
      ]),
    ]),
    _c("div", { staticClass: "el-chunk" }, [
      _c(
        "p",
        {
          staticClass: "emd-text _color_subtle",
          staticStyle: { "white-space": "pre-line" },
        },
        [_vm._v(" " + _vm._s(_vm.$t("text")) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }