import { render, staticRenderFns } from "./Error400.vue?vue&type=template&id=3b8eb7de"
import script from "./Error400.vue?vue&type=script&lang=js"
export * from "./Error400.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Error400.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/checkout-webapp-v2/checkout-webapp-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b8eb7de')) {
      api.createRecord('3b8eb7de', component.options)
    } else {
      api.reload('3b8eb7de', component.options)
    }
    module.hot.accept("./Error400.vue?vue&type=template&id=3b8eb7de", function () {
      api.rerender('3b8eb7de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/project/globals/Error400.vue"
export default component.exports