<template>
  <div class="emd-detail-box" :class="{ '_open': isOpen }" @click="toggleDetailBox">
    <div class="emd-detail-box__main">
      <span class="emd-detail-box__arrow">
        <i class="uil uil-angle-up" />
      </span>
      <div class="emd-detail-box__content">
        <div class="emd-detail-box__items">
          <div class="el-chunk">
            <h2 class="emd-text _size_section _color_default">{{ $t('order') }}</h2>
          </div>
          <table v-for="(item, i) in items" v-bind:key="i" class="eu-my_1">
            <tr>
              <td class="item-name">
                <span class="emd-detail-box__footer">
                  {{ getName(item) }}
                </span>
                <span class="emd-badge _type_rounded">x{{ getQuantity(item) }}</span>
              </td>
              <td class="item-name">{{ itemTotal(item) | currency(currency) }}</td>
            </tr>
            <tr>
              <span>
                {{ getDescription(item) }}
              </span>
            </tr>
          </table>
          <hr class="emd-divider eu-my_3">
        </div>
        <template v-if="shouldShowSubtotal">
          <div class="emd-detail-box__items">
            <table>
              <tr>
                <td>{{ $t('subtotal') }}</td>
                <td>{{ subtotal / 100 | currency(currency) }}</td>
              </tr>
              <tr v-if="hasTaxes">
                <td>{{ $t('taxes') }}</td>
                <td v-if="taxes !== 0">{{ taxes / 100 | currency(currency) }}</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="hasDiscount">
                <td>{{ $t('discounts') }}</td>
                <td v-if="taxes !== 0"> - {{ discount / 100 | currency(currency) }}</td>
                <td v-else>-</td>
              </tr>
              <tr v-if="tokenData.cart_settings.shipping_total_cost">
                <td>{{ $t('shipping') }}</td>
                <td>{{ tokenData.cart_settings.shipping_total_cost / 100 | currency(currency) }}</td>
              </tr>
            </table>
          </div>
          <hr class="emd-divider eu-my_3">
        </template>
      </div>
      <footer class="emd-detail-box__footer">
        <table>
          <tr>
            <td>{{ $t('total') }}</td>
            <td>{{ total / 100 | currency(currency) }}</td>
          </tr>
        </table>
      </footer>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "order": "Pedido",
    "subtotal": "Subtotal",
    "taxes": "Tarifas",
    "discounts": "Descontos",
    "shipping": "Frete",
    "total": "Total"
  },
  "en-US": {
    "order": "Order",
    "subtotal": "Subtotal",
    "taxes": "Taxes",
    "discounts": "Discounts",
    "shipping": "Shipping amount",
    "total": "Total"
  }
}
</i18n>

<script>
const get = require('lodash.get')

export default {
  name: 'OrderDetail',
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    items () {
      if (this.isSubscription) {
        return this.$store.getters.tokenData.cart_settings.recurrences[0].plan.items
      } else {
        return this.$store.getters.tokenData.cart_settings.items
      }
    },
    shouldShowSubtotal () {
      return this.hasInstallments || this.hasDiscount
    },
    hasDiscount () {
      return this.discount > 0
    },
    hasShipping () {
      return this.tokenData.shippable && this.tokenData.shipping.amount
    },
    hasInstallments () {
      return get(this.tokenData, 'payment_settings.credit_card_settings.installments.length') > 1
    },
    installments () {
      return this.$store.getters.payment.installments
    },
    hasTaxes () {
      return this.taxes > 0
    },
    tokenData () {
      return this.$store.getters.tokenData
    },
    payments () {
      return this.$store.getters.payment
    },
    shippable () {
      return this.$store.getters.tokenData.shippable
    },
    currency () {
      return this.$store.getters.currency
    },
    discount () {
      return this.discountPercentage(get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount_percentage`), this.subtotal) ||
        this.discountFlat || 0
    },
    discountFlat () {
      return get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount`)
    },
    subtotal () {
      let subtotal = 0
      if (this.tokenData.cart_settings.items) {
        this.tokenData.cart_settings.items.forEach((item) => {
          subtotal += item.amount * item.default_quantity
        })
      } else {
        this.tokenData.cart_settings.recurrences[0].plan.items.forEach((item) => {
          subtotal += item.quantity * item.princing_scheme.price
        })
      }
      return subtotal
    },
    taxes () {
      return Math.trunc(this.total) - this.subtotal - this.tokenData.cart_settings.shipping_total_cost
    },
    total () {
      const total = this.subtotal + this.tokenData.cart_settings.shipping_total_cost - this.discount
      if (this.payments.selectedPaymentMethod === 'credit_card') {
        return this.installments[this.$store.getters.payment.payment_1.installment.value - 1]?.total || total
      }
      return total
    },
    isSubscription () {
      return this.$store.getters.tokenData.payment_link_type === 'subscription'
    }
  },
  methods: {
    discountPercentage (discountPercentage, amount) {
      if (discountPercentage && amount) {
        return discountPercentage * amount / 100
      }
    },
    getName (item) {
      return item.name
    },
    getDescription (item) {
      return item?.description
    },
    getQuantity (item) {
      if (this.isSubscription) {
        return item.quantity
      } else {
        return item.default_quantity
      }
    },
    calculateAmount (paymentKey) {
      const percentage = this.payments[paymentKey].percentage
      if (this.payments[paymentKey].installment && this.payments[paymentKey].installment.value) {
        const installment = this.payments.installments.find(item => item.number === this.payments[paymentKey].installment.value)
        if (percentage !== 0) {
          return ((percentage / 100) * (installment.total))
        }
        return installment.total
      }
      if (percentage !== 0) {
        return ((percentage / 100) * (this.payments.amount))
      }
      return 0
    },
    toggleDetailBox () {
      if (!window.matchMedia('(min-width: 768px)').matches) {
        if (this.isOpen) {
          this.isOpen = false
        } else {
          this.isOpen = true
        }
      }
    },
    itemTotal (item) {
      if (this.isSubscription) {
        return item.princing_scheme.price * item.quantity / 100
      } else {
        return item.amount * item.default_quantity / 100
      }
    }
  }
}
</script>

<style scoped>
.item-name {
  padding-bottom: 0px !important;
}
</style>
