var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "el-column" }, [
    _c(
      "div",
      { staticClass: "el-column__navbar" },
      [_c("logo-wrapper", { attrs: { account: _vm.account } })],
      1
    ),
    _c("div", { staticClass: "el-section" }, [_c("router-view")], 1),
    _c("div", { staticClass: "el-section" }, [_c("emd-footer-external")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }