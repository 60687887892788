<template>
  <div>
    <div class="el-chunk">
      <h2 class="emd-text _size_subpage"><b>{{ $t('title') }}</b></h2>
    </div>
    <div class="el-chunk">
      <p class="emd-text _color_subtle" style="white-space: pre-line;">
        {{ $t('text') }}
      </p>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Não encontramos seu link de pagamento.",
    "text": "Esse link de pagamento não existe ou expirou.\nPeça para criarem um novo link."
  },
  "en-US": {
    "title": "We didn't find your payment link.",
    "text": "This payment link does not exist or has expired.\nAsk them to create a new link."
  }
}
</i18n>

<script>
export default {
  name: 'Error404'
}
</script>
