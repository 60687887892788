import { analiticaInstance } from './analitica'

export const onVisitPage = analiticaInstance.eventFactory('checkout2 - visit', {
  view: {
    onVisitPage: (page) => ({
      label: 'checkout2 - visit',
      metadata: { page: page }
    })
  }
})

export const onClickNext = analiticaInstance.eventFactory('checkout2 - nav next', {
  view: {
    onClickNext: (page) => ({
      label: 'checkout2 - nav next',
      metadata: {
        page: page
      }
    })
  }
})

export const onClickBack = analiticaInstance.eventFactory('checkout2 - nav back', {
  view: {
    onClickBack: (page) => ({
      label: 'checkout2 - nav back',
      metadata: {
        page: page
      }
    })
  }
})

export const onClickStepper = analiticaInstance.eventFactory('checkout2 - stepper', {
  view: {
    onClickStepper: (page, step) => ({
      label: 'checkout2 - stepper',
      metadata: {
        page: page,
        clicked_step: step
      }
    })
  }
})

export const onSelectPortuguese = analiticaInstance.eventFactory('checkout2 - select language pt', {
  view: {
    onSelectPortuguese: (page) => ({
      label: 'checkout2 - select language pt',
      metadata: {
        page: page
      }
    })
  }
})

export const onSelectEnglish = analiticaInstance.eventFactory('checkout2 - select language en', {
  view: {
    onSelectEnglish: (page) => ({
      label: 'checkout2 - select language en',
      metadata: {
        page: page
      }
    })
  }
})

export const onCaptchaError = analiticaInstance.eventFactory('checkout2 - review error captcha', {
  view: {
    onCaptchaError: () => ({
      label: 'checkout2 - review error captcha'
    })
  }
})

export const onPay = analiticaInstance.eventFactory('checkout2 - nav pay', {
  view: {
    onPay: (paymentMethod, installments) => ({
      label: 'checkout2 - nav pay',
      metadata: {
        payment_method: paymentMethod,
        installments: installments
      }
    })
  }
})

export const onPixCopyCode = analiticaInstance.eventFactory('checkout2 - success pix copy code', {
  view: {
    onPixCopyCode: () => ({
      label: 'checkout2 - success pix copy code'
    })
  }
})
