import Vue from 'vue'
import Vuex from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import CheckoutCore from 'checkout-js'
import UtilitiesCore from 'utilities-js'
import { version } from '@/../package.json'
import { sectionService } from '@/stateMachine'
import customer from './modules/customer'
import address from './modules/address'
import payment from './modules/payment'
import theme from './modules/theme'
import whitelabel from './modules/whitelabel'

import { enableFingerPrint } from '@/utils/fingerPrint'

Vue.use(Vuex)

const coreParams = {
  environment: process.env.VUE_APP_CORE_ENV,
  version: 2,
  mark1ApiUrl: process.env.VUE_APP_MARK1_API_URL,
  checkoutBffUrl: process.env.VUE_APP_CHECKOUTBFF_API_URL,
  utilitiesApiUrl: process.env.VUE_APP_UTILITIES_API_URL
}

export const CheckoutInstance = new CheckoutCore(coreParams)
export const UtilitiesInstance = new UtilitiesCore(coreParams)

export const catchError = function (error, context) {
  context.commit('toggleGlobalError', { status: true, code: error.status })
  return Promise.reject(error)
}

export default new Vuex.Store({
  strict: !['production'].includes(process.env.NODE_ENV),
  modules: {
    theme,
    customer,
    address,
    payment,
    whitelabel
  },
  state: {
    globalLoading: true,
    localLoading: false,
    globalError: false,
    globalErrorCode: '',
    isLightbox: false,
    appVersion: version,
    tokenData: null,
    currency: 'BRL',
    currentSection: null,
    responseData: null,
    isIE11: false,
    cookie_ck: null,
    totalCardFail: 0,
    failedCookie: [],
    sessionId: null,
    status: null,
    loadingSubmit: false,
    antifraudeId: null
  },
  mutations: {
    setLoadingSubmit (state, status) {
      state.loadingSubmit = status
    },
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleLocalLoading (state, status) {
      state.localLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
    },
    updateLightbox (state, isLightbox) {
      state.isLightbox = isLightbox
    },
    setIE11 (state, isIE11) {
      state.isIE11 = isIE11
    },
    setCurrentState (state, action) {
      sectionService.send(action)
    },
    setTokenData (state, tokenData) {
      state.tokenData = Object.assign({}, tokenData)
      state.tokenData.shippable = false
    },
    setResponseData (state, responseData) {
      state.responseData = Object.assign({}, responseData)
    },
    setCurrency (state, currency) {
      state.currency = currency
    },
    setTotalCardFail (state, value) {
      state.totalCardFail = value
    },
    setFailedCookie (state, value) {
      state.failedCookie = value
    },
    setSessionId (state, sessionId) {
      state.sessionId = sessionId
    },
    setStatus (state, value) {
      state.status = value
    },
    setAntifraudeId (state, value) {
      state.antifraudeId = value
    }
  },
  getters: {
    loadingSubmit (state) {
      return state.loadingSubmit
    },
    appVersion (state) {
      return state.appVersion
    },
    tokenData (state) {
      return state.tokenData
    },
    localLoading (state) {
      return state.localLoading
    },
    globalError (state) {
      return state.globalError
    },
    globalErrorCode (state) {
      return state.globalErrorCode
    },
    globalLoading (state) {
      return state.globalLoading
    },
    currentSection (state) {
      return state.currentSection
    },
    currency (state) {
      return state.currency
    },
    isLightbox (state) {
      return state.isLightbox
    },
    responseData (state) {
      return state.responseData
    },
    customer (state) {
      return state.customer
    },
    address (state) {
      return state.address
    },
    payment (state) {
      return state.payment
    },
    totalCardFail (state) {
      return state.totalCardFail
    },
    sessionId (state) {
      return state.sessionId
    },
    status (state) {
      return state.status
    },
    antifraudeId (state) {
      return state.antifraudeId
    }
  },
  actions: {
    'SET_LOADING_SUBMIT' (context, params = {}) {
      context.commit('setLoadingSubmit', params.isLoading)
    },
    'TOGGLE_GLOBAL_LOADING' (context, params = {}) {
      context.commit('toggleGlobalLoading', params.isLoading)
    },
    'TOGGLE_LOCAL_LOADING' (context, params = {}) {
      context.commit('toggleLocalLoading', params.isLoading)
    },
    'TOGGLE_GLOBAL_ERROR' (context, params = {}) {
      context.commit('toggleGlobalError', params)
    },
    'UPDATE_LIGHTBOX' (context, params = {}) {
      context.commit('updateLightbox', params)
    },
    'SET_IE_11' (context, params = {}) {
      context.commit('setIE11', params)
    },
    'CHANGE_STATE' (context, params = {}) {
      const section = context.state.currentSection
      if (params.action === 'NEXT') {
        context.dispatch(`VERIFY_${section.toUpperCase()}_FORM`)
        if (!context.state[section].hasError) {
          context.commit('setCurrentState', params.action)
        }
      } else if (params.action === 'BACK') {
        context.commit('setCurrentState', params.action)
      } else {
        const sections = ['customer', 'address', 'payment', 'review']
        const i = sections.indexOf(params.action.toLowerCase())
        const j = sections.indexOf(section)
        if (i > j) {
          const validate = sections.slice(j, i)
          var error = false
          validate.forEach(key => {
            if (!error) {
              context.dispatch(`VERIFY_${key.toUpperCase()}_FORM`)
              error = error || context.state[key].hasError
              if (error) {
                context.commit('setCurrentState', key.toUpperCase())
              }
            }
          })
          if (!error) {
            context.commit('setCurrentState', params.action)
          }
        } else {
          context.commit('setCurrentState', params.action)
        }
      }
    },
    'SET_INITIAL_STATE' (context, params = {}) {
      sectionService.onTransition(state => {
        context.state.currentSection = state.value
      }).start()
      context.dispatch('CHANGE_STATE', { action: 'CUSTOMER' })
    },
    'CREATE_SESSION' (context, params = {}) {
      return CheckoutInstance.session.create(params.token, {}).then((res) => {
        const sessionId = res.headers.location.split('/')[4]
        context.commit('setSessionId', sessionId)
      })
    },
    'SET_CUSTOMER' (context, params = {}) {
      return CheckoutInstance.session.customer(params.paymentLinkId, params.sessionId, params.customer)
    },
    'SET_ADDRESS' (context, params = {}) {
      return CheckoutInstance.session.billingAddress(params.paymentLinkId, params.sessionId, params.address)
    },
    'SET_PAYMENT' (context, params = {}) {
      return CheckoutInstance.session.payment(params.paymentLinkId, params.sessionId, params.payment)
    },
    'SET_STATUS' (context, params = {}) {
      context.commit('setStatus', params.status)
    },
    'GET_PAYMENT_LINK' (context, params = {}) {
      let googlePay = {}
      return CheckoutInstance.link.get(params.token)
        .then((res) => {
          context.commit('setAntifraudeId', uuidv4())
          context.dispatch('GOOGLE_PAY', { paymentLinkId: params.token, walletType: 'google_pay' })
            .then((response) => {
              googlePay = response.google_pay
              context.commit('setPaymentData', { tokenData: res, googlePayData: googlePay })
            })
          context.commit('toggleGlobalLoading', false)
          context.commit('setTokenData', res)
          context.commit('setCustomerData', res)
          context.commit('setAddressData', res)
          context.commit('setAccountTheme', res)
          context.dispatch('SET_INITIAL_STATE')
          enableFingerPrint(res, context.getters.antifraudeId)

          return Promise.resolve(res)
        })
        .catch((err) => {
          context.commit('toggleGlobalLoading', false)
          return catchError(err, context)
        })
    },
    'TOKENIZE_CARD' (context, params = {}) {
      return CheckoutInstance.tokenizer.tokenizeCard(params.publicKey, params.body)
        .then((res) => {
          context.commit('toggleGlobalLoading', false)
          return Promise.resolve(res)
        })
        .catch((err) => {
          context.commit('toggleGlobalLoading', false)
          return catchError(err, context)
        })
    },
    'GET_ZIPCODE' (context, params = {}) {
      return UtilitiesInstance.commons.getAddressByZipCode(params.zipCode)
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    'GET_BRAND' (context, params = {}) {
      return CheckoutInstance.utils.getBrand(params.bin)
        .then((res) => {
          return Promise.resolve(res)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    'CREATE_PAYMENT' (context, params = {}) {
      if (params.recaptcha) {
        CheckoutInstance.recaptcha = params.recaptcha
      }
      return CheckoutInstance.payment.create(params.publicKey, params.body)
        .then((res) => {
          context.commit('setResponseData', res)
          return Promise.resolve(res)
        })
        .catch((err) => {
          if (params.recaptcha) {
            CheckoutInstance.recaptcha = null
          }
          return Promise.reject(err)
        })
    },
    'SET_CARD_FAILED' (context, value) {
      context.commit('setTotalCardFail', value)
    },
    'APPLE_PAY' (context, params = {}) {
      return CheckoutInstance.applePay.create(params.paymentLinkId, params.sessionId)
    },
    'CLICK_TO_PAY' (context, params = {}) {
      return CheckoutInstance.clickToPay.create(params.paymentLinkId)
    },
    'GOOGLE_PAY' (context, params = {}) {
      return CheckoutInstance.googlePay.read(params.paymentLinkId, params.walletType)
    },
    'GET_TDS_TOKEN' (context, params = {}) {
      return CheckoutInstance.tds.get(params.paymentLinkId)
    }
  }
})
