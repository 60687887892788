export function enableFingerPrint (checkoutInstance, antifraudeId) {
  if (checkoutInstance.antifraud_id && checkoutInstance.account_settings.merchant_id) {
    sessionStorage.setItem('antifraud_id', checkoutInstance.antifraud_id)
    const merchantList = process.env.VUE_APP_FINGERPRINT_MERCHANT_LIST.split(';')
    if (merchantList.includes(checkoutInstance.account_settings.merchant_id)) {
      window.fingerPrint(window, document, 'script', 'https://device.mundipagg.com/p/fp.js', 'csdp')
      window.csdp('app', process.env.VUE_APP_FINGERPRINT_APP_ID)
      window.csdp('sessionid', antifraudeId)
    }
  }
}
