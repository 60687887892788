var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shippable || _vm.showBilling
    ? _c("div", { staticClass: "el-chunk" }, [
        _c("div", { staticClass: "el-chunk" }, [
          _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("section.address")))])]),
        ]),
        _c("div", { staticClass: "eu-flex eu-column eu-row_sm" }, [
          _vm.shippable
            ? _c(
                "div",
                {
                  staticClass: "eu-flex-item",
                  class: {
                    "eu-basis_6_sm": !(
                      _vm.shippable && _vm.address.billingEqualShipping
                    ),
                  },
                },
                [
                  _c("div", { staticClass: "el-chunk" }, [
                    _c("div", { staticClass: "emd-container _type_card" }, [
                      _c(
                        "div",
                        { staticClass: "emd-container__content" },
                        [
                          _c(
                            "div",
                            { staticClass: "emd-container__aside" },
                            [
                              _c("review-icon", {
                                attrs: { icon: "review/shipping" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "el-chunk" }, [
                            _c("h2", [
                              _vm._v(" " + _vm._s(_vm.$t("shipping")) + " "),
                              _vm.address.billingEqualShipping
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("andBilling")) + " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("cep")(
                                  _vm.address.shipping.zip_code.value
                                )
                              )
                            ),
                          ]),
                          _vm.address.type === "brazilian"
                            ? _c("p", { staticClass: "emd-text _size_small" }, [
                                _vm._v(
                                  _vm._s(_vm.address.shipping.street.value) +
                                    ", " +
                                    _vm._s(_vm.address.shipping.number.value)
                                ),
                                _vm.address.shipping.complement.value
                                  ? _c("span", [
                                      _vm._v(
                                        ", " +
                                          _vm._s(
                                            _vm.address.shipping.complement
                                              .value
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " - " +
                                    _vm._s(
                                      _vm.address.shipping.neighborhood.value
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm.address.type === "global"
                            ? [
                                _c(
                                  "p",
                                  { staticClass: "emd-text _size_small" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.address.shipping.line_1.value)
                                    ),
                                  ]
                                ),
                                _vm.address.shipping.line_2.value
                                  ? _c(
                                      "p",
                                      { staticClass: "emd-text _size_small" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.address.shipping.line_2.value
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.address.shipping.city.value) +
                                " - " +
                                _vm._s(_vm.address.shipping.state.value) +
                                " - " +
                                _vm._s(_vm.address.shipping.country.value) +
                                " "
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showBilling
            ? _c("div", { staticClass: "eu-flex-item" }, [
                _c("div", { staticClass: "el-chunk" }, [
                  _c("div", { staticClass: "emd-container _type_card" }, [
                    _c(
                      "div",
                      { staticClass: "emd-container__content" },
                      [
                        _c(
                          "div",
                          { staticClass: "emd-container__aside" },
                          [
                            _c("review-icon", {
                              attrs: { icon: "review/billing" },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "el-chunk" }, [
                          _c("h2", [_vm._v(_vm._s(_vm.$t("billing")))]),
                        ]),
                        _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("cep")(_vm.address.fields.zip_code.value)
                            )
                          ),
                        ]),
                        _vm.address.type === "brazilian"
                          ? _c("p", { staticClass: "emd-text _size_small" }, [
                              _vm._v(
                                _vm._s(_vm.address.fields.street.value) +
                                  ", " +
                                  _vm._s(_vm.address.fields.number.value)
                              ),
                              _vm.address.fields.complement.value
                                ? _c("span", [
                                    _vm._v(
                                      ", " +
                                        _vm._s(
                                          _vm.address.fields.complement.value
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " - " +
                                  _vm._s(_vm.address.fields.neighborhood.value)
                              ),
                            ])
                          : _vm._e(),
                        _vm.address.type === "global"
                          ? [
                              _c("p", { staticClass: "emd-text _size_small" }, [
                                _vm._v(_vm._s(_vm.address.fields.line_1.value)),
                              ]),
                              _vm.address.fields.line_2.value
                                ? _c(
                                    "p",
                                    { staticClass: "emd-text _size_small" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.address.fields.line_2.value)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _c(
                          "p",
                          { staticClass: "emd-text _size_small eu-capitalize" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.address.fields.city.value) +
                                " - " +
                                _vm._s(_vm.address.fields.state.value) +
                                " - " +
                                _vm._s(_vm.address.fields.country.value) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }