var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "emd-progress-indicator",
      class: [{ _interactive: _vm.interactive }, _vm.enumVariant[_vm.variant]],
    },
    _vm._l(_vm.items, function (item) {
      return _c(
        "li",
        {
          key: item.key,
          staticClass: "emd-progress-indicator__item",
          class: { _active: item.selected },
          on: {
            click: function ($event) {
              return _vm.clicked(item.key)
            },
          },
        },
        [
          _c("div", { staticClass: "emd-progress-indicator__label" }, [
            _vm._v(" " + _vm._s(item.value) + " "),
          ]),
          _c("span", { staticClass: "emd-progress-indicator__icon" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "16px",
                  height: "16px",
                  viewBox: "0 0 16 16",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("path", {
                  attrs: {
                    fill: "currentColor",
                    d: "M8,0 C3.58064516,0 0,3.58064516 0,8 C0,12.4193548 3.58064516,16 8,16 C12.4193548,16 16,12.4193548 16,8 C16,3.58064516 12.4193548,0 8,0 Z",
                  },
                }),
              ]
            ),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }