<template>
  <div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{'_state_error': customer.fields.name.error}">
          <label class="emd-label" :class="{ '_required': customer.fields.name.validation.required }">{{ $t('fields.fullName') }}</label>
          <input class="emd-input" placeholder="Digite seu nome e sobrenomes" type="text" v-model="customer.fields.name.value" @input="customer.fields.name.error = false" />
          <p v-if="customer.fields.name.error" class="emd-input-feedback">{{ $t(`error.${customer.fields.name.error}`) }}</p>
        </div>
      </div>
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{'_state_error': customer.fields.email.error}">
          <label class="emd-label" :class="{ '_required': customer.fields.email.validation.required }">{{ $t('fields.email') }}</label>
          <input class="emd-input" type="email" placeholder="exemplo@exemplo.com" v-model="customer.fields.email.value" @input="customer.fields.email.error = false" />
          <p v-if="customer.fields.email.error" class="emd-input-feedback">{{ $t(`error.${customer.fields.email.error}`) }}</p>
          <p v-else class="emd-input-feedback">{{ $t('helper.email') }}</p>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{'_state_error': customer.fields.document.error}">
          <label class="emd-label" :class="{ '_required': customer.fields.document.validation.required }">{{ $t('fields.document') }}</label>
          <the-mask :masked="false" class="emd-input" placeholder="000.000.000-00" :mask="['###.###.###-##', '##.###.###/####-##']" type="text" v-model="customer.fields.document.value" @input="customer.fields.document.error = false" />
          <p v-if="customer.fields.document.error" class="emd-input-feedback">{{ $t(`error.${customer.fields.document.error}`) }}</p>
        </div>
      </div>
    </div>
    <div class="eu-flex eu-column eu-row_md">
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{'_state_error': customer.fields.home_phone.error}">
          <label class="emd-label" :class="{ '_required': customer.fields.home_phone.validation.required }">{{ $t('fields.phone') }}</label>
          <the-mask :masked="false" class="emd-input" :mask="['(##) ####-####', '(##) #####-####']" type="tel" placeholder="(00) 0 0000-0000" v-model="customer.fields.home_phone.value" @input="customer.fields.home_phone.error = false" />
          <p v-if="customer.fields.home_phone.error" class="emd-input-feedback">{{ $t(`error.${customer.fields.home_phone.error}`) }}</p>
        </div>
      </div>
      <div class="eu-flex-item eu-basis_6_md">
        <div class="emd-form-group" :class="{'_state_error': customer.fields.mobile_phone.error}">
          <label class="emd-label" :class="{ '_required': customer.fields.mobile_phone.validation.required }">{{ $t('fields.cellPhone') }}</label>
          <the-mask :masked="false" class="emd-input" :mask="['(##) ####-####', '(##) #####-####']" type="tel" placeholder="(00) 0 0000-0000" v-model="customer.fields.mobile_phone.value" @input="customer.fields.mobile_phone.error = false" />
          <p v-if="customer.fields.mobile_phone.error" class="emd-input-feedback">{{ $t(`error.${customer.fields.mobile_phone.error}`) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CustomerEditable',
  component: {
    TheMask
  },
  computed: {
    customer: {
      get () {
        return this.$store.getters.customer
      }
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "fields": {
      "fullName": "Nome Completo",
      "email": "E-mail",
      "document": "CPF ou CNPJ",
      "phone": "Telefone com DDD",
      "cellPhone": "Celular com DDD"
    },
    "helper": {
      "email": "será a sua identificação e principal meio de contato"
    },
    "error": {
      "required": "esse campo é obrigatório",
      "format": "insira um formato válido"
    }
  },
  "en-US": {
    "fields": {
      "fullName": "Full Name",
      "email": "E-mail",
      "document": "Document",
      "phone": "Phone",
      "cellPhone": "Cell phone"
    },
    "helper": {
      "email": "your identification and main means of contact"
    },
    "error": {
      "required": "this field is required",
      "format": "please, insert a valid format"
    }
  }
}
</i18n>
