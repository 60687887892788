/* eslint-disable */
import { isEmpty } from '@/utils'

const theme = {
  mutations: {
    setAccountTheme (state, tokenData) {
      let css = ''
      const head = document.querySelector('head')
      const style = document.createElement('style')
      let primaryColor, secondaryColor
      if (tokenData.layout_settings.primary_color) {
        /* PRIMARY COLOR */
        primaryColor = removeHashFromHex(tokenData.layout_settings.primary_color)
        const primaryTextColor = setupTextColor(primaryColor, 160)
        // clarear cor no hover caso textColor seja branca
        const primaryHoverIndex = primaryTextColor === 'FFFFFF' ? 8 : -8
        const primaryColorHover = setupHoverColor(primaryColor, primaryHoverIndex)
        if (!isEmpty(primaryColor)) {
          css += buildPrimaryColor(primaryColor, primaryColorHover, primaryTextColor)
        }
      }
      if (tokenData.layout_settings.secondary_color) {
        /* SECONDARY COLOR */
        secondaryColor = removeHashFromHex(tokenData.layout_settings.secondary_color)
        const secondaryTextColor = setupTextColor(secondaryColor, 160)
        // clarear cor no hover caso textColor seja branca
        const secondaryHoverIndex = secondaryTextColor === 'FFFFFF' ? 8 : -8
        const secondaryColorHover = setupHoverColor(secondaryColor, secondaryHoverIndex)
        if (!isEmpty(secondaryColor)) {
          css += buildSecondaryColor(secondaryColor, secondaryColorHover, secondaryTextColor)
        }
      }
      /* COR DOS ÍCONES SVG */
      if (!isEmpty(primaryColor) && !isEmpty(secondaryColor)) {
        css += `.emeraldicons._color_theme { color: #${primaryColor}; fill: #${secondaryColor};}`
      }
      style.type = 'text/css'
      if (style.styleSheet) {
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }
      head.appendChild(style)
    }
  }
}

function buildPrimaryColor (color, hover, text) {
  const progressIndicator = `.emd-progress-indicator._merchant_primary { color: #${color}; } .emd-progress-indicator._merchant_primary .emd-progress-indicator__item:not(:first-child):before { background-color: #${color} } `
  const btn = `.emd-btn._merchant_primary { background-color: #${color}; border-color: #${color}; color: #${text}; } .emd-btn._merchant_primary:focus, .emd-btn._merchant_primary:hover { background-color: #${hover}; border-color: #${hover}; } .emd-btn._type_text._merchant_primary, .emd-btn._type_link._merchant_primary { color: #${color}; background-color: transparent; } .emd-btn._type_text._merchant_primary:hover, .emd-btn._merchant_primary._type_text:focus, .emd-btn._type_link._merchant_primary:hover, .emd-btn._merchant_primary._type_link:focus { color: #${hover}; } `
  return progressIndicator + btn
}

function buildSecondaryColor (color, hover, text) {
  const btn = `.emd-btn._merchant_secondary { background-color: #${color}; border-color: #${color}; color: #${text}; } .emd-btn._merchant_secondary:focus, .emd-btn._merchant_secondary:hover { background-color: #${hover}; border-color: #${hover}; }`
  return btn
}

function removeHashFromHex (color) {
  if (color.startsWith('#')) {
      color = color.substring(1)
  }
  return color
}

function setupHoverColor (color, percent) {
  if (color) {
    var num = parseInt(color,16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
    return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1)
  }
  return color
}

function setupTextColor (color, limit) {
  if (color) {
      var rgb = getRGB(color)
      var luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b
      if (luma < limit) {
          return 'FFFFFF'
      } else {
          return '000000'
      }
  } else {
      return '000000'
  }
}

function getRGB(color) {
  if (color) {
    var rgb = parseInt(color, 16) // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff // extract red
    var g = (rgb >> 8) & 0xff // extract green
    var b = (rgb >> 0) & 0xff // extract blue
    return { 'r': r, 'g': g, 'b': b }
  }
  return color
}

export default theme
