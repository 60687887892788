var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoadingSubmit
      ? _c(
          "section",
          { staticClass: "el-centered" },
          [_c("emd-pagarme-loader")],
          1
        )
      : _c(
          "section",
          [
            _c(
              "div",
              { staticClass: "el-chunk" },
              [
                _c("emd-modal", {
                  attrs: {
                    scrollable: "",
                    "has-footer": "",
                    open: _vm.openModal,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "header" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "emd-text _size_subpage _color_primary eu-flex-item",
                              },
                              [
                                _c("i", { staticClass: "uil uil-info-circle" }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("modalTitle")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "h2",
                            {
                              staticClass:
                                "emd-text _size_section _color_subtle eu-flex-item body",
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("modalMessage")) + " ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm.isEditable
              ? _c("div", { staticClass: "emd-container _type_card" }, [
                  _c("div", { staticClass: "emd-container__content" }, [
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", { staticClass: "eu-capitalize" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.customer.fields.name.value || "-") +
                            " "
                        ),
                      ]),
                    ]),
                    _vm.customer.fields.email
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            " " + _vm._s(_vm.customer.fields.email.value) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.customer.fields.document
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("document")(
                                  _vm.customer.fields.document.value
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.customer.fields.home_phone
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("phone")(
                                  _vm.customer.fields.home_phone.value
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.customer.fields.mobile_phone
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("phone")(
                                  _vm.customer.fields.mobile_phone.value
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _c("customer-editable"),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }