<template>
  <div>
    <div id="challengeIframeElement" class="challengeIframeElement">
      <div class="modal-content">
        <iframe
          title="tds"
          name="challengeIframe"
          id="challengeIframe"
        ></iframe>
      </div>
    </div>
    <section class="el-centered" v-if="isLoadingSubmit">
      <emd-pagarme-loader />
    </section>
    <section v-else>
      <CostumerReview />
      <AddressReview />
      <PaymentReview />
    </section>
  </div>
</template>

<script>
import { EmdPagarmeLoader } from 'emerald-vue/src/components'
import CostumerReview from './CustomerReview.vue'
import AddressReview from './AddressReview.vue'
import PaymentReview from './PaymentReview.vue'
import { onVisitPage } from '@/utils/analitica/events'

export default {
  name: 'Review',
  components: {
    EmdPagarmeLoader,
    CostumerReview,
    AddressReview,
    PaymentReview
  },
  computed: {
    isLoadingSubmit () {
      return this.$store.getters.loadingSubmit
    }
  },
  created () {
    onVisitPage.events.view.onVisitPage('review')
    this.import3ds()
  },
  methods: {
    import3ds () {
      const script3ds = document.createElement('script')
      script3ds.setAttribute(
        'src',
        process.env.VUE_APP_3DS_URL
      )
      script3ds.async = true
      document.head.appendChild(script3ds)
    }
  }
}
</script>

<style>
#challengeIframe {
  display: none;
  width: 250px;
  height: 400px;
  border: 0px;
}

.challengeIframeElement {
  display: none;
  position: fixed;
  z-index: 10000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: fit-content;
}
</style>
