export const isEmpty = (text) => {
  return (text === '' || text === null)
}

const isValidPhone = (phone) => {
  // eslint-disable-next-line
  var re = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
  return re.test(phone)
}

const isValidEmail = (email) => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const isValidCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false
  if (cnpj.length !== 14) return false
  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000' || cnpj === '11111111111111' || cnpj === '22222222222222' || cnpj === '33333333333333' || cnpj === '44444444444444' || cnpj === '55555555555555' || cnpj === '66666666666666' || cnpj === '77777777777777' || cnpj === '88888888888888' || cnpj === '99999999999999') {
    return false
  }
  // Valida DVs
  var size = cnpj.length - 2
  var numbers = cnpj.substring(0, size)
  var dig = cnpj.substring(size)
  var sum = 0
  var pos = size - 7
  var i
  for (i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  var result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== dig.charAt(0)) {
    return false
  }
  size = size + 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== dig.charAt(1)) {
    return false
  }
  return true
}

const isValidCPF = (cpf) => {
  // eslint-disable-next-line
  cpf = cpf.replace(/[^\d]+/g,'')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666' || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') {
    return false
  }
  var add, rev, i
  // Valida 1o digito
  add = 0
  for (i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(9))) {
    return false
  }
  // Valida 2o digito
  add = 0
  for (i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(10))) {
    return false
  }
  return true
}

const toDigits = numString =>
  numString
    .replace(/[^0-9]/g, '')
    .split('')
    .map(Number)

const condTransform = (predicate, value, fn) => {
  if (predicate) {
    return fn(value)
  } else {
    return value
  }
}

const doubleEveryOther = (current, idx) =>
  condTransform(idx % 2 === 0, current, x => x * 2)

const reduceMultiDigitVals = current =>
  condTransform(current > 9, current, x => x - 9)

const isValidCard = (numString) => {
  if (!numString || numString !== '') return false
  const digits = toDigits(numString)
  const len = digits.length
  const luhnDigit = digits[len - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map(doubleEveryOther)
    .map(reduceMultiDigitVals)
    .reduce((current, accumulator) => current + accumulator, luhnDigit)
  return total % 10 === 0
}

const isValidExpDate = (date) => {
  const month = date.substring(0, 2)
  var year = date.substring(2, 4)
  if (!(month && year) || (month === '' || year === '')) {
    return false
  }
  if (!/^\d+$/.test(month)) {
    return false
  }
  if (!/^\d+$/.test(year)) {
    return false
  }
  if (parseInt(month, 10) > 12 || parseInt(month, 10) === 0) {
    return false
  }
  if (year.length === 2) {
    var prefix = (new Date()).getFullYear()
    prefix = prefix.toString().slice(0, 2)
    year = prefix + year
  }
  // o 0 faz retornar o último dia do mês
  const expiration = new Date(year, month, 0)
  // ajusta hora de 00:00:00 para 23:59:59
  expiration.setHours(23, 59, 59)
  const current = new Date()
  const lessThan13 = expiration.getFullYear() - current.getFullYear() <= 13
  return expiration > current && lessThan13
}

export function hasError (field) {
  var error = false

  if (!field.validation) return false

  if (field.validation.required && isEmpty(field.value)) {
    return 'required'
  }

  if (!isEmpty(field.value)) {
    if (field.validation.length) {
      const str = field.value.toString()
      if (typeof field.validation.length === 'object') {
        error = true
        field.validation.length.forEach(size => {
          if (str.length === size) {
            error = false
          }
        })
        return error ? 'length' : false
      }
      return str.length !== field.validation.length ? 'length' : false
    }

    if (field.validation.format) {
      field.validation.format.forEach(format => {
        if (format.type === 'email') {
          error = !isValidEmail(field.value)
        }
        if (format.type === 'document') {
          error = !(isValidCNPJ(field.value) || isValidCPF(field.value))
        }
        if (format.type === 'phone') {
          error = !isValidPhone(field.value)
        }
        if (format.type === 'credit_card') {
          error = !isValidCard(field.value)
        }
        if (format.type === 'expiration_date') {
          error = !isValidExpDate(field.value)
        }
        if (format.type === 'text') {
          error = /[^A-Za-z\s]+/g.test(field.value)
        }
      })
      return error ? 'format' : false
    }
  }

  return false
}
