var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "eu-flex eu-column eu-row_md" }, [
      _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
        _c(
          "div",
          {
            staticClass: "emd-form-group",
            class: { _state_error: _vm.customer.fields.name.error },
          },
          [
            _c(
              "label",
              {
                staticClass: "emd-label",
                class: {
                  _required: _vm.customer.fields.name.validation.required,
                },
              },
              [_vm._v(_vm._s(_vm.$t("fields.fullName")))]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customer.fields.name.value,
                  expression: "customer.fields.name.value",
                },
              ],
              staticClass: "emd-input",
              attrs: {
                placeholder: "Digite seu nome e sobrenomes",
                type: "text",
              },
              domProps: { value: _vm.customer.fields.name.value },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.customer.fields.name,
                      "value",
                      $event.target.value
                    )
                  },
                  function ($event) {
                    _vm.customer.fields.name.error = false
                  },
                ],
              },
            }),
            _vm.customer.fields.name.error
              ? _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`error.${_vm.customer.fields.name.error}`))
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
        _c(
          "div",
          {
            staticClass: "emd-form-group",
            class: { _state_error: _vm.customer.fields.email.error },
          },
          [
            _c(
              "label",
              {
                staticClass: "emd-label",
                class: {
                  _required: _vm.customer.fields.email.validation.required,
                },
              },
              [_vm._v(_vm._s(_vm.$t("fields.email")))]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customer.fields.email.value,
                  expression: "customer.fields.email.value",
                },
              ],
              staticClass: "emd-input",
              attrs: { type: "email", placeholder: "exemplo@exemplo.com" },
              domProps: { value: _vm.customer.fields.email.value },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.customer.fields.email,
                      "value",
                      $event.target.value
                    )
                  },
                  function ($event) {
                    _vm.customer.fields.email.error = false
                  },
                ],
              },
            }),
            _vm.customer.fields.email.error
              ? _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(
                    _vm._s(_vm.$t(`error.${_vm.customer.fields.email.error}`))
                  ),
                ])
              : _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(_vm._s(_vm.$t("helper.email"))),
                ]),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "eu-flex eu-column eu-row_md" }, [
      _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
        _c(
          "div",
          {
            staticClass: "emd-form-group",
            class: { _state_error: _vm.customer.fields.document.error },
          },
          [
            _c(
              "label",
              {
                staticClass: "emd-label",
                class: {
                  _required: _vm.customer.fields.document.validation.required,
                },
              },
              [_vm._v(_vm._s(_vm.$t("fields.document")))]
            ),
            _c("the-mask", {
              staticClass: "emd-input",
              attrs: {
                masked: false,
                placeholder: "000.000.000-00",
                mask: ["###.###.###-##", "##.###.###/####-##"],
                type: "text",
              },
              on: {
                input: function ($event) {
                  _vm.customer.fields.document.error = false
                },
              },
              model: {
                value: _vm.customer.fields.document.value,
                callback: function ($$v) {
                  _vm.$set(_vm.customer.fields.document, "value", $$v)
                },
                expression: "customer.fields.document.value",
              },
            }),
            _vm.customer.fields.document.error
              ? _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`error.${_vm.customer.fields.document.error}`)
                    )
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "eu-flex eu-column eu-row_md" }, [
      _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
        _c(
          "div",
          {
            staticClass: "emd-form-group",
            class: { _state_error: _vm.customer.fields.home_phone.error },
          },
          [
            _c(
              "label",
              {
                staticClass: "emd-label",
                class: {
                  _required: _vm.customer.fields.home_phone.validation.required,
                },
              },
              [_vm._v(_vm._s(_vm.$t("fields.phone")))]
            ),
            _c("the-mask", {
              staticClass: "emd-input",
              attrs: {
                masked: false,
                mask: ["(##) ####-####", "(##) #####-####"],
                type: "tel",
                placeholder: "(00) 0 0000-0000",
              },
              on: {
                input: function ($event) {
                  _vm.customer.fields.home_phone.error = false
                },
              },
              model: {
                value: _vm.customer.fields.home_phone.value,
                callback: function ($$v) {
                  _vm.$set(_vm.customer.fields.home_phone, "value", $$v)
                },
                expression: "customer.fields.home_phone.value",
              },
            }),
            _vm.customer.fields.home_phone.error
              ? _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`error.${_vm.customer.fields.home_phone.error}`)
                    )
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
        _c(
          "div",
          {
            staticClass: "emd-form-group",
            class: { _state_error: _vm.customer.fields.mobile_phone.error },
          },
          [
            _c(
              "label",
              {
                staticClass: "emd-label",
                class: {
                  _required:
                    _vm.customer.fields.mobile_phone.validation.required,
                },
              },
              [_vm._v(_vm._s(_vm.$t("fields.cellPhone")))]
            ),
            _c("the-mask", {
              staticClass: "emd-input",
              attrs: {
                masked: false,
                mask: ["(##) ####-####", "(##) #####-####"],
                type: "tel",
                placeholder: "(00) 0 0000-0000",
              },
              on: {
                input: function ($event) {
                  _vm.customer.fields.mobile_phone.error = false
                },
              },
              model: {
                value: _vm.customer.fields.mobile_phone.value,
                callback: function ($$v) {
                  _vm.$set(_vm.customer.fields.mobile_phone, "value", $$v)
                },
                expression: "customer.fields.mobile_phone.value",
              },
            }),
            _vm.customer.fields.mobile_phone.error
              ? _c("p", { staticClass: "emd-input-feedback" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(`error.${_vm.customer.fields.mobile_phone.error}`)
                    )
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }