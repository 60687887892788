import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueCurrencyInput from 'vue-currency-input'

// FONT AWESOME CONFIG & IMPORTS
import { formatCurrency, formatCEP, formatDocument, formatDate, formatPhone } from './filters'

// IMPORT EMERALD GLOBALY
import 'emerald-workbench/dist/emerald.mundipagg.min.css'
if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
}

if (['production', 'contingency'].includes(process.env.VUE_APP_CORE_ENV)) {
  Vue.use(VueAnalytics, {
    id: 'UA-63609965-24',
    router,
    debug: {
      enabled: false, // Turn on for activate debuging
      sendHitTask: true
    }
  })
}

Vue.config.productionTip = false

Vue.use(VueTheMask)

Vue.filter('currency', formatCurrency)
Vue.filter('cep', formatCEP)
Vue.filter('document', formatDocument)
Vue.filter('date', formatDate)
Vue.filter('phone', formatPhone)

Vue.use(VueCurrencyInput)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
