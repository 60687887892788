var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("google-pay-button", {
    attrs: {
      environment: _vm.environment,
      "button-type": "short",
      "button-color": "default",
      "existing-payment-method-required": _vm.existingPaymentMethodRequired,
    },
    domProps: {
      paymentRequest: {
        apiVersion: _vm.paymentRequest.apiVersion,
        apiVersionMinor: _vm.paymentRequest.apiVersionMinor,
        allowedPaymentMethods: _vm.paymentRequest.allowedPaymentMethods,
        merchantInfo: _vm.paymentRequest.merchantInfo,
        transactionInfo: _vm.paymentRequest.transactionInfo,
        callbackIntents: ["PAYMENT_AUTHORIZATION"],
      },
      onPaymentAuthorized: _vm.onPaymentDataAuthorized,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }