<template>
  <div class="el-bounds _narrow">
    <div class="el-column__main">
      <template v-if="tokenData && !globalError">
        <div class="el-column__main_body">
          <div class="emd-container"
            :class="{ '_footer_end': checkoutState === 'form', '_footer_centered': checkoutState === 'success' }">
            <template v-if="checkoutState === 'form'">
              <form v-on:submit.prevent="submit" novalidate>
                <div class="emd-container__content">
                  <div class="el-chunk">
                    <emd-progress-indicator class="_merchant_primary" :items="sections" :interactive="true"
                      variant="primary" @goTo="goToState" />
                  </div>
                  <div class="el-chunk">
                    <emd-alert variant="danger" v-if="errors.length > 0" @cancel="handleCancel"
                      :title="$t('error.title')" autoclose>
                      <template slot="content">
                        <i18n v-for="(error, index) in errors" :path="`error.${error}`" tag="p" v-bind:key="index">
                          <template v-slot:form>
                            <span class="emd-text _type_caption">{{ $t(`section.${formError}`) }}</span>
                          </template>
                        </i18n>
                      </template>
                    </emd-alert>
                  </div>
                  <section-customer v-if="currentSection === 'customer'" />
                  <section-address v-if="currentSection === 'address'" />
                  <section-payment v-if="currentSection === 'payment'" />
                  <section-review v-if="currentSection === 'review'" />
                  <form data-pagarmecheckout-form v-show="false">
                    <input class="emd-input " type="text" v-model="tdsToken" v-if="currentSection === 'review'">
                    <input class="emd-input " type="text" v-model="creditCardNumber" v-if="currentSection === 'review'"
                      data-pagarmecheckout-element="number" v-show="true">
                  </form>
                  <template v-if="currentSection === 'review' && !isLoadingSubmit">
                    <vue-recaptcha v-if="showCaptcha" :sitekey="sitekey" :loadRecaptchaScript="true"
                      @verify="validateCaptcha" @expired="resetCaptcha" :language="$i18n?.locale" theme="light"
                      class="captcha-container g-recaptcha" />
                    <p v-if="showRecaptchaFeedback" class="emd-input-feedback eu-align_center _state_error ">{{
                      $t('recaptchaError') }}</p>
                  </template>
                </div>
                <div class="emd-container__footer" v-if="!isLoadingSubmit">
                  <button class="emd-btn _color_primary _type_text _block_mobile _merchant_primary"
                    v-if="currentSection !== 'customer'" type="button" @click="goToState('BACK')">
                    <i class="uil uil-angle-left" />
                    {{ $t('btn.back') }}
                  </button>
                  <button class="emd-btn _color_primary _block_mobile _merchant_primary" type="submit"
                    v-if="currentSection !== 'review'">
                    {{ $t('btn.next') }}
                    <i class="uil uil-angle-right" />
                  </button>
                  <button
                    v-if="payments.selectedPaymentMethod !== 'apple_pay' && payments.selectedPaymentMethod !== 'google_pay' && currentSection === 'review'"
                    class="emd-btn _color_primary _block_mobile"
                    :class="{ '_disabled': loadingPayment, '_merchant_secondary': !loadingPayment }"
                    :disabled="loadingPayment" type="submit">
                    {{ payments.selectedPaymentMethod === 'boleto' || payments.selectedPaymentMethod === 'pix' ?
                    $t(`btn.${payments.selectedPaymentMethod}`) : $t('btn.pay') }}
                  </button>
                  <template v-if="payments.selectedPaymentMethod === 'apple_pay' && currentSection === 'review'">
                    <apple-pay @onPaymentAuthorized="setApplePayPayment" />
                  </template>
                  <template v-if="payments.selectedPaymentMethod === 'google_pay' && currentSection === 'review'">
                    <google-pay :setPaymentData="setPaymentData" />
                  </template>
                </div>
              </form>
            </template>
            <template v-if="checkoutState === 'success'">
              <success />
            </template>
          </div>
        </div>
        <div class="el-column__main_aside">
          <order-detail />
        </div>
      </template>
      <template v-else>
        <error :code="globalErrorCode" />
      </template>
    </div>
    <div class="el-chunk">
      <emd-modal id="hst_src-modal" scrollable has-footer :open="showClickToPayModal">
        <template v-slot:content>
          <div id="hst_src" class="click-to-pay" />
        </template>
      </emd-modal>
    </div>
    <script type="application/javascript" :src="clickToPayParam?.hst_sdk_url" />
  </div>
</template>

<script>
import { onClickNext, onClickBack, onCaptchaError, onPay } from '@/utils/analitica/events'
import { EmdModal } from 'emerald-vue/src/components'
import moment from 'moment'
import OrderDetail from './OrderDetail.vue'
import Customer from './customer/Customer.vue'
import Address from './address/Address.vue'
import Payment from './Payment.vue'
import Review from './review/Review.vue'
import Success from './success/Success.vue'
import ApplePay from './paymentForm/ApplePay.vue'
import GooglePay from './paymentForm/GooglePay.vue'
import Error from '@/components/project/globals/GlobalError.vue'
import EmdAlert from '@/components/emerald/molecules/EmdAlert.vue'
import EmdProgressIndicator from '@/components/emerald/molecules/EmdProgressIndicator.vue'
import { isEmpty } from '@/utils'
import { checkoutService } from '@/stateMachine'
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters } from 'vuex'
const get = require('lodash.get')

export default {
  name: 'Checkout',
  components: {
    OrderDetail,
    SectionCustomer: Customer,
    SectionAddress: Address,
    SectionPayment: Payment,
    SectionReview: Review,
    Success,
    Error,
    EmdAlert,
    EmdProgressIndicator,
    VueRecaptcha,
    ApplePay,
    GooglePay,
    EmdModal
  },
  data () {
    return {
      clickToPayData: {},
      showClickToPayModal: false,
      clickToPayParam: {},
      disableTds: false,
      tokenizeCardData: null,
      tdsToken: '',
      loadingPayment: false,
      errorStatus: null,
      errors: [],
      checkoutState: 'form',
      sections: [
        {
          key: 'customer',
          value: this.$t('section.customer'),
          selected: false
        },
        {
          key: 'address',
          value: this.$t('section.address'),
          selected: false
        },
        {
          key: 'payment',
          value: this.$t('section.payment'),
          selected: false
        },
        {
          key: 'review',
          value: this.$t('section.review'),
          selected: false
        }
      ],
      sitekey: process.env.VUE_APP_RECAPTCHA_ID,
      showCaptchaFeedback: false,
      recaptcha: null,
      applePayData: null,
      tdsData: null
    }
  },
  computed: {
    ...mapGetters([
      'antifraudeId',
      'localLoading',
      'globalError',
      'globalErrorCode',
      'tokenData',
      'sessionId',
      'isLightbox',
      'customer',
      'address',
      'payment',
      'currentSection'
    ]),
    showRecaptchaFeedback () {
      return this.showCaptchaFeedback && !this.isPaymentWithDigitalWallet
    },
    isPaymentWithDigitalWallet () {
      return this.isPaymentWithApplePay || this.isPaymentWithGooglePay || this.isPaymentWithClickToPay
    },
    creditCardNumber () {
      return this.payments.payment_1?.cardNumber?.value
    },
    discount () {
      return this.discountPercentage(get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount_percentage`), this.subtotal) ||
        this.discountFlat || 0
    },
    discountFlat () {
      return get(this.tokenData, `payment_settings.${this.payments.selectedPaymentMethod}_settings.discount`)
    },
    subtotal () {
      let subtotal = 0
      if (this.tokenData.cart_settings.items) {
        this.tokenData.cart_settings.items.forEach((item) => {
          subtotal += item.amount * item.default_quantity
        })
      } else {
        this.tokenData.cart_settings.recurrences[0].plan.items.forEach((item) => {
          subtotal += item.quantity * item.princing_scheme.price
        })
      }
      return subtotal
    },
    installments () {
      return this.payment.installments
    },
    total () {
      const total = this.subtotal + this.tokenData.cart_settings.shipping_total_cost - this.discount
      if (this.payments.selectedPaymentMethod === 'credit_card') {
        return this.installments[this.payment.payment_1.installment.value - 1]?.total || total
      }
      return total
    },
    isPaymentWithApplePay () {
      return this.payment.selectedPaymentMethod === 'apple_pay'
    },
    isPaymentWithClickToPay () {
      return this.payment.selectedPaymentMethod === 'click_to_pay'
    },
    isPaymentWithGooglePay () {
      return this.payment.selectedPaymentMethod === 'google_pay'
    },
    payments () {
      return this.payment
    },
    selectedPaymentMethod () {
      return this.payment.selectedPaymentMethod
    },
    selectedInstallments () {
      return this.payment.payment_1?.installment?.value
    },
    hasZipCode () {
      return !isEmpty(this.$store.getters.address.fields.zip_code.value)
    },
    hasDocument () {
      return !isEmpty(this.$store.getters.customer.fields.document.value)
    },
    boletoError () {
      return (this.selectedPaymentMethod === 'boleto' || this.selectedPaymentMethod === 'credit_card_and_boleto') && (!this.hasZipCode || !this.hasDocument)
    },
    formError () {
      switch (true) {
        case this.customer.hasError:
          return 'customer'
        case this.address.hasError:
          return 'address'
        case this.payments.hasError:
          return 'payment'
        default:
          return null
      }
    },
    isLoadingSubmit () {
      return this.$store.getters.loadingSubmit
    },
    hasError: {
      get () {
        return this.payments.hasError || this.address.hasError || this.customer.hasError
      },
      set () { }
    },
    failedCount: {
      get () {
        return this.$store.getters.totalCardFail
      },
      set (newValue) {
        this.goToState('PAYMENT')
        this.$store.dispatch('SET_CARD_FAILED', newValue)
      }
    },
    showCaptcha () {
      if (this.isPaymentWithDigitalWallet) {
        return false
      }
      if (process.env.VUE_APP_SHOW_RECAPTCHA) {
        return JSON.parse(process.env.VUE_APP_SHOW_RECAPTCHA)
      }
      return true
    }
  },
  watch: {
    hasError (newValue) {
      if (newValue) {
        if (!this.boletoError) {
          this.errors.push('formError')
          window.scrollTo(0, 0)
        }
      } else {
        this.errors = []
      }
    },
    currentSection (newValue) {
      this.sections.forEach(obj => { obj.selected = false })
      this.changeSelectedSection(newValue)
      if (this.formError && this.formError !== newValue) {
        this.handleCancel()
      }
    }
  },
  created () {
    if (this.$route.query.lightbox) {
      this.$store.dispatch('UPDATE_LIGHTBOX', true)
    }
    this.changeSelectedSection(this.currentSection)
    checkoutService.onTransition(state => {
      this.checkoutState = state.value
    }).start()
  },
  methods: {
    discountPercentage (discountPercentage, amount) {
      if (discountPercentage && amount) {
        return discountPercentage * amount / 100
      }
    },
    changeSelectedSection (key) {
      const selectedSectionIndex = this.sections.findIndex(obj => obj.key === key)
      if (selectedSectionIndex >= 0) {
        this.sections[selectedSectionIndex].selected = true
      }
    },
    handleCancel () {
      if (this.errorStatus === 404) {
        this.$store.dispatch('TOGGLE_GLOBAL_ERROR', { status: true, code: 404 })
      } else {
        this.errors = []
        this.payments.hasError = false
        this.address.hasError = false
        this.customer.hasError = false
      }
    },
    getTypeByDocument (document) {
      if (document?.length === 11) {
        return 'individual'
      }
      return 'company'
    },
    getDocumentType (document) {
      if (document && document.length === 11) {
        return 'cpf'
      }
      return 'cnpj'
    },
    goToState (action) {
      if (action === 'BACK') {
        onClickBack.events.view.onClickBack(this.currentSection)
      }
      if (!this.localLoading) {
        this.$store.dispatch('CHANGE_STATE', { action: action.toUpperCase() })
        if (this.recaptcha !== null) this.resetCaptcha()
      }
    },
    formatPhone (phone) {
      return {
        country_code: '55',
        area_code: phone.substr(0, 2),
        number: phone.substr(2)
      }
    },
    setAddressData () {
      this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: true })
      const address = {
        city: this.address.fields.city.value,
        state: this.address.fields.state.value,
        country: this.address.fields.country.value,
        zip_code: this.address.fields.zip_code.value
      }
      if (this.address.type === 'global') {
        address.line_1 = this.address.fields.line_1.value
        address.line_2 = this.address.fields.line_2.value
      } else {
        address.complement = this.address.fields.complement.value
        address.neighborhood = this.address.fields.neighborhood.value
        address.number = this.address.fields.number.value
        address.street = this.address.fields.street.value
      }

      this.$store.dispatch('SET_ADDRESS', { paymentLinkId: this.tokenData.id, sessionId: this.sessionId, address: address })
        .then((res) => {
          this.goToState('NEXT')
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
        }).catch((error) => {
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
          this.handleError(error)
        })
    },
    tokenizeData () {
      this.loadingPayment = true
      const expiry = [
        this.payments.payment_1.expDate.value.substr(0, 2),
        this.payments.payment_1.expDate.value.substr(2)
      ]
      const tokenizeRequest = {
        publicKey: this.tokenData.account_settings.public_key,
        body: {
          type: 'card',
          card: {
            number: this.payments.payment_1.cardNumber.value,
            holder_name: this.payments.payment_1.name.value,
            exp_month: parseInt(expiry[0]),
            exp_year: parseInt(expiry[1]),
            cvv: this.payments.payment_1.cvv.value
          }
        }
      }
      this.$store.dispatch('TOKENIZE_CARD', tokenizeRequest)
        .then((res) => {
          this.tokenizeCardData = res.id
          this.loadingPayment = false
          this.setPaymentData(res.id)
        }).catch((error) => {
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
          this.loadingPayment = false
          this.handleError(error)
        })
    },
    setApplePayPayment (applePayData) {
      this.applePayData = applePayData
      this.setPaymentData()
    },
    creditCardParam (cardToken) {
      const creditCard = {
        token_id: cardToken,
        installments: this.selectedInstallments,
        antifraud_id: this.antifraudeId
      }
      if (this.tdsData?.trans_status) {
        creditCard.threed_secure = {
          mpi: 'pagarme',
          transaction_id: this.tdsData?.tds_server_trans_id,
          approval_status: this.tdsData?.trans_status
        }
      }

      return creditCard
    },
    setPaymentData (cardToken, googlePayData) {
      if (this.verifyRecaptcha()) {
        this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: true })
        if (!this.loadingPayment) {
          const paymentData = {
            payments: [
              {
                payment_method: this.payments.selectedPaymentMethod
              }
            ]
          }

          if (cardToken) {
            paymentData.payments.forEach(payment => {
              payment.credit_card = this.creditCardParam(cardToken)
            })
          }

          if (this.isPaymentWithClickToPay) {
            paymentData.payments.forEach(payment => {
              payment.click_to_pay = {
                card_encrypted_data: this.clickToPayData
              }
            })
          }

          if (this.isPaymentWithApplePay) {
            paymentData.payments.forEach(payment => {
              payment.apple_pay = this.applePayData.appleDetails
            })
          }

          if (this.isPaymentWithGooglePay) {
            paymentData.payments.forEach(payment => {
              payment.google_pay = {
                signature: googlePayData.googlePayDetails.signature,
                intermediate_signing_key: {
                  signed_key: googlePayData.googlePayDetails.intermediateSigningKey.signedKey,
                  signatures: googlePayData.googlePayDetails.intermediateSigningKey.signatures
                },
                version: googlePayData.googlePayDetails.protocolVersion,
                signed_message: googlePayData.googlePayDetails.signedMessage,
                merchant_identifier: googlePayData.googlePayDetails.merchant_identifier
              }
            })
          }
          this.loadingPayment = true
          return this.$store.dispatch('SET_PAYMENT', { paymentLinkId: this.tokenData.id, sessionId: this.sessionId, payment: paymentData }).then((response) => {
            this.loadingPayment = false
            if (response.order_status === 'failed') {
              if (this.isPaymentWithApplePay) {
                this.applePayData.onCompletePayment('STATUS_FAILURE')
              }
              if (this.isPaymentWithGooglePay) {
                return 'ERROR'
              }
              this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
              this.handleError(response.order_status)
              this.goToState('PAYMENT')
            } else {
              if (this.isPaymentWithGooglePay) {
                return 'SUCCESS'
              } else if (this.isPaymentWithApplePay) {
                this.applePayData.onCompletePayment('STATUS_SUCCESS')
              }
              this.$store.dispatch('SET_STATUS', { status: response })
              checkoutService.send('SUCCESS')
              this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
            }
          }).catch((error) => {
            if (this.isPaymentWithGooglePay) {
              return 'ERROR'
            } else if (this.isPaymentWithApplePay) {
              this.applePayData.onCompletePayment('STATUS_FAILURE')
            }
            if (this.recaptcha !== null) this.resetCaptcha()
            this.loadingPayment = false
            this.errorStatus = error.status
            this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
            this.handleError(error)
          })
        }
      }
    },
    tdsCallback (value) {
      this.tdsData = value
      this.tokenizeData()
    },
    getToken () {
      return this.$store.dispatch('GET_TDS_TOKEN', { paymentLinkId: this.tokenData.id })
        .then((data) => {
          this.tdsToken = data.tds_token
        })
        .catch(() => {
          this.disableTds = true
          this.tokenizeData()
        })
    },
    async initTds () {
      this.loadingPayment = true
      const expiry = [
        this.payments.payment_1.expDate.value.substr(0, 2),
        this.payments.payment_1.expDate.value.substr(2)
      ]

      const phones = []
      if (this.customer.fields.home_phone.value) {
        phones.push({
          country_code: '55',
          subscriber: this.customer.fields.home_phone.value,
          phone_type: 'home'
        })
      }
      if (this.customer.fields.mobile_phone.value) {
        phones.push({
          country_code: '55',
          subscriber: this.customer.fields.mobile_phone.value,
          phone_type: 'mobile'
        })
      }

      const param = {
        email: this.customer.fields.email.value,
        addr_match: 'Y',
        bill_addr: {
          number: this.address.fields.number.value,
          street: this.address.fields.street.value,
          city: this.address.fields.city.value,
          state: this.address.fields.state.value,
          country: 'BRA',
          post_code: this.address.fields.zip_code.value
        },
        ship_addr: {
          number: this.address.fields.number.value,
          street: this.address.fields.street.value,
          city: this.address.fields.city.value,
          state: this.address.fields.state.value,
          country: 'BRA',
          post_code: this.address.fields.zip_code.value
        },
        purchase: {
          amount: this.total,
          currency: 'BRL',
          date: moment().format(),
          instal_data: 2
        },
        acct_number: this.payments.payment_1.cardNumber.value,
        card_expiry_date: `20${expiry[1]}-${expiry[0]}`,
        cardholder_name: this.payments.payment_1.name.value,
        cvv: this.payments.payment_1.cvv.value
      }

      if (phones.length > 0) {
        param.phones = phones
      }

      await this.getToken()

      if (!this.disableTds) {
        // eslint-disable-next-line
        Script3ds.init3ds(this.tdsToken, param, this.tdsCallback, '01')
      }
    },
    getClickToPayParam () {
      return this.$store.dispatch('CLICK_TO_PAY', { paymentLinkId: this.tokenData.id })
        .then((response) => {
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
          return response
        }).catch((error) => {
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
          this.handleError(error)
        })
    },
    async onHstInitialize () {
      this.loadingPayment = true
      this.loadingPayment = false
      this.showClickToPayModal = true

      // eslint-disable-next-line
      hst_Initialize({
        credentialsPayload: this.clickToPayParam.credentials_payload,
        currencyCode: 'BRL',
        checkoutPurchaseValue: this.total.toString(),
        locale: 'pt_BR',
        shippingAddressPreference: 'NONE',
        billingAddressPreference: 'NONE',
        checkoutCallback: this.onHstSrcCheckout,
        fatalErrorCallback: this.onHstSrcError,
        notRecognizedCallback: this.onHstSrcNotRecognized,
        cancelCallback: this.onHstSrcCancel,
        forceActivation: ['VISA', 'MASTERCARD']
      })
    },
    onHstSrcNotRecognized () {
      // eslint-disable-next-line
      hst_SrcTrigger()
    },
    onHstSrcError () {
      this.showClickToPayModal = false
      this.handleError('failed')
    },
    onHstSrcCancel () {
      this.showClickToPayModal = false
      this.goToState('BACK')
    },
    onHstSrcCheckout (checkoutData) {
      this.clickToPayData = checkoutData.paymentPayload
      this.showClickToPayModal = false
      this.setPaymentData()
    },
    async submit () {
      if (!this.localLoading) {
        if (this.currentSection !== 'review') {
          onClickNext.events.view.onClickNext(this.currentSection)
          if (this.currentSection === 'customer') {
            this.$store.dispatch('VERIFY_CUSTOMER_FORM')
            if (!this.customer.hasError) {
              this.setCustomerData()
            }
          }
          if (this.currentSection === 'address') {
            this.$store.dispatch('VERIFY_ADDRESS_FORM')
            if (!this.address.hasError) {
              this.setAddressData()
            }
          }
          if (this.currentSection === 'payment') {
            if (this.selectedPaymentMethod === 'click_to_pay') {
              this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: true })
              this.clickToPayParam = await this.getClickToPayParam()
            }
            this.goToState('NEXT')
          }
        } else {
          onPay.events.view.onPay(this.selectedPaymentMethod, this.selectedInstallments || null)
          if (this.selectedPaymentMethod === 'click_to_pay') {
            // eslint-disable-next-line
            this.onHstInitialize()
          } else if (this.selectedPaymentMethod === 'credit_card') {
            if (this.tokenData?.payment_settings?.threed_secure_enabled) {
              this.initTds()
            } else {
              this.tokenizeData()
            }
          } else {
            this.setPaymentData()
          }
        }
      }
    },
    setCustomerData () {
      this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: true })
      const phones = {}
      const customer = {
        name: this.customer.fields.name.value,
        email: this.customer.fields.email.value,
        document: this.customer.fields.document.value,
        document_type: this.getDocumentType(this.customer.fields.document.value),
        type: this.getTypeByDocument(this.customer.fields.document.value)
      }
      if (this.customer.fields.home_phone.value) {
        phones.home_phone = this.formatPhone(this.customer.fields.home_phone.value)
      }

      if (this.customer.fields.mobile_phone.value) {
        phones.mobile_phone = this.formatPhone(this.customer.fields.mobile_phone.value)
      }

      if (Object.keys(phones).length > 0) {
        customer.phones = phones
      }

      if (this.customer.customer_id) {
        customer.customer_id = this.customer.customer_id
      }

      this.$store.dispatch('SET_CUSTOMER', { paymentLinkId: this.tokenData.id, sessionId: this.sessionId, customer: customer })
        .then((res) => {
          this.goToState('NEXT')
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
        }).catch((error) => {
          this.$store.dispatch('SET_LOADING_SUBMIT', { isLoading: false })
          this.handleError(error)
        })
    },
    handleError (error) {
      window.scrollTo(0, 0)
      var msg = 'general'
      if (error === 'failed') {
        msg = 'dataError'
      } else if (error.message === 'The billing address is required') {
        msg = 'addressRequired'
      } else if (error.body && error.body.message === 'Token not found') {
        msg = 'tokenNotFound'
      } else if (error.body && error.body.message === 'The checkout cannot be paid.') {
        msg = 'checkoutCannotBePaid'
      } else if (error.body && error.body.message === 'Captcha required or invalid') {
        msg = 'invalidCaptcha'
      } else if (error.body && error.body.errors) {
        Object.keys(error.body.errors).forEach((key) => {
          if (error.body.errors[key][0] === 'The number field is not a valid credit card number.') {
            msg = 'invalidCard'
          }
        })
      }
      this.errors.push(msg)
    },
    validateCaptcha () {
      this.recaptcha = window.grecaptcha.getResponse()
      this.showCaptchaFeedback = false
    },
    resetCaptcha () {
      window.grecaptcha.reset()
      this.recaptcha = null
      this.showCaptchaFeedback = false
    },
    verifyRecaptcha () {
      if (this.showCaptcha) {
        if (this.recaptcha === null) {
          onCaptchaError.events.view.onCaptchaError()
          this.showCaptchaFeedback = true
          return false
        }
        this.showCaptchaFeedback = false
      }
      return true
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "section": {
      "customer": "Identificação",
      "address": "Endereço",
      "payment": "Pagamento",
      "review": "Revisão"
    },
    "btn": {
      "backToStore": "Voltar para a loja",
      "back": "Voltar",
      "next": "Avançar",
      "boleto": "Gerar Boleto",
      "pix": "Concluir",
      "pay": "Pagar"
    },
    "error": {
      "title": "Erro",
      "formError": "Parece que o formulário {form} não foi preenchido corretamente.",
      "general": "Algo de errado aconteceu ao processar o seu pedido. Tente novamente mais tarde.",
      "card": "Verifique seus dados do cartão, limite ou se ele está bloqueado e tente novamente.",
      "invalidCard": "O número deste cartão é inválido. Por favor, tente novamente com outro cartão.",
      "addressRequired": "Para finalizar seu pedido, é necessário endereço de cobrança. Por favor, preencha seus dados.",
      "tokenNotFound": "Seu token expirou.",
      "checkoutCannotBePaid": "Esse checkout não pode ser pago.",
      "invalidCaptcha": "Não foi possível realizar seu pagamento. Verifique os campos e tente novamente.",
      "dataError": "Não foi possível efetuar o pagamento desta compra. Revise os dados e tente novamente."
    },
    "recaptchaError": "Esse campo é obrigatório."
  },
  "en-US": {
    "section": {
      "customer": "Identification",
      "address": "Address",
      "payment": "Payment",
      "review": "Review"
    },
    "btn": {
      "backToStore": "Back to the Store",
      "back": "Back",
      "next": "Next",
      "boleto": "Generate Boleto",
      "pix": "Conclude",
      "pay": "Pay"
    },
    "error": {
      "title": "Error",
      "formError": "It seems the form {form} was not filled correctly.",
      "general": "Something went wrong. Please, try again later.",
      "card": "Check your card data, limit or if it is locked and try again.",
      "invalidCard": "This is not a valid card number. Please, try again with another card.",
      "addressRequired": "To process your order provide a billing address.",
      "tokenNotFound": "Token not found.",
      "checkoutCannotBePaid": "This checkout cannot be paid.",
      "invalidCaptcha": "Something went wrong. Please, verify your infos and try again.",
      "dataError": "Payment for this purchase was not possible. Review the data and try again."
    },
    "recaptchaError": "This field is required."
  }
}
</i18n>

<style>
.click-to-pay {
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  padding: 40px;
  border-radius: 3px;
  min-height: 560px;
  max-height: 720px;
  min-width: 293px;
  max-width: 560px;
}

.captcha-container {
  width: 304px;
  margin: 0 auto;
  overflow: hidden;
}

.emd-container__content {
  padding: 8px;
}

#hst_src-modal.emd-container__content {
  padding: 20px;
}

#hst_src {
  padding: 0;
  box-shadow: none;
}

#hst_contact_info_area {
  position: relative;
  width: 100%;
}

#hst_contact_info_inputs_area {
  flex-wrap: wrap;
}

.hst_src_button {
  height: 38px;
}
</style>
