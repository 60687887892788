import moment from 'moment'
import {
  hasError,
  isEmpty
}
from '@/utils'
import store from '@/store'
import ApplePay from '../../views/paymentForm/ApplePay.vue'
import GooglePay from '../../views/paymentForm/GooglePay.vue'

const clonedeep = require('lodash.clonedeep')

const payment = {
  state: {
    amount: 0,
    hasError: false,
    selectedPaymentMethod: null,
    paymentMethods: [],
    brands: [],
    installments: [],
    payment_1: {
      amount: 0,
      percentage: 0
    },
    applePaymentDetails: {},
    creditCard: buildCreditCardForm(),
    boleto: buildBoletoForm(),
    googlePayData: {}
  },
  mutations: {
    setPaymentMethod (state, paymentMethod) {
      state.hasError = false
      state.selectedPaymentMethod = paymentMethod
      state.payment_1 = Object.assign({}, {
        amount: 0,
        percentage: 0
      })
      if (paymentMethod === 'credit_card') {
        state.payment_1 = Object.assign(state.payment_1, clonedeep(state.creditCard))
      }
    },
    async setPaymentData (state, { tokenData, googlePayData }) {
      state.googlePayData = googlePayData
      state.applePaymentDetails = tokenData.apple_payment_details
      if (tokenData.payment_link_type === 'order') {
        state.amount = orderAmount(tokenData)
      } else if (tokenData.payment_link_type === 'subscription') {
        state.amount = subscriptionAmount(tokenData)
      }
      state.paymentMethods = tokenData.payment_settings.accepted_payment_methods

      const accountList = ['acc_z637y5xTajCrpwVP', 'acc_JqM0RJqHGIBY3v62', 'acc_81MW79jiptAoDYxv', 'acc_8YL6ol8HwfJo9Rny', 'acc_Rgb1nWyuACL3JqjE', 'acc_NLOWe0oivqTzmDy5', /* staging account to test */ 'acc_jy7eRB0tNsd2VNgk']
      const shouldShowClickToPay = accountList.includes(tokenData.account_settings.account_id)

      if ((hasPaymentInAcceptPaymentMethods(state, ('credit_card')) && tokenData.payment_settings.click_to_pay_enabled) && shouldShowClickToPay) {
        state.paymentMethods.unshift('click_to_pay')
      }

      if (hasPaymentInAcceptPaymentMethods(state, ('credit_card')) && ApplePay.showApplePayOption()) {
        const applePayEnabled = tokenData.payment_settings.apple_pay_enabled
        if (applePayEnabled) {
          state.paymentMethods.unshift('apple_pay')
        }
      }

      if (hasPaymentInAcceptPaymentMethods(state, ('credit_card')) && tokenData.payment_settings.google_pay_enabled) {
        const googlePay = await GooglePay.showGooglePayOption(googlePayData)
        if (googlePay) {
          state.paymentMethods.unshift('google_pay')
        }
      }

      if (state.paymentMethods.length === 1 && state.paymentMethods[0] === 'boleto') {
        state.selectedPaymentMethod = 'boleto'
      }
      if (hasPaymentInAcceptPaymentMethods(state, ('boleto'))) {
        if (tokenData.payment_settings.boleto_settings.due_at) {
          state.boleto.due_at = tokenData.payment_settings.boleto_settings.due_at
        } else if (tokenData.payment_settings.boleto_settings.due_in) {
          const dueAt = moment().add(tokenData.payment_settings.boleto_settings.due_in, 'days')
          state.boleto.due_at = dueAt.format('DD/MM/YYYY')
        }
      }
      if (hasPaymentInAcceptPaymentMethods(state, ('credit_card'))) {
        if (tokenData.payment_settings.credit_card_settings) {
          state.installments = tokenData.payment_settings.credit_card_settings.installments
        }
      }
    },
    setCardBrands (state, tokenData) {
      if (state.selectedPaymentMethod === 'credit_card') {
        state.brands = tokenData.payment_settings.credit_card_settings.accepted_brands
      }
    },
    updateErrors (state, params) {
      state[params.payment] = {
        ...state[params.payment],
        [params.field]: {
          ...state[params.payment][params.field],
          error: params.error
        }
      }
    },
    updateFieldValue (state, params) {
      state[params.payment] = {
        ...state[params.payment],
        [params.field]: {
          ...state[params.payment][params.field],
          value: params.value
        }
      }
    },
    updateCardValidation (state, params) {
      state[params.payment].cardNumber.validation.length = params.cardNumber
      state[params.payment].cvv.validation.length = params.cvv
    },
    setBrandImage (state, params) {
      state[params.payment].brandImage = params.brandImage
    }
  },
  actions: {
    'VERIFY_PAYMENT_FORM' (context, params = {}) {
      context.state.hasError = false
      const paymentMethod = context.state.selectedPaymentMethod
      const hasDocument = !isEmpty(store.getters.customer.fields.document.value)
      const hasZipCode = !isEmpty(store.getters.address.fields.zip_code.value)
      if (paymentMethod === 'credit_card') {
        Object.keys(context.state.payment_1).forEach((key) => {
          if (typeof context.state.payment_1[key] === 'object' && context.state.payment_1[key] !== null) {
            updateStoreWithErrors(context, key, 'payment_1')
            context.state.hasError = (context.state.hasError || context.state.payment_1[key].error)
          }
        })
      } else if (paymentMethod === 'boleto') {
        if (!hasDocument || !hasZipCode) {
          context.state.hasError = true
        }
      } else if (!paymentMethod) {
        context.state.hasError = true
      }
    },
    'UPDATE_ERRORS' (context, params = {}) {
      context.commit('updateErrors', params)
    },
    'UPDATE_CARD_VALIDATION' (context, params = {}) {
      context.commit('updateCardValidation', params)
    },
    'UPDATE_FIELD_VALUE' (context, params = {}) {
      context.commit('updateFieldValue', params)
    },
    'SET_BRAND_IMAGE' (context, params = {}) {
      context.commit('setBrandImage', params)
    },
    'SET_PAYMENT_METHOD' (context, params = {}) {
      context.commit('setPaymentMethod', params.paymentMethod)
      context.commit('setCardBrands', context.rootState.tokenData)
    }
  }
}

function orderAmount (tokenData) {
  return tokenData.cart_settings.items.reduce((accumulator, value) => accumulator + value.amount, 0)
}

function subscriptionAmount (tokenData) {
  return tokenData.cart_settings.recurrences[0].plan.items.reduce((accumulator, value) => accumulator + value.princing_scheme.price, 0)
}

function hasPaymentInAcceptPaymentMethods (state, paymentMethod) {
  return state.paymentMethods.includes(paymentMethod)
}

function updateStoreWithErrors (context, key, paymentObject) {
  context.commit('updateErrors', {
    error: hasError(context.state.payment_1[key]),
    payment: paymentObject,
    field: key
  })
}

function buildBoletoForm () {
  return {
    due_at: null,
    due_in: null
  }
}

function buildCreditCardForm () {
  return {
    brandImage: null,
    cardNumber: {
      value: null,
      validation: {
        required: true,
        length: 16
      },
      error: false
    },
    brand: {
      value: null,
      validation: {
        required: true
      },
      error: false
    },
    name: {
      value: null,
      validation: {
        required: true,
        format: [{
          type: 'text'
        }
        ]
      },
      error: false
    },
    expDate: {
      value: null,
      validation: {
        required: true,
        format: [{
          type: 'expiration_date'
        }
        ]
      },
      error: false
    },
    cvv: {
      value: null,
      validation: {
        required: true,
        length: 3
      },
      error: false
    },
    installment: {
      value: null,
      validation: {
        required: true
      },
      error: false
    }
  }
}

export default payment
