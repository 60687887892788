<template>
  <div>
    <shippable-address v-if="isShippableAdress"/>
    <billing-address v-if="isBillingAdress"/>
  </div>
</template>

<script>
import BillingAddress from './BillingAddress.vue'
import ShippableAddress from './ShippableAddress.vue'
import { onVisitPage } from '@/utils/analitica/events'

export default {
  name: 'Address',
  components: {
    BillingAddress,
    ShippableAddress
  },
  created () {
    onVisitPage.events.view.onVisitPage('address')
  },
  computed: {
    address: {
      get () {
        return this.$store.getters.address
      }
    },
    isShippableAdress () {
      return this.$store.getters.tokenData.shippable
    },
    isBillingAdress () {
      return !this.address.billingEqualShipping
    }
  }
}
</script>
