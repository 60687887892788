<template>
  <apple-pay-button @click='createApplePaySession' buttonstyle="black" type="buy" locale="pt-BR"></apple-pay-button>
</template>

<script>
export default {
  name: 'ApplePay',
  data () {
    return {
      session: null,
      errors: null,
      merchantId: null
    }
  },
  showApplePayOption () {
    // eslint-disable-next-line
    return window.ApplePaySession && ApplePaySession.canMakePayments()
  },
  computed: {
    tokenData () {
      return this.$store.getters.tokenData
    },
    sessionId () {
      return this.$store.getters.sessionId
    },
    applePaymentDetails () {
      return this.$store.getters.payment.applePaymentDetails
    }
  },
  methods: {
    intToCurrency (value) {
      let amount = null
      if (value.includes('.')) {
        return value
      } else if (value < 10) {
        amount = '0.0' + value
      } else if (value < 100) {
        amount = '0.' + value
      } else {
        const amountArray = value.toString().split('')
        const decimal = amountArray.slice(-2).join('')
        const integer = amountArray.slice(0, -2).join('')
        amount = `${integer}.${decimal}`
      }
      return amount
    },
    formatAmout (value) {
      if (!value) return value
      if (Array.isArray(value)) {
        value.forEach(item => {
          item.amount = this.intToCurrency(item.amount)
        })
      } else {
        value = this.intToCurrency(value)
      }
      return value
    },
    sessionData () {
      return {
        applicationData: btoa(this.tokenData),
        countryCode: this.applePaymentDetails.country_code,
        currencyCode: this.applePaymentDetails.currency_code,
        merchantCapabilities: this.applePaymentDetails.merchant_capabilities,
        supportedNetworks: this.applePaymentDetails.supported_networks,
        requiredBillingContactFields: this.applePaymentDetails.required_billing_contact_fields,
        requiredShippingContactFields: this.applePaymentDetails.required_shipping_contact_fields,
        shippingType: this.applePaymentDetails.shipping_type,
        supportedCountries: this.applePaymentDetails.supported_countries,
        shippingMethods: this.formatAmout(this.applePaymentDetails.shipping_methods),
        lineItems: this.formatAmout(this.applePaymentDetails.line_items),
        total: {
          label: this.applePaymentDetails.total.label,
          amount: this.formatAmout(this.applePaymentDetails.total.amount)
        }
      }
    },
    merchantData (response) {
      return {
        displayName: response.display_name,
        domainName: response.domain_name,
        epochTimestamp: response.epoch_timestamp,
        expiresAt: response.expires_at,
        merchantIdentifier: response.merchant_identifier,
        merchantSessionIdentifier: response.merchant_session_identifier,
        nonce: response.nonce,
        operationalAnalyticsIdentifier: response.operational_analytics_identifier,
        pspId: response.psp_id,
        retries: response.retries,
        signature: response.signature
      }
    },
    createApplePaySession () {
      const appleRequest = this.sessionData()
      const applePayVersion = 10
      // eslint-disable-next-line
      this.session = new ApplePaySession(applePayVersion, appleRequest)
      this.session.onvalidatemerchant = this.onValidateMerchant
      this.session.onpaymentauthorized = this.onPaymentAuthorized
      this.session.begin()
    },
    onValidateMerchant () {
      this.$store.dispatch('APPLE_PAY', { paymentLinkId: this.tokenData.id, sessionId: this.sessionId }).then((response) => {
        this.merchantId = response.merchant_identifier
        const merchantData = this.merchantData(response)
        this.session.completeMerchantValidation(merchantData)
      })
    },
    completePayment (status) {
      const authorizationResult = {
        // eslint-disable-next-line
        status: ApplePaySession[status],
        errors: []
      }

      this.session.completePayment(authorizationResult)
    },
    onPaymentAuthorized (event) {
      const paymentData = {
        version: event.payment.token.paymentData.version,
        merchant_identifier: this.merchantId,
        transaction_id: event.payment.token.transactionIdentifier,
        ephemeral_public_key: event.payment.token.paymentData.header.ephemeralPublicKey,
        public_key_hash: event.payment.token.paymentData.header.publicKeyHash,
        signature: event.payment.token.paymentData.signature,
        data: event.payment.token.paymentData.data,
        installments: 1
      }
      this.$emit('onPaymentAuthorized', { appleDetails: paymentData, onCompletePayment: this.completePayment })
    }
  }
}
</script>
