var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "el-centered" },
    [
      _vm.theme === "mundipagg"
        ? _c(
            "svg",
            {
              staticClass: "emd-mundi-loader _color_primary",
              attrs: {
                width: "36px",
                height: "40px",
                viewBox: "0 0 36 40",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M17.9424838,24.1545999 L32.156449,38.2118394 C32.698081,38.7474993 33.5713986,38.7426579 34.1070584,38.2010259 C34.3624287,37.9428085 34.5056573,37.5942949 34.5056573,37.231128 L34.5056573,2.77725067 C34.5056573,2.0154786 33.8881191,1.39794033 33.126347,1.39794033 C32.7600178,1.39794033 32.4087359,1.5436671 32.1499804,1.80297899 L15.8885043,18.0994211 C14.8317323,19.1584654 14.8117557,20.8668479 15.8434747,21.9503135 L17.9424838,24.1545999 L20.0519579,21.951233 C21.0889852,20.8680476 21.0707979,19.1548442 20.0110089,18.0939185 L3.734463,1.79991544 C3.19609759,1.26097261 2.32276671,1.26050452 1.78382388,1.79886993 C1.52483316,2.05758317 1.37931034,2.40864021 1.37931034,2.77471224 L1.37931034,37.231128 C1.37931034,37.9929 1.99684862,38.6104383 2.75862069,38.6104383 C3.12178764,38.6104383 3.47030121,38.4672097 3.72851865,38.2118394 L17.9424838,24.1545999 Z",
                      id: "path",
                      "stroke-width": "2",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.theme == "pagarme" ? _c("emd-pagarme-loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }