import { render, staticRenderFns } from "./CustomerEditable.vue?vue&type=template&id=22c36c69"
import script from "./CustomerEditable.vue?vue&type=script&lang=js"
export * from "./CustomerEditable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CustomerEditable.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/checkout-webapp-v2/checkout-webapp-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22c36c69')) {
      api.createRecord('22c36c69', component.options)
    } else {
      api.reload('22c36c69', component.options)
    }
    module.hot.accept("./CustomerEditable.vue?vue&type=template&id=22c36c69", function () {
      api.rerender('22c36c69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/customer/CustomerEditable.vue"
export default component.exports