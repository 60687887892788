<template>
  <div class="el-chunk">
      <div class="el-chunk">
        <p>
          <strong>{{ $t('section.customer') }}</strong>
        </p>
      </div>
      <div class="eu-flex eu-column eu-row_sm">
        <div class="eu-flex-item" :class="{ 'eu-basis_6_sm': address.shipping.recipient_name || address.shipping.recipient_phone }">
          <div class="el-chunk">
            <div class="emd-container _type_card">
              <div class="emd-container__content">
                <div class="emd-container__aside">
                  <review-icon icon="review/user" />
                </div>
                <div class="el-chunk">
                  <h2 class="eu-capitalize">
                    {{ customer.fields.name.value || '-' }}
                  </h2>
                </div>
                <p v-if="customer.fields.email" class="emd-text _size_small">
                  {{ customer.fields.email.value }}
                </p>
                <p v-if="customer.fields.document" class="emd-text _size_small">
                  {{ customer.fields.document.value | document }}
                </p>
                <p v-if="customer.fields.home_phone" class="emd-text _size_small">
                  {{ customer.fields.home_phone.value | phone }}
                </p>
                <p v-if="customer.fields.mobile_phone" class="emd-text _size_small">
                  {{ customer.fields.mobile_phone.value | phone }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="address.shipping.recipient_name || address.shipping.recipient_phone" class="eu-flex-item eu-basis_6_sm">
          <div class="el-chunk">
            <div class="emd-container _type_card">
              <div class="emd-container__content">
                <div class="emd-container__aside">
                  <review-icon icon="review/recipient" />
                </div>
                <div class="el-chunk">
                  <h2>{{ $t('recipient') }}</h2>
                </div>
                <p v-if="address.shipping.recipient_name" class="emd-text _size_small">{{ address.shipping.recipient_name.value }}</p>
                <p v-if="address.shipping.recipient_phone" class="emd-text _size_small">{{ address.shipping.recipient_phone | phone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ReviewIcon from '@/components/project/atoms/Icon.vue'

export default {
  name: 'CustomerReview',
  components: {
    ReviewIcon
  },
  computed: {
    customer () {
      return this.$store.getters.customer
    },
    address () {
      return this.$store.getters.address
    },
    payments () {
      return this.$store.getters.payment
    },
    shippable () {
      return this.$store.getters.tokenData.shippable
    },
    banks () {
      return this.$store.getters.payment.banks
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "section": {
      "customer": "Identificação",
      "address": "Endereço",
      "payment": "Pagamento"
    },
    "recipient": "Destinatário",
    "shipping": "Entrega"
  },
  "en-US": {
    "section": {
      "customer": "Identificação",
      "address": "Address",
      "payment": "Payment"
    },
    "recipient": "Recipient",
    "shipping": "Shipping"
  }
}
</i18n>
