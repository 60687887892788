<template>
    <section class="el-chunk">
        <div class="el-chunk">
        <h2 class="emd-text _size_section">{{ $t('shipping') }}</h2>
        </div>
        <div class="el-chunk">
        <div class="eu-flex eu-column eu-row_md">
            <div class="eu-flex-item" :class="{ 'eu-basis_6_md': address.shipping.recipient_name || address.shipping.recipient_phone}">
            <div class="el-chunk">
                <div class="emd-container _type_card">
                <div class="emd-container__content">
                    <div class="el-chunk">
                    <h2>{{ $t('address') }}</h2>
                    </div>
                    <p class="emd-text _size_small">{{ address.shipping.zip_code.value | cep }}</p>
                    <p v-if="address.type === 'brazilian'" class="emd-text _size_small">{{ address.shipping.street.value }}, {{ address.shipping.number.value }}<span v-if="address.shipping.complement.value">, {{ address.shipping.complement.value }}</span> - {{ address.shipping.neighborhood.value }}</p>
                    <template v-if="address.type === 'global'" >
                    <p class="emd-text _size_small">{{ address.shipping.line_1.value }}</p>
                    <p v-if="address.shipping.line_2.value" class="emd-text _size_small">{{ address.shipping.line_2.value }}</p>
                    </template>
                    <p class="emd-text _size_small">
                    {{ address.shipping.city.value }} - {{ address.shipping.state.value }} - {{ address.shipping.country.value }}
                    </p>
                </div>
                </div>
            </div>
            </div>
            <div v-if="address.shipping.recipient_name || address.shipping.recipient_phone" class="eu-flex-item eu-basis_6_md">
            <div class="el-chunk">
                <div class="emd-container _type_card">
                <div class="emd-container__content">
                    <div class="el-chunk">
                    <h2>{{ $t('recipient') }}</h2>
                    </div>
                    <p v-if="address.shipping.recipient_name" class="emd-text _size_small eu-capitalize">{{ address.shipping.recipient_name }}</p>
                    <p v-if="address.shipping.recipient_phone" class="emd-text _size_small">{{ address.shipping.recipient_phone | phone }}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div v-if="address.is_editable" class="el-chunk">
        <label class="emd-checkbox" for="same">
            <input class="emd-checkbox__input" type="checkbox" id="same" v-model="address.billingEqualShipping" @change="handleBillingEqualShipping">
            <div class="emd-checkbox__area"></div>
            <span class="emd-checkbox__text">{{ $t('billingEqualShipping') }}</span>
        </label>
        </div>
    </section>
</template>

<script>
import { isEmpty } from '@/utils'

export default {
  name: 'ShippableAddress',
  computed: {
    address: {
      get () {
        return this.$store.getters.address
      }
    },
    localLoading () {
      return this.$store.getters.localLoading
    }
  },
  data () {
    return {
      zipCodeNotFound: null,
      canSearchZipcode: true
    }
  },
  created () {
    if (!isEmpty(this.address.fields.zip_code.value)) {
      this.canSearchZipcode = false
    }
  },
  methods: {
    handleBillingEqualShipping (e) {
      this.zipCodeNotFound = null
      this.canSearchZipcode = true
      this.$store.dispatch('SET_BILLING_EQUAL_SHIPPING')
    }
  }
}
</script>

<i18n>
{
  "pt-BR": {
    "shipping": "Entrega",
    "address": "Endereço",
    "recipient": "Destinatário",
    "billingEqualShipping": "O endereço de cobrança é igual ao de entrega"
  },
  "en-US": {
    "shipping": "Shipping",
    "address": "Address",
    "recipient": "Recipient",
    "billingEqualShipping": "Use same Billing Address"
  }
}
</i18n>
