<template>
  <div>
    <div class="el-chunk">
      <h2 class="emd-text _size_subpage"><b>{{ $t('title') }}</b></h2>
    </div>
    <div class="el-chunk">
      <p class="emd-text _color_subtle" style="white-space: pre-line;">
        {{ $t('text') }}
      </p>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Não foi possível abrir seu link de pagamento.",
    "text": "Algo inesperado aconteceu, mas estamos trabalhando para normalizar o mais rápido possível.\nTente outra vez em alguns minutos."
  },
  "en-US": {
    "title": "Unable to open your payment link.",
    "text": "Something unexpected happened, but we are working to return to normal as quickly as possible.\nTry again in a few minutes."
  }
}
</i18n>

<script>
export default {
  name: 'Error500'
}
</script>
