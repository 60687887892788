import { Analitica } from '@stone-payments/analitica'
import { AmplitudePlugin } from '@stone-payments/analitica/src/plugins/amplitude'

const globalMetadata = { env: process.env.NODE_ENV }

export const analiticaInstance = new Analitica(
  globalMetadata,
  [
    new AmplitudePlugin(process.env.VUE_APP_AMPLITUDE_KEY, { userId: '', defaultTracking: false })
  ],
  { dryRun: process.env.NODE_ENV !== 'production' }
)
