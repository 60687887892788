import dayjs from 'dayjs'
var localizedFormat = require('dayjs/plugin/localizedFormat')
var utc = require('dayjs/plugin/utc')
require('dayjs/locale/pt')

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.locale('pt')

const formats = {
  date: 'L',
  dateAndHour: 'L - LTS'
}

export function formatCurrency (text, currency) {
  if (!text) return text
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: currency }).format(text)
}

export function formatCEP (text) {
  if (!text) return text
  if (text.length === 8) return `${text.slice(0, 5)}-${text.slice(5, 8)}`
  return text
}

export function formatDocument (text) {
  if (!text) return text
  const type = text.length === 14 ? 'cnpj' : text.length === 11 ? 'cpf' : null
  if (!type) return text
  if (type === 'cpf') return `${text.slice(0, 3)}.${text.slice(3, 6)}.${text.slice(6, 9)}-${text.slice(9, 11)}`
  if (type === 'cnpj') return `${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5, 8)}/${text.slice(8, 12)}-${text.slice(12, 14)}`
}

export function formatPhone (text) {
  if (!text) return text
  if (text.length === 11) return `(${text.slice(0, 2)}) ${text.slice(2, 7)}-${text.slice(7, 11)}`
  if (text.length === 10) return `(${text.slice(0, 2)}) ${text.slice(2, 6)}-${text.slice(6, 10)}`
  return text
}

export function formatDate (date, format) {
  if (date) return dayjs.utc(date).local().format(formats[format])
  return '-'
}
