var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "el-chunk" }, [
    _c("div", { staticClass: "el-chunk" }, [
      _c("h2", { staticClass: "emd-text _size_section" }, [
        _vm._v(_vm._s(_vm.$t("shipping"))),
      ]),
    ]),
    _c("div", { staticClass: "el-chunk" }, [
      _c("div", { staticClass: "eu-flex eu-column eu-row_md" }, [
        _c(
          "div",
          {
            staticClass: "eu-flex-item",
            class: {
              "eu-basis_6_md":
                _vm.address.shipping.recipient_name ||
                _vm.address.shipping.recipient_phone,
            },
          },
          [
            _c("div", { staticClass: "el-chunk" }, [
              _c("div", { staticClass: "emd-container _type_card" }, [
                _c(
                  "div",
                  { staticClass: "emd-container__content" },
                  [
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("address")))]),
                    ]),
                    _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("cep")(_vm.address.shipping.zip_code.value)
                        )
                      ),
                    ]),
                    _vm.address.type === "brazilian"
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            _vm._s(_vm.address.shipping.street.value) +
                              ", " +
                              _vm._s(_vm.address.shipping.number.value)
                          ),
                          _vm.address.shipping.complement.value
                            ? _c("span", [
                                _vm._v(
                                  ", " +
                                    _vm._s(
                                      _vm.address.shipping.complement.value
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            " - " +
                              _vm._s(_vm.address.shipping.neighborhood.value)
                          ),
                        ])
                      : _vm._e(),
                    _vm.address.type === "global"
                      ? [
                          _c("p", { staticClass: "emd-text _size_small" }, [
                            _vm._v(_vm._s(_vm.address.shipping.line_1.value)),
                          ]),
                          _vm.address.shipping.line_2.value
                            ? _c("p", { staticClass: "emd-text _size_small" }, [
                                _vm._v(
                                  _vm._s(_vm.address.shipping.line_2.value)
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c("p", { staticClass: "emd-text _size_small" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.address.shipping.city.value) +
                          " - " +
                          _vm._s(_vm.address.shipping.state.value) +
                          " - " +
                          _vm._s(_vm.address.shipping.country.value) +
                          " "
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
            ]),
          ]
        ),
        _vm.address.shipping.recipient_name ||
        _vm.address.shipping.recipient_phone
          ? _c("div", { staticClass: "eu-flex-item eu-basis_6_md" }, [
              _c("div", { staticClass: "el-chunk" }, [
                _c("div", { staticClass: "emd-container _type_card" }, [
                  _c("div", { staticClass: "emd-container__content" }, [
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", [_vm._v(_vm._s(_vm.$t("recipient")))]),
                    ]),
                    _vm.address.shipping.recipient_name
                      ? _c(
                          "p",
                          { staticClass: "emd-text _size_small eu-capitalize" },
                          [_vm._v(_vm._s(_vm.address.shipping.recipient_name))]
                        )
                      : _vm._e(),
                    _vm.address.shipping.recipient_phone
                      ? _c("p", { staticClass: "emd-text _size_small" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("phone")(
                                _vm.address.shipping.recipient_phone
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm.address.is_editable
      ? _c("div", { staticClass: "el-chunk" }, [
          _c("label", { staticClass: "emd-checkbox", attrs: { for: "same" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.address.billingEqualShipping,
                  expression: "address.billingEqualShipping",
                },
              ],
              staticClass: "emd-checkbox__input",
              attrs: { type: "checkbox", id: "same" },
              domProps: {
                checked: Array.isArray(_vm.address.billingEqualShipping)
                  ? _vm._i(_vm.address.billingEqualShipping, null) > -1
                  : _vm.address.billingEqualShipping,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.address.billingEqualShipping,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.address,
                            "billingEqualShipping",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.address,
                            "billingEqualShipping",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.address, "billingEqualShipping", $$c)
                    }
                  },
                  _vm.handleBillingEqualShipping,
                ],
              },
            }),
            _c("div", { staticClass: "emd-checkbox__area" }),
            _c("span", { staticClass: "emd-checkbox__text" }, [
              _vm._v(_vm._s(_vm.$t("billingEqualShipping"))),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }