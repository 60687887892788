import { render, staticRenderFns } from "./BillingAdressCard.vue?vue&type=template&id=0d52765c"
import script from "./BillingAdressCard.vue?vue&type=script&lang=js"
export * from "./BillingAdressCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BillingAdressCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/checkout-webapp-v2/checkout-webapp-v2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d52765c')) {
      api.createRecord('0d52765c', component.options)
    } else {
      api.reload('0d52765c', component.options)
    }
    module.hot.accept("./BillingAdressCard.vue?vue&type=template&id=0d52765c", function () {
      api.rerender('0d52765c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/address/BillingAdressCard.vue"
export default component.exports