<template>
  <div class="el-column">
    <div class="el-column__navbar">
      <logo-wrapper :account="account" />
    </div>
    <div class="el-section">
      <router-view/>
    </div>
    <div class="el-section">
      <emd-footer-external />
    </div>
  </div>
</template>

<script>
import LogoWrapper from '@/components/project/molecules/LogoWrapper.vue'
import EmdFooterExternal from '@/components/emerald/organisms/EmdFooterExternal.vue'

export default {
  name: 'Motherboard',
  components: {
    LogoWrapper,
    EmdFooterExternal
  },
  computed: {
    account () {
      if (this.$store.getters.tokenData) {
        const paymentLink = this.$store.getters.tokenData
        return {
          name: paymentLink.account_info?.account_name,
          logo: paymentLink.layout_settings?.image_url,
          website: paymentLink.layout_settings?.url
        }
      }
      return null
    }
  }
}
</script>
