var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "emd-detail-box",
      class: { _open: _vm.isOpen },
      on: { click: _vm.toggleDetailBox },
    },
    [
      _c("div", { staticClass: "emd-detail-box__main" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "emd-detail-box__content" },
          [
            _c(
              "div",
              { staticClass: "emd-detail-box__items" },
              [
                _c("div", { staticClass: "el-chunk" }, [
                  _c(
                    "h2",
                    { staticClass: "emd-text _size_section _color_default" },
                    [_vm._v(_vm._s(_vm.$t("order")))]
                  ),
                ]),
                _vm._l(_vm.items, function (item, i) {
                  return _c("table", { key: i, staticClass: "eu-my_1" }, [
                    _c("tr", [
                      _c("td", { staticClass: "item-name" }, [
                        _c("span", { staticClass: "emd-detail-box__footer" }, [
                          _vm._v(" " + _vm._s(_vm.getName(item)) + " "),
                        ]),
                        _c("span", { staticClass: "emd-badge _type_rounded" }, [
                          _vm._v("x" + _vm._s(_vm.getQuantity(item))),
                        ]),
                      ]),
                      _c("td", { staticClass: "item-name" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.itemTotal(item),
                              _vm.currency
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.getDescription(item)) + " "),
                      ]),
                    ]),
                  ])
                }),
                _c("hr", { staticClass: "emd-divider eu-my_3" }),
              ],
              2
            ),
            _vm.shouldShowSubtotal
              ? [
                  _c("div", { staticClass: "emd-detail-box__items" }, [
                    _c("table", [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(_vm.$t("subtotal")))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.subtotal / 100,
                                _vm.currency
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm.hasTaxes
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("taxes")))]),
                            _vm.taxes !== 0
                              ? _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.taxes / 100,
                                        _vm.currency
                                      )
                                    )
                                  ),
                                ])
                              : _c("td", [_vm._v("-")]),
                          ])
                        : _vm._e(),
                      _vm.hasDiscount
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("discounts")))]),
                            _vm.taxes !== 0
                              ? _c("td", [
                                  _vm._v(
                                    " - " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.discount / 100,
                                          _vm.currency
                                        )
                                      )
                                  ),
                                ])
                              : _c("td", [_vm._v("-")]),
                          ])
                        : _vm._e(),
                      _vm.tokenData.cart_settings.shipping_total_cost
                        ? _c("tr", [
                            _c("td", [_vm._v(_vm._s(_vm.$t("shipping")))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.tokenData.cart_settings
                                      .shipping_total_cost / 100,
                                    _vm.currency
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("hr", { staticClass: "emd-divider eu-my_3" }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("footer", { staticClass: "emd-detail-box__footer" }, [
          _c("table", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("total")))]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.total / 100, _vm.currency))
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "emd-detail-box__arrow" }, [
      _c("i", { staticClass: "uil uil-angle-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }