var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.dynamicIcon, {
    tag: "component",
    class: { "emeraldicons _color_theme": _vm.colorTheme === true },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }