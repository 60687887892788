var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.failedStatus
    ? _c(
        "div",
        [
          [
            _c(
              "div",
              { staticClass: "emd-container__content eu-align_center" },
              [
                _c("div", { staticClass: "el-chunk" }, [
                  _c("h2", { staticClass: "emd-text _size_page" }, [
                    _vm._v(_vm._s(_vm.$t("failed.title"))),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-chunk",
                    staticStyle: { "white-space": "pre-line" },
                  },
                  [_c("p", [_vm._v(_vm._s(_vm.$t("failed.body")))])]
                ),
              ]
            ),
          ],
        ],
        2
      )
    : _c(
        "div",
        [
          _vm.paymentWithBoleto
            ? [
                this.isSubscription
                  ? _c(
                      "div",
                      { staticClass: "emd-container__content eu-align_center" },
                      [
                        _c("div", { staticClass: "el-chunk" }, [
                          _c("h2", { staticClass: "emd-text _size_page" }, [
                            _vm._v(_vm._s(_vm.$t("boleto.subscription.title"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "el-chunk",
                            staticStyle: { "white-space": "pre-line" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("boleto.subscription.body"))
                              ),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "emd-container__content eu-align_center" },
                      [
                        _c("div", { staticClass: "el-chunk" }, [
                          _c("h2", { staticClass: "emd-text _size_page" }, [
                            _vm._v(_vm._s(_vm.$t("boleto.title"))),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "el-chunk",
                            staticStyle: { "white-space": "pre-line" },
                          },
                          [_c("p", [_vm._v(_vm._s(_vm.$t("boleto.body")))])]
                        ),
                        _c("div", { staticClass: "el-chunk" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.status.boleto.barcode,
                              alt: _vm.status.boleto.barcode,
                              width: "435",
                            },
                          }),
                          _c("p", { ref: "boleto_code" }, [
                            _vm._v(_vm._s(_vm.status.boleto.line)),
                          ]),
                        ]),
                      ]
                    ),
                !this.isSubscription
                  ? _c("footer", { staticClass: "emd-container__footer" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "emd-btn _color_secondary _block_mobile _merchant_primary",
                          on: {
                            click: function ($event) {
                              return _vm.copy(_vm.status.boleto.line)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "uil uil-copy" }),
                          _vm._v(" " + _vm._s(_vm.$t("boleto.btn.copy")) + " "),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "emd-btn _color_primary _block_mobile _merchant_secondary",
                          attrs: {
                            href: _vm.status.boleto.pdf,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("boleto.btn.print")) + " ")]
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.paymentWithCreditCard
            ? [
                _c(
                  "div",
                  { staticClass: "emd-container__content eu-align_center" },
                  [
                    _c(
                      "div",
                      { staticClass: "el-chunk" },
                      [
                        _vm.theme === "mundipagg"
                          ? _c("img", {
                              attrs: { src: require("@/assets/thumbs-up.png") },
                            })
                          : _vm._e(),
                        _vm.theme === "pagarme" ? _c("success-icon") : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", { staticClass: "emd-text _size_page" }, [
                        _vm._v(_vm._s(_vm.$t("credit_card.title"))),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "el-chunk",
                        staticStyle: { "white-space": "pre-line" },
                      },
                      [_c("p", [_vm._v(_vm._s(_vm.$t("credit_card.body")))])]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm.paymentWithPix
            ? [
                _c(
                  "div",
                  { staticClass: "emd-container__content eu-align_center" },
                  [
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("h2", { staticClass: "emd-text _size_page" }, [
                        _vm._v(_vm._s(_vm.$t("pix.title"))),
                      ]),
                    ]),
                    _c("div", { staticClass: "el-chunk" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.status.pix.qr_code_url,
                          alt: _vm.status.pix.qr_code,
                          width: "250",
                        },
                      }),
                      _c("p", { ref: "qr_code", staticClass: "qr_code" }, [
                        _vm._v(_vm._s(_vm.status.pix.qr_code)),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass:
                            "emd-btn _color_secondary _block_mobile _merchant_primary",
                          on: {
                            click: function ($event) {
                              return _vm.copy(_vm.status.pix.qr_code)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "uil uil-copy" }),
                          _vm._v(" " + _vm._s(_vm.$t("pix.btn.copy")) + " "),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "el-chunk eu-justify_center eu-display_flex",
                        staticStyle: { "white-space": "pre-line" },
                      },
                      [
                        _c("div", { staticClass: "eu-display_flex" }, [
                          _c(
                            "ol",
                            {
                              staticClass:
                                "steps emd-list _space_base eu-align_left",
                            },
                            _vm._l(_vm.$t("pix.steps"), function (step, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  domProps: { innerHTML: _vm._s(step) },
                                },
                                [_vm._v(" " + _vm._s(step) + " ")]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "el-chunk eu-justify_center eu-align-items_center eu-display_flex eu-column",
                        staticStyle: { "white-space": "pre-line" },
                      },
                      _vm._l(_vm.$t("pix.infos"), function (info, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "eu-basis_7 eu-basis_12_mobile eu-display_flex eu-ma_2",
                          },
                          [
                            info.date
                              ? _c("div", { staticClass: "eu-align_left" }, [
                                  _vm._v(" " + _vm._s(info.message) + " "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          _vm.status.pix.expires_at,
                                          "dateAndHour"
                                        )
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            !info.date && !info.redirect
                              ? _c("div", { staticClass: "eu-align_left" }, [
                                  _vm._v(" " + _vm._s(info.message) + " "),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c("footer", { staticClass: "emd-container__footer" }),
              ]
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }