<template>
  <div :class="['emd-language-select', modifiers]">
    <select v-model="$i18n.locale" @click="selectLanguage">
      <option
        v-for="lang in langs"
        :key="lang.code"
        :value="lang.code">{{ lang.flag }} {{ lang.name }}</option>
    </select>
    <div class="emd-language-select__arrow"/>
  </div>
</template>

<script>
import { onSelectPortuguese, onSelectEnglish } from '@/utils/analitica/events'

export default {
  name: 'EmdLanguageSelect',
  props: {
    color: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      langs: [
        {
          code: 'en-US',
          name: 'English',
          flag: '🇺🇸'
        },
        {
          code: 'pt-BR',
          name: 'Português',
          flag: '🇧🇷'
        }
      ]
    }
  },
  computed: {
    currentSection () {
      return this.$store.getters.currentSection
    },
    modifiers () {
      const classes = []

      if (this.color) {
        classes.push(`_color_${this.color}`)
      }

      return classes
    }
  },
  methods: {
    selectLanguage () {
      if (this.$i18n.locale === 'pt-BR') {
        onSelectPortuguese.events.view.onSelectPortuguese(this.currentSection)
      } else {
        onSelectEnglish.events.view.onSelectEnglish(this.currentSection)
      }
    }
  }
}
</script>
