<template>
  <section>
    <section class="el-centered" v-if="isLoadingSubmit">
      <emd-pagarme-loader />
    </section>
    <section v-else>
      <div>
        <template v-for="paymentMethod in paymentMethods">
          <div class="el-chunk" v-if="paymentWithBoleto" v-bind:key="paymentMethod">
            <emd-form-box :hasError="(selectedPaymentMethod === null && hasError || (boletoError(paymentMethod) && hasError))"
              :isActive="selectedPaymentMethod === paymentMethod && selectedPaymentMethod !== 'boleto'" :id="paymentMethod"
              :label="$t(`paymentMethods.${paymentMethod}`)" name="payment_method" border="base" type="radio"
              :value="selectedPaymentMethod" @change="setPaymentMethod">
              <template slot="icon">
                <payment-method-icon class="eu-va_middle" :colorTheme="paymentMethod !== 'apple_pay'" :icon="`payment_methods/${paymentMethod}`" />
              </template>
              <template v-if="boletoError(paymentMethod)" slot="helper">
                {{ $t('boletoError') }}
              </template>
              <template
                v-if="selectedPaymentMethod && selectedPaymentMethod !== 'boleto' && !boletoError('credit_card_and_boleto') && paymentMethods.length > 1"
                slot="aside">
                <button class="emd-btn _color_secondary _type_link _size_small _merchant_primary" type="button"
                  @click="setPaymentMethod(null)">{{ $t('modify') }}</button>
              </template>
              <template v-if="paymentWithCardOrVoucher" slot="content">
                <card-form />
              </template>
              <template v-if="selectedPaymentMethod === 'bank_transfer'" slot="content">
                <bank-transfer-form />
              </template>
              <template v-if="selectedPaymentMethod === '2_credit_cards'" slot="content">
                <div class="el-chunk">
                  <p><strong>{{ $t('card') }} 1</strong></p>
                </div>
                <card-form multipayment paymentKey="payment_1" />
                <hr class="emd-divider eu-my_3">
                <div class="el-chunk">
                  <p><strong>{{ $t('card') }} 2</strong></p>
                </div>
                <card-form multipayment paymentKey="payment_2" />
              </template>
              <template v-if="selectedPaymentMethod === 'credit_card_and_boleto' && !boletoError(paymentMethod)"
                slot="content">
                <div class="el-chunk">
                  <p><strong>{{ $t('card') }}</strong></p>
                </div>
                <card-form multipayment paymentKey="payment_1" />
                <hr class="emd-divider eu-my_3">
                <div class="el-chunk">
                  <p><strong>{{ $t('boleto') }}</strong></p>
                </div>
                <div class="el-chunk">
                  <div class="eu-flex eu-column eu-row_md">
                    <div class="eu-flex-item eu-basis_12_md">
                      <div class="el-chunk">
                        <div class="emd-form-group">
                          <label class="emd-label ">{{ $t('amount') }}</label>
                          <currency-input class="emd-input" v-model="payment.amount" v-bind="options"
                            @input="handleMultipaymentValue" />
                        </div>
                      </div>
                      <div class="emd-form-group">
                        <label class="emd-label ">{{ $t('dueDate') }}</label>
                        <input class="emd-input " type="text" :value="dueDate" disabled="">
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </emd-form-box>
          </div>
        </template>
      </div>
    </section>
  </section>
</template>

<script>
import EmdFormBox from '@/components/emerald/molecules/EmdFormBox.vue'
import PaymentMethodIcon from '@/components/project/atoms/Icon.vue'
import CardForm from './paymentForm/Card.vue'
import BankTransferForm from './paymentForm/BankTransfer.vue'
import { isEmpty } from '@/utils'
import moment from 'moment'
import { onVisitPage } from '@/utils/analitica/events'
import { EmdPagarmeLoader } from 'emerald-vue/src/components'

export default {
  name: 'Payment',
  components: {
    EmdFormBox,
    PaymentMethodIcon,
    CardForm,
    BankTransferForm,
    EmdPagarmeLoader
  },
  data () {
    return {
      oldAmount: 0
    }
  },
  computed: {
    isLoadingSubmit () {
      return this.$store.getters.loadingSubmit
    },
    options () {
      return {
        currency: this.currency,
        locale: this.$i18n.locale,
        'distraction-free': {
          hideNegligibleDecimalDigits: false,
          hideCurrencySymbol: false,
          hideGroupingSymbol: false
        },
        'value-as-integer': true,
        'auto-decimal-mode': true,
        precision: 2
      }
    },
    hasZipCode () {
      return !isEmpty(this.$store.getters.address.fields.zip_code.value)
    },
    hasDocument () {
      return !isEmpty(this.$store.getters.customer.fields.document.value)
    },
    currency () {
      return this.$store.getters.currency
    },
    paymentMethods () {
      return this.$store.getters.payment.paymentMethods
    },
    hasError () {
      return this.$store.getters.payment.hasError
    },
    boleto () {
      return this.$store.getters.payment.boleto
    },
    dueDate () {
      return moment(this.$store.getters.payment.boleto.due_at).utc().format('DD/MM/YYYY')
    },
    payment () {
      return this.$store.getters.payment.payment_2
    },
    selectedPaymentMethod: {
      get () {
        return this.$store.getters.payment.selectedPaymentMethod
      }
    },
    paymentWithCardOrVoucher () {
      return this.selectedPaymentMethod === 'credit_card' || this.selectedPaymentMethod === 'debit_card' || this.selectedPaymentMethod === 'voucher'
    }
  },
  created () {
    onVisitPage.events.view.onVisitPage('payment')
    if (!this.selectedPaymentMethod && this.paymentMethods.length === 1) {
      this.setPaymentMethod(this.paymentMethods[0])
    }
  },
  methods: {
    paymentWithBoleto (paymentMethod) {
      return this.selectedPaymentMethod === null || this.selectedPaymentMethod === paymentMethod || this.selectedPaymentMethod === 'boleto' || this.boletoError('credit_card_and_boleto')
    },
    boletoError (paymentMethod) {
      return ((paymentMethod === 'boleto' && this.selectedPaymentMethod === 'boleto') || (paymentMethod === 'credit_card_and_boleto' && this.selectedPaymentMethod === 'credit_card_and_boleto')) && (!this.hasZipCode || !this.hasDocument)
    },
    handleMultipaymentValue (value) {
      if (value !== this.oldAmount) {
        this.selectedInstallment = null
        if (value <= 0 || value > this.totalAmount) {
          this.installmentDisabled = true
        } else {
          this.installmentDisabled = false
        }
        this.oldAmount = value
        this.$store.dispatch('UPDATE_MULTIPAYMENT_VALUE', { payment: 'payment_2', amount: value })
      }
    },
    setPaymentMethod (e) {
      if (e !== this.selectedPaymentMethod) {
        this.$store.dispatch('SET_PAYMENT_METHOD', { paymentMethod: e })
      }
    }
  }
}
</script>

<style>
.eu-va_middle {
  vertical-align: middle;
}
</style>

<i18n>
  {
    "pt-BR": {
      "paymentMethods": {
        "credit_card": "Cartão de crédito",
        "debit_card": "Cartão de débito",
        "boleto": "Boleto",
        "bank_transfer": "Débito online",
        "2_credit_cards": "2 Cartões de crédito",
        "credit_card_and_boleto": "Cartão de crédito + Boleto",
        "voucher": "Voucher",
        "pix": "Pix",
        "apple_pay": "Apple Pay",
        "google_pay": "Google Pay",
        "click_to_pay": "Click to Pay"
      },
      "boletoError": "Pagamentos com boleto requerem endereço e documento. Por favor, preencha seus dados corretamente.",
      "modify": "Modificar",
      "card": "Cartão",
      "boleto": "Boleto",
      "amount": "Valor a pagar",
      "dueDate": "Vencimento"
    },
    "en-US": {
      "paymentMethods": {
        "credit_card": "Credit card",
        "debit_card": "Debit card",
        "boleto": "Boleto",
        "bank_transfer": "Bank transfer",
        "2_credit_cards": "2 credit cards",
        "credit_card_and_boleto": "Credit card + Boleto",
        "voucher": "Voucher",
        "pix": "Pix",
        "apple_pay": "Apple Pay",
        "google_pay": "Google Pay",
        "click_to_pay": "Click to Pay"
      },
      "boletoError": "Boleto payment method requires billing address and document. Please, provide valid data.",
      "modify": "Modify",
      "card": "Card",
      "boleto": "Boleto",
      "amount": "Payment value",
      "dueDate": "Due date"
    }
  }
  </i18n>
