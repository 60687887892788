var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isIE
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.isLoading ? _c("global-loader") : _vm._e(),
          !_vm.isLoading ? [_c("router-view")] : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }